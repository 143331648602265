import {
  forwardRef,
  useTheme,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

import { lightenDarkenColor } from "utilities";

export interface ButtonProps extends ChakraButtonProps {
  variant?: "primary" | "danger" | "secondary" | "tertiary" | "link" | "navy";
}

// Button size constants for consistent sizing
const BUTTON_SIZES: Record<
  string,
  {
    height: string;
    px: string;
    py: string;
    fontSize: string;
  }
> = {
  xs: {
    height: "2rem", // 32px
    px: "0.75rem", // 12px
    py: "0.25rem", // 4px
    fontSize: "sm",
  },
  sm: {
    height: "2.5rem", // 40px
    px: "1rem", // 16px
    py: "0.5rem", // 8px
    fontSize: "sm",
  },
  md: {
    height: "3.5rem", // 56px
    px: "2rem", // 32px
    py: "1rem", // 16px
    fontSize: "md",
  },
};

/** We've gotten better since creating this button and this file now exports a new version. The default export will remain the "LegacyButton" and the named export is the new "Button".
 * ```
 * // Imports `LegacyButton`. Most of the app uses this.
 * import Button from "./lib/Button"
 *
 * // Imports new `Button`, which should be easier to use.
 * import { Button } from "./lib/Button";
 * ``` */
const LegacyButton = ({ variant = "primary", size = "md", ...rest }: ButtonProps): JSX.Element => {
  const theme = useTheme();
  const colorStylesForVariant = (() => {
    return Object.fromEntries(
      Object.entries({
        color: theme.colors.buttons[variant].text,
        backgroundColor: theme.colors.buttons[variant].bg,
        borderColor: theme.colors.buttons[variant][variant === "secondary" ? "link" : "bg"],
      }).filter(([_, color]) => color), // remove color styles not defined in the theme for this variant
    );
  })();
  // for now, one in the same and letting the variable communicate that
  const activeStyles = {
    ...colorStylesForVariant,
    boxShadow: variant === "link" ? "none" : "outer",
  };

  const hoverStyles = {
    ...Object.fromEntries(
      Object.entries(colorStylesForVariant).map(([property, color]) => {
        return [property, lightenDarkenColor(color, 25)];
      }),
    ),
    textDecoration: variant === "link" ? "underline" : "none",
  };

  // Get the appropriate size properties
  const sizeProps = BUTTON_SIZES[size as string] || BUTTON_SIZES.md;

  return (
    <ChakraButton
      data-testid="button"
      border="1px solid"
      boxShadow={variant === "link" ? "none" : "outer"}
      fontWeight="bold"
      fontSize={sizeProps.fontSize}
      px={sizeProps.px}
      py={sizeProps.py}
      height={sizeProps.height}
      {...colorStylesForVariant}
      _hover={rest.disabled ? {} : { ...hoverStyles }}
      _focus={rest.disabled ? {} : { ...hoverStyles }}
      _active={activeStyles}
      {...rest}
    />
  );
};

export default LegacyButton;

// -------------------------------------------------------
/** New Button below. To use:
 * ```
 * import { Button } from "./lib/Button"
 * ```  */
const BaseButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  return (
    <ChakraButton
      boxShadow={rest.variant !== "link" ? "outer" : undefined}
      colorScheme="aqua"
      fontWeight="bold"
      border="1px solid"
      ref={ref}
      {...rest}
    />
  );
});

const XSmallButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  const sizeProps = BUTTON_SIZES.xs;
  return (
    <BaseButton
      fontSize={sizeProps.fontSize}
      px={sizeProps.px}
      py={sizeProps.py}
      height={sizeProps.height}
      ref={ref}
      {...rest}
    />
  );
});

const SmallButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  const sizeProps = BUTTON_SIZES.sm;
  return (
    <BaseButton
      fontSize={sizeProps.fontSize}
      px={sizeProps.px}
      py={sizeProps.py}
      height={sizeProps.height}
      ref={ref}
      {...rest}
    />
  );
});

const LargeButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  const sizeProps = BUTTON_SIZES.md;
  return (
    <BaseButton
      fontSize={sizeProps.fontSize}
      px={sizeProps.px}
      py={sizeProps.py}
      height={sizeProps.height}
      ref={ref}
      {...rest}
    />
  );
});

export const Button = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  if (rest.size === "xs") {
    return <XSmallButton ref={ref} {...rest} />;
  }
  if (rest.size === "sm") {
    return <SmallButton ref={ref} {...rest} />;
  }
  if (rest.size === "lg") {
    return <LargeButton ref={ref} {...rest} />;
  }
  return <BaseButton ref={ref} {...rest} />;
});
