import { Navigate, Route, Routes } from "react-router-dom";
import { FullStory } from "@fullstory/browser";

import CampaignProvider from "containers/admin/clients/campaign/components/campaign-provider/campaign-provider";
import ClientProvider from "containers/admin/clients/client/components/client-provider/client-provider";
import CreativeProvider from "containers/admin/clients/touchpoint/components/touchpoint-provider/touchpoint-provider";
import TouchpointPageRedirect from "containers/admin/clients/touchpoint/touchpoint-page-redirect";
import TouchpointPage from "containers/admin/clients/touchpoint/touchpoint-page";
import ClientsRoutes from "containers/admin/clients/clients-routes";
import InboxPage from "containers/admin/inbox/inbox";
import ReviewsPage from "containers/client/reviews/reviews";
import UIKitPage from "containers/ui-kit-page/ui-kit-page";
import NotFoundPage from "containers/not-found-page/not-found-page";

import AppHeader from "components/partials/header/header";
import Logout from "components/partials/logout/logout";

import { useCurrentUser } from "state/ducks";

import useSetPageTitle from "hooks/use-set-page-title";
import { getTouchpointPaths } from "utilities/app-routes";

import { Role } from "types/auth";
import { Content } from "components/partials/layout/layout";

const AuthenticatedApp = () => {
  const currentUser = useCurrentUser();

  const isSuperAdminUser = currentUser.roles[0] === Role.ROLE_SUPER_ADMIN;

  useSetPageTitle({ isClientUser: !isSuperAdminUser });

  FullStory("setIdentity", {
    uid: currentUser.id,
    properties: {
      email: currentUser.email,
    },
  });

  const { touchpointPath, touchpointPreviewPath } = getTouchpointPaths();

  return (
    <>
      <AppHeader />
      <Routes>
        {/* Root redirect */}
        {["/", "/login"].map((path) => (
          <Route
            key={path}
            path={path}
            element={<Navigate to={isSuperAdminUser ? "/clients" : "/reviews"} replace />}
          />
        ))}

        {/* Admin-only routes - protected from client users */}
        {isSuperAdminUser && (
          <>
            <Route path="/clients/*" element={<ClientsRoutes />} />
            <Route path="/inbox" element={<InboxPage />} />
          </>
        )}

        {/* Special case: Allow access client touchpoint and preview paths */}
        {!isSuperAdminUser && (
          <>
            <Route path={touchpointPath} element={<TouchpointPageRedirect />} />
            <Route
              path={touchpointPreviewPath}
              element={
                <ClientProvider>
                  <CampaignProvider>
                    <CreativeProvider>
                      <Content>
                        <TouchpointPage />
                      </Content>
                    </CreativeProvider>
                  </CampaignProvider>
                </ClientProvider>
              }
            />
          </>
        )}

        {["/reviews", "/approved"].map((path) => (
          <Route key={path} path={path} element={<ReviewsPage />} />
        ))}

        <Route path="/logout" element={<Logout />} />
        <Route path="/ui-kit" element={<UIKitPage />} />

        {/* 404 Route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
