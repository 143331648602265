import { Controller, useFormContext, FieldError, Merge, FieldErrorsImpl } from "react-hook-form";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";
import { useTypographyOptions } from "containers/admin/clients/client/theme-page/preview/hooks/use-typography-option";
import HexInput from "containers/admin/clients/client/theme-page/preview/components/hex-input/hex-input";
import ColorSwatch from "containers/admin/clients/client/theme-page/preview/components/color-swatch/color-swatch";
import CustomSelect from "components/new/custom-select/custom-select";

import { TypographyTabInputType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";
import {
  TypographyKeys,
  TypographyTabFormData,
  ThemeOption,
  TypographyInputOptions,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

import Icons from "assets";

const TypographySectionContent = ({
  sectionName,
  sectionTitle,
  content,
}: {
  sectionName: TypographyKeys;
  sectionTitle: string;
  content: TypographyTabInputType[];
}) => {
  // getting context
  const { isEditMode, setTypography, typography, colors } = useThemePreviewContext();

  const TYPOGRAPHY_OPTIONS = useTypographyOptions();

  const isThemeSet = typography && colors;

  //form
  const {
    control,
    formState: { errors },
    watch,
    resetField,
    clearErrors,
  } = useFormContext();

  const typographyErrors = errors?.typography as Record<
    string,
    FieldError | Merge<FieldError, FieldErrorsImpl<TypographyTabFormData>>
  >;
  const typographyErrorsSection = typographyErrors?.[sectionName] as Record<
    string,
    FieldError | Merge<FieldError, FieldErrorsImpl<TypographyInputOptions>>
  >;

  const getSelectValue = (name: keyof TypographyInputOptions, field: any) => {
    return (
      isThemeSet && {
        value: field.value,
        label: (
          <div className="flex gap-1 relative w-full">
            {name === "textColor" && <ColorSwatch color={colors[field.value]} />}
            {field.label}
            {name === "textColor" && typography[sectionName]?.textColor?.isAlertShown && (
              <Icons.ExclamationCircle className="overflow-visible absolute text-wrong-text -bottom-0.5 size-6 left-[180px]" />
            )}
          </div>
        ),
      }
    );
  };

  const handleChange = (
    input: ThemeOption,
    field: string,
    fieldOnChange: (value: ThemeOption) => void,
  ) => {
    const { label, value, isAlertShown } = input;
    const inputVal =
      field === "textColor" && isAlertShown ? { label, value, isAlertShown } : { label, value };

    setTypography((prev: TypographyTabFormData | null) => {
      if (!prev) return prev;

      return {
        ...prev,
        [sectionName]: {
          ...prev[sectionName],
          [field]: inputVal,
        },
      };
    });

    if (field === "textColor" && value !== "custom") {
      resetField(`typography.${sectionName}.customColor`, { defaultValue: "" });
      setTypography((prev: TypographyTabFormData | null) => {
        if (!prev) return prev;

        return {
          ...prev,
          [sectionName]: {
            ...prev[sectionName],
            customColor: "",
          },
        };
      });
    }

    fieldOnChange({ label, value });
  };

  // Seperate function to handle changes to custom color because it is structured differently than other textColor options
  const handleColorChange = (
    value: string,
    field: string,
    fieldOnChange: (value: string) => void,
  ) => {
    const hasError = typographyErrorsSection?.customColor ?? false;
    if (value !== "#" && !hasError) {
      setTypography((prev: TypographyTabFormData | null) => {
        if (!prev) return prev;

        return {
          ...prev,
          [sectionName]: {
            ...prev[sectionName],
            [field]: value,
          },
        };
      });
    }
    fieldOnChange(value);
  };

  return (
    isThemeSet && (
      <div className="flex flex-col font-semibold gap-5 text-sm">
        {content.map(({ name, label, isRequired }: TypographyTabInputType, ind: number) => (
          <Controller
            key={ind}
            name={`typography.${sectionName}.${name}`}
            control={control}
            render={({ field }) => (
              <div>
                <label className="pb-1.5">
                  {label === "Color" ? `${sectionTitle} ${label}` : label}
                </label>
                <div className="mt-1.5 grid grid-cols-1">
                  <CustomSelect
                    ariaLabel={label}
                    selectName={`typography-select-${name}`}
                    size="md"
                    options={TYPOGRAPHY_OPTIONS[name]}
                    isRequired={isRequired}
                    isDisabled={!isEditMode}
                    {...field}
                    value={getSelectValue(name, field.value)}
                    onChange={(el) => {
                      handleChange(el as ThemeOption, name as string, field.onChange);
                    }}
                  />
                </div>
              </div>
            )}
          />
        ))}
        {watch(`typography.${sectionName}.textColor`)?.value === "custom" && (
          <Controller
            name={`typography.${sectionName}.customColor`}
            control={control}
            render={({ field }) => (
              <div className="mt-2">
                <HexInput
                  label="Custom color"
                  isRequired={true}
                  isDisabled={!isEditMode}
                  handleCustomChange={(value, isColorPickerInvoked) => {
                    if (isColorPickerInvoked) clearErrors(`typography.${sectionName}.customColor`);

                    handleColorChange(value, "customColor", field.onChange);
                  }}
                  errMessage={(typographyErrorsSection?.customColor as FieldError)?.message}
                  inputNameKey="custom"
                  {...field}
                />
              </div>
            )}
          />
        )}
      </div>
    )
  );
};

export default TypographySectionContent;
