import { twMerge } from "tailwind-merge";

import Icons from "assets";
interface ContentTypeMismatchIconProps {
  customClass?: string;
}

const ContentTypeMismatchIcon = ({ customClass }: ContentTypeMismatchIconProps) => {
  return (
    <span title="Content type doesn't match the campaign objective.">
      <Icons.WarningIndicator customClass={twMerge("text-warning-text", customClass)} />
    </span>
  );
};

export default ContentTypeMismatchIcon;
