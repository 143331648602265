import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertStatus, BoxProps, ComponentWithAs, Fade, useDisclosure } from "@chakra-ui/react";
import {
  CheckCircleIcon,
  IconProps,
  InfoIcon,
  WarningIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";

import AlertBox from "components/partials/alert-box/alert-box";

export type AlertMessage = {
  status: AlertStatus;
  title: string;
  message?: string;
  button?: { path: string; label: string; variant?: "primary" | "secondary" };
  link?: {
    label: string;
    route: string;
  };
};

const Flash = ({ ...rest }: BoxProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const flashRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [flashMessages, setFlashMessages] = useState<AlertMessage[] | undefined>();

  function createFlashMessages() {
    // Note: Does this actually need to allow for an array of messages?
    Object.entries<AlertMessage | AlertMessage[]>(location.state as any).flatMap(([_, messages]) =>
      setFlashMessages(arrayify(messages)),
    );
  }

  function showFlashMessages() {
    createFlashMessages();
    onOpen();
    setTimeout(() => {
      // onOpen() needs to fire before scrolling
      flashRef.current?.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
    });
  }

  function dismissFlashMessages() {
    // Note: This clears all messages, not just one, but we don't currently show any arrays of messages.
    setFlashMessages(undefined);
  }

  function handleClose() {
    onClose();
    setTimeout(() => {
      navigate(".", { replace: true, state: undefined });
    }, 150);
  }

  useEffect(
    function setOrClearFlashMessages() {
      if (location?.state && Object.entries((location.state as any).flash).length > 0) {
        showFlashMessages();
      }
      return () => {
        dismissFlashMessages();
      };
    },
    // eslint-disable-next-line
    [location.state],
  );

  if (!flashMessages) {
    return null;
  }

  return (
    <Fade ref={flashRef} in={isOpen} style={{ marginBottom: "20px" }}>
      {flashMessages.map((flash, index) => (
        <AlertBox alert={flash} handleClose={handleClose} key={index} {...rest} />
      ))}
    </Fade>
  );
};

export default Flash;

function arrayify<T>(thing: T | T[]): T[] {
  return Array.isArray(thing) ? [...thing] : [thing];
}

// This function is no longer being used in this component,
// but it is still being imported into ConfirmationModal.tsx
// ... which should be refactored later to not use it.
export function determineIconAndColorForFlashType(
  type: string,
): [ComponentWithAs<"svg", IconProps> | null, string] {
  switch (type) {
    case "danger":
    case "error":
      return [WarningIcon, "error.100"];
    case "warning":
      return [WarningTwoIcon, "warning.100"];
    case "success":
      return [CheckCircleIcon, "success.100"];
    case "info":
      return [InfoIcon, "info.100"];
    default: {
      return [null, "transparent"];
    }
  }
}
