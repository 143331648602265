import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import { ClientsPage } from "containers/admin/clients/clients-page";
import { AddClientPage } from "containers/admin/clients/client/add-client-page/add-client-page";
import ClientProvider from "containers/admin/clients/client/components/client-provider/client-provider";
import ClientSecondaryNav from "containers/admin/clients/client/components/client-secondary-nav/client-secondary-nav";
import { ProfileRoutes } from "containers/admin/clients/client/profile-page/profile-routes";
import ThemePage from "containers/admin/clients/client/theme-page/theme-page";
import { CampaignsRoutes } from "containers/admin/clients/client/campaigns-page/campaigns-routes";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const shouldHideClientNav = (pathname: string): boolean => {
  // Check if we're in a specific campaign route
  const campaignMatch = pathname.match(/\/campaigns\/[\w-]+\/?/);
  return !!campaignMatch;
};

const ClientLayout = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const hideNav = shouldHideClientNav(location.pathname);

  return (
    <>
      {!hideNav && <ClientSecondaryNav />}
      {children}
    </>
  );
};

const ClientsRoutes = () => {
  usePagePermission(Permission.PERM_CLIENT_READ);

  return (
    <Routes>
      <Route index element={<ClientsPage />} />
      <Route path="new" element={<AddClientPage />} />

      <Route
        path=":clientId/*"
        element={
          <ClientProvider>
            <ClientLayout>
              <Outlet />
            </ClientLayout>
          </ClientProvider>
        }>
        <Route path="campaigns/*" element={<CampaignsRoutes />} />

        <Route path="theme/*" element={<ThemePage />} />

        <Route path="profile/*" element={<ProfileRoutes />} />
      </Route>
    </Routes>
  );
};

export default ClientsRoutes;
