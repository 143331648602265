import { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import Field from "components/partials/field/field";
import LegacyButton from "components/forms/button/button";
import LegacyToggle from "components/partials/toggle/toggle";

import {
  IEmailTypesDetails,
  ISenderDetails,
  IEmailTypesSendersForm,
} from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.types";

import { EMAIL_TYPES_DETAILS_VALIDATION_SCHEMA } from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.constants";
import { NEW_SENDER_DATA } from "containers/admin/clients/client/components/email-types/email-types.constants";

import Icons from "assets";

const EmailTypesSendersForm = ({ onCancel, saveSenders, email }: IEmailTypesSendersForm) => {
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  //form
  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    watch,
    getValues,
    setValue,
  } = useForm<IEmailTypesDetails>({
    mode: "onChange",
    defaultValues: {
      senders: email.senders,
    },
    resolver: EMAIL_TYPES_DETAILS_VALIDATION_SCHEMA,
  });

  const { fields, append, remove } = useFieldArray({
    name: "senders",
    control,
    keyName: "_tempHookFormId",
  });

  const watchedAllSendersFilled = watch("senders").every((senderData: ISenderDetails) => {
    const { senderName, replyToEmail, senderEmail } = senderData;

    return (
      Object.keys({ senderName, replyToEmail, senderEmail }) as Array<keyof ISenderDetails>
    ).every((key) => !!senderData[key]);
  });

  const resetSenders = (newDefaultSender: string) => {
    for (let i = 0; i < fields.length; i++) {
      fields[i]._tempHookFormId !== newDefaultSender &&
        setValue(`senders.${i}.defaultForEmailType`, false);
    }
  };

  const formatSenderData = (formValues: IEmailTypesDetails) => {
    const formattedSendersData = fields.map((field, ind) => {
      const updatedSender = {
        ...formValues.senders[ind],
        //react-hook-form has issues with boolean values and it can not register them properly so that's why we set it manually below
        defaultForEmailType: getValues(`senders.${ind}.defaultForEmailType`),
      };

      const newSender = field.id
        ? {
            ...updatedSender,
            //set id if sender existed
            id: field?.id,
          }
        : //if sender is new - set it without id
          updatedSender;

      return newSender;
    });

    saveSenders(formattedSendersData);
  };

  useEffect(() => {
    const noErrorsOccured = !errors.senders?.length;

    const atLeastOneFieldTouched = !!dirtyFields?.senders?.length;

    //check if all 3 fields in sender data filled, if there are no errs and if at least one field is touched
    const isDisabled = !(watchedAllSendersFilled && noErrorsOccured && atLeastOneFieldTouched);

    setIsSubmitBtnDisabled(isDisabled);

    // eslint-disable-next-line
  }, [watchedAllSendersFilled, dirtyFields?.senders?.length, errors.senders?.length]);

  return (
    <form
      onSubmit={handleSubmit(formatSenderData)}
      data-testid="email-types-details-table"
      className="flex flex-col gap-2">
      {fields.map((formField, index) => {
        const watchDefaultSender = watch(`senders.${index}.defaultForEmailType`);

        return (
          <section
            className={twMerge(
              "flex flex-col border border-gray-1 rounded-md p-5 gap-2",
              fields.length > 1 && "pb-2",
            )}
            key={formField._tempHookFormId}>
            <div className="flex justify-between">
              <span className="font-semibold text-navy">Sender {index + 1}</span>
              <Controller
                name={`senders.${index}.defaultForEmailType`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <LegacyToggle
                    toggleName="Default sender"
                    uncheckedTextColor="gray.300"
                    customClass={value ? "!opacity-50" : ""}
                    isChecked={value}
                    setIsChecked={(value) => {
                      if (value) {
                        resetSenders(formField._tempHookFormId);
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
            </div>
            <Controller
              name={`senders.${index}.senderName`}
              control={control}
              render={({ field }) => (
                <Field
                  isRequired
                  errMessage={errors?.senders?.[index]?.senderName?.message}
                  data-testid={"sender-name-input" + index}
                  label="Sender name"
                  {...field}
                  value={field.value || ""}
                />
              )}
            />
            <Controller
              name={`senders.${index}.senderEmail`}
              control={control}
              render={({ field }) => (
                <Field
                  isRequired
                  errMessage={errors?.senders?.[index]?.senderEmail?.message}
                  data-testid="sender-email-input"
                  label="Sender email"
                  {...field}
                  value={field.value || ""}
                />
              )}
            />
            <Controller
              name={`senders.${index}.replyToEmail`}
              control={control}
              render={({ field }) => (
                <Field
                  isRequired
                  errMessage={errors?.senders?.[index]?.replyToEmail?.message}
                  data-testid="reply-to-email-input"
                  label="Reply-to email"
                  {...field}
                  value={field.value || ""}
                />
              )}
            />
            {fields.length > 1 && (
              <div>
                <LegacyButton
                  variant="link"
                  size="sm"
                  cursor={watchDefaultSender ? "default" : "pointer"}
                  disabled={watchDefaultSender}
                  opacity={watchDefaultSender ? "50%" : "initial"}
                  onClick={() => !watchDefaultSender && remove(index)}
                  leftIcon={<Icons.Trash className="mb-1" />}>
                  Delete
                </LegacyButton>
              </div>
            )}
          </section>
        );
      })}
      <div>
        <LegacyButton
          variant="secondary"
          size="sm"
          onClick={() => {
            setIsSubmitBtnDisabled(true);
            append({
              ...NEW_SENDER_DATA,
              defaultForEmailType: false,
            });
          }}>
          Add sender details
        </LegacyButton>
      </div>
      <div className="flex gap-4 mt-3">
        <LegacyButton variant="tertiary" onClick={onCancel}>
          Cancel
        </LegacyButton>
        <LegacyButton type="submit" isDisabled={isSubmitBtnDisabled}>
          Save changes
        </LegacyButton>
      </div>
    </form>
  );
};

export default EmailTypesSendersForm;
