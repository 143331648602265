import { Content } from "components/partials/layout/layout";

const ThemeVersionHistory = () => {
  return (
    <Content pb={4} pt={4}>
      Version History
    </Content>
  );
};

export default ThemeVersionHistory;
