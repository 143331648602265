import { Controller, useFormContext, FieldError } from "react-hook-form";

import HexInput from "containers/admin/clients/client/theme-page/preview/components/hex-input/hex-input";
import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import { PreviewSidebarInputHexType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";
import {
  ColorsTabFormData,
  ThemeOption,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

const ColorsSectionContent = ({ content }: { content: PreviewSidebarInputHexType[] }) => {
  const { setColors, typography, colors, button, isEditMode } = useThemePreviewContext();

  //form
  const {
    control,
    formState: { errors },
    clearErrors,
    resetField,
    setValue,
  } = useFormContext();

  const colorsErrors = errors?.colors as Record<string, FieldError>;

  const updateTypographyColor = (
    field: string,
    updateFn: (option: ThemeOption) => ThemeOption,
    shoulUpdateFormValue?: boolean,
  ) => {
    //get section names where colors may be applied
    if (typography) {
      const sectionsWhereColorUsed = Object.keys(typography).filter(
        (sectionName) => typography[sectionName].textColor.value === field,
      );

      if (sectionsWhereColorUsed.length) {
        for (const section of sectionsWhereColorUsed) {
          typography[section].textColor = updateFn(typography?.[section].textColor);

          // onBlur we update the value for each select with chosen optional color on Typography tab to Intense color
          if (shoulUpdateFormValue) {
            setValue(`typography.${section}.textColor`, {
              label: "Neutrals/Intense",
              value: "intense",
              isAlertShown: !!colorsErrors?.intense?.message,
            });
          }
        }
      }
    }
  };

  const updateButtonColor = (
    field: string,
    updateFn: (option: ThemeOption) => ThemeOption,
    shouldUpdateFormValue?: boolean,
  ) => {
    if (button) {
      // Check and update backgroundColor if it matches
      if (button.backgroundColor.name.value === field) {
        button.backgroundColor.name = updateFn(button.backgroundColor.name);

        if (shouldUpdateFormValue) {
          setValue("button.backgroundColor", {
            ...button.backgroundColor,
            name: {
              label: "Neutrals/Intense",
              value: "intense",
              isAlertShown: !!colorsErrors?.intense?.message,
            },
          });
        }
      }
    }
  };

  const handleChange = (value: string, fieldOnChange: (value: string) => void, name: string) => {
    const hasError = colorsErrors?.[name] ?? false;

    //setting colors in case we don't have errors
    if (colors && value !== "#" && !hasError) {
      setColors((prev: ColorsTabFormData | null) => {
        if (!prev) return prev;

        return {
          ...prev,
          [name]: value,
        };
      });
    }

    //set warning icons to Typography selects in case current color has an error
    updateTypographyColor(name, (color) => {
      return {
        ...color,
        isAlertShown: !!hasError,
      };
    });

    // set warning icons to button selects
    updateButtonColor(name, (color) => {
      return {
        ...color,
        isAlertShown: !!hasError,
      };
    });

    //setting react-hook-form onChange value
    fieldOnChange(value);
  };

  const handleBlurForNotRequiredField = (inputNameKey: string) => {
    resetField(`colors.${inputNameKey}`, { defaultValue: "" });

    //set intense color for selects where optional colors were set for typography and button tabs
    updateTypographyColor(
      inputNameKey,
      (_) => ({
        label: "Neutrals/Intense",
        value: "intense",
        isAlertShown: !!colorsErrors?.intense?.message,
      }),
      true,
    );

    updateButtonColor(
      inputNameKey,
      (_) => ({
        label: "Neutrals/Intense",
        value: "intense",
        isAlertShown: !!colorsErrors?.intense?.message,
      }),
      true,
    );
  };

  return (
    <div className="flex flex-col gap-5">
      {content.map((el: PreviewSidebarInputHexType, ind: number) => (
        <Controller
          key={ind}
          disabled={!isEditMode}
          name={`colors.${el.inputNameKey}`}
          control={control}
          render={({ field }) => (
            <HexInput
              label={el.label}
              isDisabled={!isEditMode}
              handleCustomChange={(value, isColorPickerInvoked) => {
                if (isColorPickerInvoked) clearErrors(`colors.${el.inputNameKey}`);

                handleChange(value, field.onChange, el.inputNameKey as string);
              }}
              errMessage={colorsErrors?.[el.inputNameKey]?.message}
              inputNameKey={el.inputNameKey}
              isRequired={el.isRequired}
              handleBlurForNotRequiredField={handleBlurForNotRequiredField}
              {...field}
            />
          )}
        />
      ))}
    </div>
  );
};

export default ColorsSectionContent;
