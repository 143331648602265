import { TabItem } from "hooks/use-tab";

import Icons from "assets";

interface ToggleOptionsConfig {
  /**
   * Array of tab items: `{ name: string, path: string }` */
  tabs: TabItem[];
  /**
   * A function that returns a boolean to determine if an icon should be shown for a tab */
  shouldShowIcon: (tabPath: string) => boolean;
  /**
   * An optional custom icon component to show for each tab.
   * Will show `Icons.ExclamationCircle` in `currentColor` by default. */
  Icon?: React.ReactElement;
}
export const mapToggleOptions = ({ tabs, shouldShowIcon, Icon }: ToggleOptionsConfig) => {
  return tabs.map((tab) => ({
    label: (
      <span key={tab.path} className="flex items-center gap-2">
        {tab.name}
        {shouldShowIcon(tab.path) && (Icon ?? <Icons.ExclamationCircle role="alert" />)}
      </span>
    ),
    value: tab.name,
  }));
};
