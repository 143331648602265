import { Navigate, Route, Routes } from "react-router-dom";

import Preview from "containers/admin/clients/client/theme-page/preview/preview";
import VersionHistory from "containers/admin/clients/client/theme-page/version-history/version-history";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import { Route as AppRoute } from "utilities/app-routes";

const ThemeRoutes = () => {
  const { colors, typography, fonts, button } = useThemePreviewContext();

  //define whether we have values or they are still not set
  const isThemeSet = colors && typography && fonts && button;

  return (
    <Routes>
      <Route index element={<Navigate to={AppRoute.preview} />} />

      {isThemeSet && (
        <Route
          path={AppRoute.preview + "/*"}
          element={<Preview {...{ colors, typography, fonts, button }} />}
        />
      )}
      <Route path={AppRoute.versions + "/*"} element={<VersionHistory />} />
    </Routes>
  );
};

export default ThemeRoutes;
