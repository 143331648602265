import Icons from "assets";

interface IFieldLabel {
  name: string;
  label: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

const FieldLabel = ({ name, label, isDisabled, isRequired }: IFieldLabel) => {
  return (
    <label htmlFor={name} id={`${name}-label`} className="w-full text-sm font-bold text-text-label">
      {label}
      {!isDisabled && isRequired ? (
        <span>
          <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
        </span>
      ) : null}
    </label>
  );
};

export default FieldLabel;
