import { forwardRef, ForwardedRef } from "react";
import ReactSelect, {
  components,
  SingleValueProps,
  MultiValueRemoveProps,
  OptionProps,
  GroupBase,
} from "react-select";
import { twMerge } from "tailwind-merge";

import { getBasicSelectStyles } from "./custom-select.helper";

import { IThemedSelectProps } from "./custom-select.types";

import Icons from "assets";

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icons.Close />
    </components.MultiValueRemove>
  );
};

const Option = <OptionType extends { icon?: React.ReactNode; isAlertShown?: boolean }>({
  ...rest
}: OptionProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const icon = rest?.data.icon;
  const isAlertShown = rest?.data.isAlertShown;

  return (
    <components.Option {...rest}>
      <div className="flex w-full items-center justify-between">
        <div className="flex">
          {icon && <div className="mr-1">{icon}</div>}
          {rest?.label}
        </div>
        {isAlertShown && (
          <Icons.ExclamationCircle
            className={twMerge("mr-7 text-red-700 size-6", rest.isSelected && "text-white")}
          />
        )}
      </div>
    </components.Option>
  );
};

const SingleValue = <OptionType extends { icon?: React.ReactNode; isAlertShown?: boolean }>({
  children,
  ...rest
}: SingleValueProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const icon = rest?.data.icon;
  const isAlertShown = rest?.data.isAlertShown;

  return (
    <components.SingleValue {...rest}>
      <div className="relative flex w-full items-center justify-between">
        <div className="flex">
          {icon && <div className="mr-1">{icon}</div>}
          {children}
        </div>
        {isAlertShown && <Icons.ExclamationCircle className="mr-4 text-red-700 size-6" />}
      </div>
    </components.SingleValue>
  );
};

const CustomSelect = forwardRef(
  <OptionType,>(
    {
      label,
      selectName,
      size = "md",
      options,
      defaultValue,
      isMulti = false,
      isDisabled = false,
      isSearchable = false,
      isRequired = false,
      errMessage,
      helperText,
      ariaLabel = "CustomSelect an option",
      ...props
    }: IThemedSelectProps<OptionType>,
    ref: ForwardedRef<any>,
  ) => {
    const isMiddle = size === "md";

    return (
      <div className="flex w-full flex-col gap-1">
        {!!label && (
          <label
            htmlFor={selectName}
            id={`${selectName}-label`}
            className={twMerge(
              "flex w-full flex-wrap gap-1 text-base font-semibold",
              !isMiddle && "text-sm",
            )}>
            {label}
            {!isDisabled && isRequired && <Icons.RequiredIndicator />}
          </label>
        )}
        <ReactSelect
          aria-label={ariaLabel}
          aria-errormessage={errMessage}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          name={selectName}
          options={options}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          components={{
            MultiValueRemove,
            Option,
            SingleValue,
          }}
          backspaceRemovesValue={true}
          styles={getBasicSelectStyles(isMiddle, !!errMessage)}
          ref={ref}
          {...props}
        />
        {!!helperText && !errMessage && (
          <span className={twMerge("text-base", !isMiddle && "text-sm")}>{helperText}</span>
        )}
        {!!errMessage && (
          <span className={twMerge("text-base text-core-error", !isMiddle && "text-sm")}>
            {errMessage}
          </span>
        )}
      </div>
    );
  },
);

export default CustomSelect;
