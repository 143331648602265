import ColorsSectionContent from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/colors-section-content";

import {
  PreviewSidebarInputHexType,
  PreviewSidebarItemType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

export const BRAND_COLORS: PreviewSidebarInputHexType[] = [
  { isRequired: false, inputNameKey: "primary", label: "Primary color" },
  { isRequired: false, inputNameKey: "secondary", label: "Secondary color" },
  { isRequired: false, inputNameKey: "tertiary", label: "Tertiary color" },
];

export const NEUTRALS_COLORS: PreviewSidebarInputHexType[] = [
  { isRequired: true, inputNameKey: "intense", label: "Intense" },
  { isRequired: true, inputNameKey: "bold", label: "Bold" },
  { isRequired: true, inputNameKey: "soft", label: "Soft" },
  { isRequired: true, inputNameKey: "subtle", label: "Subtle" },
  { isRequired: true, inputNameKey: "white", label: "White" },
];

export const COLOR_TAB_SIDEBAR: PreviewSidebarItemType[] = [
  {
    titleText: "BRAND",
    children: <ColorsSectionContent content={BRAND_COLORS} />,
  },
  {
    titleText: "NEUTRALS",
    children: <ColorsSectionContent content={NEUTRALS_COLORS} />,
  },
];
