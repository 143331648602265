import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Stack } from "@chakra-ui/react";

import { Logo } from "components/partials/logo/logo";
import { ContentWrapper } from "components/partials/layout/layout";
import Button from "components/forms/button/button";
import { H1, H2 } from "components/partials/typography/typography";
import LogoutButton from "components/partials/logout-button/logout-button";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";
import { Route as AppRoute } from "utilities/app-routes";

import { Permission } from "types/auth";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const baseRouteBasedOnPermission = isClientUser ? AppRoute.reviews : AppRoute.clients;

  const redirctToDashboard = () => {
    navigate(baseRouteBasedOnPermission);
  };

  return (
    <ContentWrapper
      data-testid="404-page"
      background="primary"
      position="absolute"
      height="full"
      width="full"
      zIndex="overlay">
      <Stack align="center" justify="center" textAlign="center" flex="1">
        <Logo isClientUser={isClientUser} pr={10} />
        <H1 fontSize="9rem" color="white" lineHeight="short">
          404
        </H1>
        <H2 color="white" mb={14}>
          You're lost in space!
          <br />
          That page doesn't exist.
        </H2>
        <Flex>
          <Button px={8} mr={4} onClick={redirctToDashboard}>
            Return to homepage
          </Button>
          <LogoutButton variant={"secondary"} />
        </Flex>
      </Stack>
    </ContentWrapper>
  );
};

export default NotFound;
