import { useLocation } from "react-router-dom";

export interface TabItem {
  name: string;
  path: string;
}

export const useTab = (tabs: readonly TabItem[], defaultPath: string) => {
  const location = useLocation();

  // First, try to find a tab that matches the current URL
  const activeTab = tabs.find((tab) => location.pathname.includes(tab.path));
  if (activeTab) return activeTab.name;

  // If no match, try to find the default tab
  const defaultTab = tabs.find((tab) => tab.path === defaultPath);
  if (defaultTab) return defaultTab.name;

  // If all else fails, return the first tab
  return tabs[0].name;
};
