import React, { createContext, useMemo, useState, useCallback, useEffect } from "react";

import { useCurrentClient } from "state/ducks";
import ThemeConfig from "models/theme-config";

import {
  ColorsTabFormData,
  ThemeContextData,
  FontsTabFormData,
  TypographyTabFormData,
  ButtonTabFormData,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

import THEME_DEFAULT_DATA from "containers/admin/clients/client/theme-page/preview/mock-data/theme-default-data.json";

const INITIAL_THEME_STATE: ThemeContextData = {
  colors: THEME_DEFAULT_DATA.colors,
  setColors: () => {},
  fonts: THEME_DEFAULT_DATA.fonts,
  setFonts: () => {},
  typography: THEME_DEFAULT_DATA.typography,
  setTypography: () => {},
  button: THEME_DEFAULT_DATA.button,
  setButton: () => {},
  isEditMode: false,
  setIsEditMode: () => {},
  themeError: "",
};

export const ThemePreviewContext = createContext<ThemeContextData>(INITIAL_THEME_STATE);

export const ThemePreviewProvider = (props: React.PropsWithChildren) => {
  const [colors, setColors] = useState<ColorsTabFormData | null>(null);
  const [fonts, setFonts] = useState<FontsTabFormData | null>(null);
  const [typography, setTypography] = useState<TypographyTabFormData | null>(null);
  const [button, setButton] = useState<ButtonTabFormData | null>(null);
  const [themeError, setThemeError] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(INITIAL_THEME_STATE.isEditMode);
  const client = useCurrentClient();

  const getThemeForm = useCallback(async () => {
    try {
      const results: {
        colors?: ColorsTabFormData;
        fonts?: FontsTabFormData;
        typography?: TypographyTabFormData;
        button?: ButtonTabFormData;
      } = await ThemeConfig.getAll({
        clientId: client.id,
      });

      if (!results.colors && !results.fonts && !results.typography && !results.button) {
        //set default theme if it didn't come from BE
        setColors(INITIAL_THEME_STATE.colors);
        setFonts(INITIAL_THEME_STATE.fonts);
        setTypography(INITIAL_THEME_STATE.typography);
        setButton(INITIAL_THEME_STATE.button);
      } else {
        // set theme if it came from BE
        setColors(results.colors as ColorsTabFormData);
        setFonts(results.fonts as FontsTabFormData);
        setTypography(results.typography as TypographyTabFormData);
        setButton(results.button as ButtonTabFormData);
      }
    } catch (error) {
      setThemeError(error.response.data.title);

      console.error("Error fetch theme config", error.response.data.title);
    }
  }, [client.id]);

  useEffect(() => {
    getThemeForm();
    // eslint-disable-next-line
  }, []);

  const value = useMemo(
    () => ({
      colors,
      setColors,
      fonts,
      setFonts,
      typography,
      setTypography,
      button,
      setButton,
      isEditMode,
      setIsEditMode,
      themeError,
    }),
    [
      colors,
      setColors,
      fonts,
      setFonts,
      typography,
      setTypography,
      button,
      setButton,
      isEditMode,
      setIsEditMode,
      themeError,
    ],
  );

  return <ThemePreviewContext.Provider value={value} {...props} />;
};

export const useThemePreviewContext = () => {
  const context = React.useContext(ThemePreviewContext);
  if (context === undefined) {
    throw new Error("useThemePreviewContext must be used within a ThemePreviewProvider");
  }
  return context;
};
