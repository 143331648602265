import { Route, Routes } from "react-router-dom";

import { ProfilePage } from "./profile-page";
import { ProfileEditPage } from "./profile-edit-page/profile-edit-page";

import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";

export const ProfileRoutes = () => {
  usePagePermission(Permission.PERM_CLIENT_READ);
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  return (
    <Routes>
      <Route index element={<ProfilePage />} />
      <Route path="edit/*" element={<ProfileEditPage />} />
    </Routes>
  );
};
