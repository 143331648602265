import { useContext, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import parse, {
  DOMNode,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";

import CommentContext from "contexts/comment-context";
import CommentableModule from "containers/admin/clients/touchpoint/components/comments/commentable-module/commentable-module";
import { DesignPreviewSandbox } from "containers/admin/clients/touchpoint/components/design-preview-sandbox/design-preview-sandbox";

import {
  BEE_COMMENT_WRAPPER_SELECTOR,
  BEE_LP_SELECTOR,
  BLANK_BEE_TEMPLATE,
} from "utilities/constants";
import { BuilderJsonObject, findModules, Module } from "utilities";

import { TouchpointPreviewProps } from "types/touchpoint";
import { Element } from "domhandler/lib/node";

import {
  removeComments,
  replaceRootWordsWithPrefix,
  prefixSelectorsThatFollowBraces,
  prefixSelectorsThatFollowCommas,
  prefixSelectorsWithinMediaQueries,
  prefixOrReturnString,
  excludeStylesWithinSelector,
  getStylesWithinHtmlStyleTags,
  compressStyles,
  sanitizeHtmlContent,
} from "utilities/css";

interface LPDesignPreviewProps extends TouchpointPreviewProps {}

const LPDesignPreview = ({
  htmlContent,
  isCommentMode = false,
  mobileView = false,
  htmlJSON,
}: LPDesignPreviewProps) => {
  const originalJSON: BuilderJsonObject = JSON.parse(htmlJSON || BLANK_BEE_TEMPLATE);
  const { commentData } = useContext(CommentContext);
  const modules: Module[] = findModules(originalJSON);

  /** Complete touchpoint HTML including <html> element */
  const landingPageHtml: string = sanitizeHtmlContent(htmlContent);

  /** Add a prefix to string of styles between `<style>` and `</style>` */
  function addPrefixToStyles(prefix: string, styles: string) {
    //Before adding prefixes we need to make sure we don't have classes the same as prefix
    //If yes - we must remove them to avoid ".bee-page-container .bee-page-container" class which breaks UI
    const regex = /\.bee-page-container(?=\{)/g;
    let prefixedStyles = styles.replace(regex, "");

    [
      removeComments,
      replaceRootWordsWithPrefix,
      prefixSelectorsThatFollowBraces,
      prefixSelectorsThatFollowCommas,
      prefixSelectorsWithinMediaQueries,
      prefixOrReturnString,
    ].forEach((cssFunction) => {
      prefixedStyles = cssFunction(prefixedStyles, prefix);
    });

    prefixedStyles = excludeStylesWithinSelector(
      prefixedStyles,
      prefix,
      BEE_COMMENT_WRAPPER_SELECTOR,
    );

    const socialBlockStyles = `.bee-social .content {display:flex;}`;

    return prefixedStyles.concat(socialBlockStyles);
  }

  useEffect(
    /**
     * Landing Page previews are created from touchpoint HTML that includes all standard HTML tags, such as `<style>`, `<script>`, `<title>` and `<meta>`. The touchpoint HTML is added inline, not via iFrame, to allow our commenting system to work. This creates conflicts with existing page styles, scripts, etc. To prevent those conflicts, we remove or namespace tags and styles so that they only affect the preview HTML. */
    function sandboxThePreviewStyles() {
      /**
       * An array of styles from between sets of <style></style> tags */
      const allPrefixedStyles: string[] = getStylesWithinHtmlStyleTags(landingPageHtml)
        .map((styles) => compressStyles(styles))
        .map((styles) => {
          const prefix = BEE_LP_SELECTOR + " ";

          const phoneInputPlaceholderStyles = `.to-form__input-tel{
            position: relative;
            display: inline-block;
        }
        .to-form__input-tel::before {
            content: "";
            position: absolute;
            left: 5px;
            top: 55%;
            width: 35px;
            height: 20px;
            background-image: url('/phone-input-placeholder.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }`;
          const desktopStyles = addPrefixToStyles(prefix, styles).concat(
            phoneInputPlaceholderStyles,
          );
          /**
           * We are faking a mobile viewport and must change media queries to container queries to show layout correctly. */

          const mobileStyles = desktopStyles
            .replaceAll("@media", "@container")
            .concat(`.bee-page-container {container-type: inline-size;}`);

          return mobileView ? mobileStyles : desktopStyles;
        });

      /** Merge prefixed styles into one new `<style></style>` tag */
      const previewStylesTag = document.createElement("style");
      previewStylesTag.textContent = allPrefixedStyles.join("");

      /** Add preview style tag to the end of document `<head>` */
      document.head.appendChild(previewStylesTag);

      return () => {
        /** Remove preview style tag on unmount */
        document.head.removeChild(previewStylesTag);
      };
    },
    [landingPageHtml, htmlContent, mobileView],
  );

  const isElement = (domNode: DOMNode): domNode is Element => {
    const isTag = domNode.type === "tag";
    const hasAttributes = (domNode as Element).attribs !== undefined;
    return isTag && hasAttributes;
  };

  /**
   * These are the nodeClassNames appended to modules in `LPBuilderDesignTab` that a user can leave comments on. Spacer modules cannot receive comments. */
  const moduleClassNames = [
    "bee-button",
    "bee-divider",
    "bee-heading",
    "bee-html-block",
    "bee-icons",
    "bee-image",
    "bee-menu",
    "bee-social",
    "bee-text",
    "bee-video",
    "bee-paragraph",
    "bee-spacer",
    "bee-list",
  ];

  let count = 0;

  const CommentableModules: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        !isElement(domNode) ||
        (isElement(domNode) && (!domNode.attribs || !domNode.attribs.class))
      ) {
        return;
      }

      const nodeClassNames = domNode.attribs.class.split(" ");

      if (moduleClassNames.some((className) => nodeClassNames.includes(className))) {
        const props = attributesToProps(domNode.attribs);
        const children = domToReact(domNode.children, CommentableModules);
        const moduleId = modules[count].descriptor.id;
        count++;
        const comment = commentData.content.filter(
          (comment) => comment.annotationId === moduleId,
        )[0];

        return (
          <Box position="relative" {...props}>
            <Box position="relative" cursor="pointer">
              {children}
              <CommentableModule moduleId={moduleId} comment={comment} />
            </Box>
          </Box>
        );
      }
    },
  };

  const designPreviewContent = parse(
    landingPageHtml.substring(
      landingPageHtml.indexOf("<body>") + "<body>".length,
      landingPageHtml.lastIndexOf("</body>"),
    ),
    isCommentMode ? CommentableModules : undefined,
  );

  return (
    <DesignPreviewSandbox
      data-testid="lp-builder__preview-sandbox"
      isCommentMode={isCommentMode}
      isMobileView={mobileView}>
      {designPreviewContent}
    </DesignPreviewSandbox>
  );
};

export default LPDesignPreview;
