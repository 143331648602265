import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { LandingPage } from "models";
import { LandingPageAttributes } from "models/landing-page";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";

import BuilderForm from "containers/admin/clients/touchpoint/components/builder/builder-form/builder-form";
import {
  CustomInput,
  CustomSelect,
  CustomTextarea,
} from "components/new/forms/custom-form-inputs/custom-form-inputs";
import { BuilderTabName } from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";

import {
  createZodSchemaFromFormFields,
  CustomInputProps,
  enforceValidSubdirectory,
  CustomFormField,
} from "utilities/forms";

interface LPBuilderContentSettingsTabProps {
  previewMode: boolean;
  formFields: CustomFormField[];
  onSave: (field: Record<string, any>) => Promise<void>;
  setIsSaved: (isSaved: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setInvalidTabs: React.Dispatch<React.SetStateAction<BuilderTabName[]>>;
}

const LPBuilderContentSettingsTab = ({
  previewMode = false,
  formFields,
  onSave,
  setIsSaved,
  setIsSaving,
  setInvalidTabs,
}: LPBuilderContentSettingsTabProps) => {
  const { attributes: initialAttributes } = useTouchpointBuilderContent() as LandingPage;

  const zodSchema = createZodSchemaFromFormFields(formFields);

  type FormValues = z.infer<typeof zodSchema>;

  const [hasChanges, setHasChanges] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });

  useEffect(
    function setInitialValues() {
      formFields.forEach((field) => {
        const fieldName = field.name as keyof LandingPageAttributes;
        const initialValue = initialAttributes[fieldName] || field.defaultValue;
        setValue(fieldName, initialValue ? `${initialValue}` : "");
      });

      const { redirectDestination, redirectDestinationType } = getValues();
      const selectValue = "redirectDestinationSelect";

      switch (redirectDestinationType) {
        case "URL":
          setValue(selectValue, "custom-url");
          break;
        case "TOUCHPOINT":
          setValue(selectValue, redirectDestination);
          break;
        default:
          setValue(selectValue, "");
          break;
      }
    },
    // eslint-disable-next-line
    [formFields],
  );

  const watchValues = watch();

  const urlValue = watch("url");

  useEffect(() => {
    // Prevent a user from typing anything other than letters into the Page URL field.
    if (!urlValue) return;

    const sanitizedValue = enforceValidSubdirectory(urlValue);
    if (urlValue !== sanitizedValue) {
      setValue("url", sanitizedValue);
    }
  }, [urlValue, setValue]);

  useEffect(() => {
    // Set redirectDestination and redirectDestinationType based on redirectDestinationSelect value.
    const subscription = watch((value, { name }) => {
      if (name === "redirectDestinationSelect") {
        const selectedValue = value.redirectDestinationSelect;

        if (selectedValue === "" || selectedValue === undefined) {
          setValue("redirectDestination", "");
          setValue("redirectDestinationType", "");
        } else if (selectedValue === "custom-url") {
          setValue("redirectDestination", "");
          setValue("redirectDestinationType", "URL");

          // Set the initial value for redirectDestination if it's empty
          if (!initialAttributes.redirectDestination) {
            setValue("redirectDestination", "");
          }
        } else {
          setValue("redirectDestination", selectedValue);
          setValue("redirectDestinationType", "TOUCHPOINT");
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, initialAttributes]);

  const hasErrors = !!Object.keys(errors).length;

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const submitHandler = async (formData: FormValues) => {
        const { redirectDestinationSelect, ...dataToSubmit } = formData;
        await onSave(dataToSubmit);
      };

      await handleSubmit(submitHandler)();

      setIsSaved(true);
    } catch (error) {
      console.error("Failed to save", error);
      setIsSaved(false);
    } finally {
      reset(getValues());
      setIsSaving(false);
    }
  };

  /** Saves on blur if field value has been changed */
  const handleFieldBlur = async () => {
    if (hasChanges && isValid) {
      try {
        await handleSave();
      } catch (e) {
        console.error("Error when save onBlur", e);
      }
    }
  };

  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    /** Autosave after 2 second debounce if a change has been made and the form is valid */
    function autosave() {
      if (hasChanges && isValid) {
        saveTimeoutRef.current = setTimeout(() => {
          handleSave();
        }, 2000);
      }

      return () => {
        if (saveTimeoutRef.current) {
          clearTimeout(saveTimeoutRef.current);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasChanges, isValid],
  );

  useEffect(() => {
    setHasChanges(!isSubmitting && isDirty);
  }, [isDirty, isSubmitting, setHasChanges]);

  useEffect(() => {
    const thisTab = "Content Settings";
    setInvalidTabs((prev) =>
      hasErrors ? [...prev, thisTab] : prev.filter((tab) => tab !== thisTab),
    );
  }, [hasErrors, setInvalidTabs]);

  /** Create dynamic string as user types, with trailing forward slash */
  const getUrlEyebrow = (field: CustomInputProps) => {
    const urlWithSlash = urlValue ? `${urlValue}/` : "";
    return `${field.addonStart}${urlWithSlash}`;
  };

  return (
    <BuilderForm errors={errors} formFields={formFields} onSubmit={() => handleSave()} noValidate>
      {formFields.map((field) => {
        if (field.component === "hidden" || (field.condition && !field.condition(watchValues))) {
          return null;
        }

        const dynamicUrlEyebrow =
          field.name === "url" ? getUrlEyebrow(field as CustomInputProps) : null;

        return (
          <div key={`${field.name}-${field.component}`}>
            <Controller
              control={control}
              name={field.name}
              render={({ field: { onChange, onBlur, value } }) => {
                const commonProps = {
                  disabled: previewMode,
                  error: errors[field.name],
                  eyebrow: dynamicUrlEyebrow ?? field.eyebrow,
                  helperText: field.helperText,
                  label: field.label,
                  name: field.name,
                  onChange,
                  onBlur: () => {
                    onBlur();
                    handleFieldBlur();
                  },
                  placeholder: field.placeholder,
                  required: !!field.validations?.isRequired,
                  value: value ?? "",
                };

                switch (field.component) {
                  case "select":
                    return <CustomSelect options={field.options} {...commonProps} />;
                  case "textarea":
                    return <CustomTextarea {...commonProps} />;

                  default:
                    return (
                      <CustomInput
                        type="text"
                        addonStart={field.addonStart}
                        addonEnd={field.addonEnd}
                        {...commonProps}
                      />
                    );
                }
              }}
            />
          </div>
        );
      })}
    </BuilderForm>
  );
};

export default LPBuilderContentSettingsTab;
