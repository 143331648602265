import { useNavigate } from "react-router-dom";
import {
  ModalProps,
  Modal,
  ModalContent,
  ModalOverlay,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  HStack,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { H4, P } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import Link from "components/partials/link/link";

import { Email, LandingPage, Theme } from "models";

interface LockingModalTempProps {
  content?: Email | LandingPage | Theme;
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  redirectUrl: string;
  previewUrl: string;
  claimLock?: () => void;
  resetLock?: (locked: boolean) => void;
}

export const LockingModal = ({
  content,
  isOpen,
  onClose,
  redirectUrl,
  previewUrl,
  claimLock,
}: LockingModalTempProps) => {
  const navigate = useNavigate();

  function backRedirect() {
    navigate(redirectUrl);
  }

  const openPreview = () => {
    navigate(previewUrl);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}>
        <Alert
          variant="left-accent"
          data-testid={`claim-lock-notification`}
          borderColor="#FFAB0F"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}>
          <WarningTwoIcon color="#FFAB0F" mr={6} mt={-1.85} boxSize={5} />
          <Flex direction="column">
            <AlertTitle as={H4}>
              {(content?.lockedBy && content.lockedBy.firstName) || "Someone"}{" "}
              {content?.lockedBy && content.lockedBy.lastName} is currently working on this content
            </AlertTitle>
            <AlertDescription mt={2}>
              You won't be able to make any changes unless you take over editing. Do you want to
              take over?
            </AlertDescription>
            <HStack my={8} spacing={6}>
              <Button onClick={openPreview} fontWeight="bold" variant={"secondary"}>
                Enter preview mode
              </Button>
              <Button onClick={claimLock} fontWeight="bold">
                Take Over Editing
              </Button>
            </HStack>
            <P>
              or
              <Link onClick={backRedirect} fontWeight="bold" ml={2} textDecoration="underline">
                {`exit builder`}
              </Link>
            </P>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  );
};

export default LockingModal;
