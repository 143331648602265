import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { COLOR_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/color.constants";

import Icons from "assets";

const Color = () => {
  return (
    <>
      <PreviewSidebar
        sidebarInfoWarning={
          <div className="flex p-2 gap-1 rounded-md border border-neutral-4">
            <Icons.ExclamationCircle className="h-4 w-4 text-neutral-2 shrink-0" />
            <p className="text-sm">
              Changing these parameters may affect the configurations using it.
            </p>
          </div>
        }
        sidebarItems={COLOR_TAB_SIDEBAR}
      />
      <div className="flex-1 overflow-auto custom-scroll">
        <PreviewArea />
      </div>
    </>
  );
};

export default Color;
