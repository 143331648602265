import { useEffect } from "react";

import { Table, TableBody, TableHead } from "components/table/table";
import { TableHeader } from "components/table/table-header/table-header";
import { TableLoader } from "components/table/table-loader/table-loader";
import { InboxTableRow } from "components/table/inbox-table-row/inbox-table-row";
import { useInboxTableContext } from "components/table/inbox-table/inbox-table-context";

import { InboxItem, InboxTableColumnId, InboxTableColumns } from "types/inbox";

interface InboxTableProps {
  currentSearchResults: InboxItem[];
  currentSortOrder: string;
  handleTableSort: (columnId: InboxTableColumnId) => void;
  isClientUser: boolean;
  tableColumns: InboxTableColumns;
  isLoading: boolean;
}

export const InboxTable = ({
  currentSearchResults: tableRows,
  currentSortOrder,
  handleTableSort,
  isClientUser,
  tableColumns,
  isLoading,
}: InboxTableProps) => {
  
  const { setTableHasInvalidTouchpoints } = useInboxTableContext();

  useEffect(() => {
    setTableHasInvalidTouchpoints(tableRows.some((touchpoint) => !touchpoint.isValidContentType));
  }, [tableRows, setTableHasInvalidTouchpoints]);

  return (
    <Table>
      <TableHead>
        {tableColumns.map((column) => {
          if (!column || column.isDisplayed === false) return null;
          return (
            <TableHeader
              key={column.id}
              column={column}
              sortBy={currentSortOrder.split(",")}
              handleTableSort={handleTableSort}
            />
          );
        })}
      </TableHead>

      <TableBody>
        {isLoading ? (
          <TableLoader colSpan={tableColumns.length} />
        ) : (
          tableRows.map((row) => (
            <InboxTableRow
              key={row.touchpointVersionId}
              tableColumns={tableColumns}
              isClientUser={isClientUser}
              item={row}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
};
