import { useNavigate } from "react-router-dom";

import PageContent from "components/partials/page-content/page-content";
import { CampaignForm } from "containers/admin/clients/campaign/components/campaign-form/campaign-form";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks/clients";
import Campaign, { CampaignAttributes } from "models/campaign";
import { Unpersisted } from "models/model";

import { getErrorMessage } from "utilities";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const CampaignAddPage = () => {
  const navigate = useNavigate();
  const client = useCurrentClient();

  usePagePermission(Permission.PERM_CAMPAIGN_WRITE);

  const submitNewCampaign = (attributes: Unpersisted<CampaignAttributes>) => {
    Campaign.create(client.id, attributes)
      .then((campaign) => {
        toast.success({
          data: {
            title: "Campaign created",
            message: "Manage additional campaign settings from the campaign card",
          },
        });
        navigate(`/clients/${client.id}/campaigns/${campaign.id}`);
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create Campaign",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  return (
    <>
      <PageContent>
        <CampaignForm
          onSubmit={submitNewCampaign}
          onCancel={() => navigate(`/clients/${client.id}/campaigns`)}
        />
      </PageContent>
    </>
  );
};

export default CampaignAddPage;
