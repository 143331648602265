import { useNavigate } from "react-router-dom";
import { VStack } from "@chakra-ui/react";

import PageContent from "components/partials/page-content/page-content";
import { AddButton } from "components/partials/add-button/add-button";
import { H2 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";

import { useCurrentUser } from "state/ducks/users";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

import { HStack } from "@chakra-ui/react";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import {
  TableEditButton,
  TableViewButton,
} from "components/table/table-icon-button/table-icon-button";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

import Client from "models/client";

import { PaginatedRequestOptions } from "types/pagination";

export const ClientsPage = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  if (hasPermission(currentUser, Permission.PERM_CLIENT_USER)) {
    return null;
  }

  return (
    <PageContent>
      <VStack align="stretch" mt={8}>
        <PageContentHeader marginY={0}>
          <H2>Clients</H2>
          <AddButton aria-label="Add New Client" onClick={() => navigate("new")} />
        </PageContentHeader>
        <ClientTable />
      </VStack>
    </PageContent>
  );
};

const ClientTable = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const fetchClients = (options: PaginatedRequestOptions) => {
    return Client.all({ ...options, sort: "name" });
  };

  return (
    <PaginatedTable
      headers={["Client Name", "Actions"]}
      fetchPage={fetchClients}
      renderRow={(client: Client) => (
        <TableRow>
          <TableCell>
            <RouterLink to={`${client.id}/campaigns`}>{client.name}</RouterLink>
          </TableCell>
          <TableCell>
            <HStack spacing={5}>
              <TableViewButton
                title="View client"
                aria-label="View client"
                onClick={() => navigate(`${client.id}/campaigns`)}
              />
              {hasPermission(currentUser, Permission.PERM_CLIENT_WRITE) && (
                <TableEditButton
                  title="Edit client"
                  aria-label="Edit client"
                  onClick={() => navigate(client.id + "/profile/edit")}
                />
              )}
            </HStack>
          </TableCell>
        </TableRow>
      )}
    />
  );
};
