import { Image, ImageProps, Box } from "@chakra-ui/react";

interface LogoProps extends ImageProps {
  isClientUser?: boolean;
  testid?: string;
  useWhiteBgVersion?: boolean;
}
export const Logo = ({
  height = "24px",
  width = "128px",
  isClientUser,
  testid = "logo",
  useWhiteBgVersion = false,
  ...rest
}: LogoProps) => {
  const logoSrc = useWhiteBgVersion
    ? isClientUser
      ? "/logo-two-ocean-onwhite.svg"
      : "/logo-apollo-onwhite.svg"
    : isClientUser
      ? "/logo-two-ocean.svg"
      : "/logo-apollo.svg";

  return (
    <Box data-testid={testid} {...rest}>
      <Image
        alt="logo"
        height={height}
        src={logoSrc}
        width={width}
        objectFit="contain"
        objectPosition="left"
      />
    </Box>
  );
};
