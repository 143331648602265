import { Email, LandingPage, Theme } from "models";

export interface Link {
  to: string;
  text: string;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface ChildPageProps {
  parentUrl: string;
}

export interface TeamMember {
  firstName: string;
  imageUrl: string;
  lastName: string;
  id: string;
  userRole?: "ACCOUNT_STRATEGY";
  email?: string;
  profileHexColor?: string;
}

export type StatusString =
  | "DRAFT"
  | "PUBLISHED"
  | "CREATED"
  | "DELETED"
  | "APPROVED"
  | "REJECTED"
  | "ARCHIVED"
  | "SYSTEM_DRAFT";

export enum TouchpointStatuses {
  ARCHIVED = "ARCHIVED",
  ARCHIVED_DRAFT = "ARCHIVED_DRAFT",
  CREATED = "CREATED",
  DELETED = "DELETED",
  /**
   * This touchpoint version has not been sent for review. */
  DRAFT = "DRAFT",
  /**
   * The draft has been sent to a client and is awaiting feedback or approval. */
  CLIENT_REVIEW = "CLIENT_REVIEW",
  /**
   * The client has given feedback on a touchpoint draft. This feedback makes the draft "REJECTED". */
  REJECTED = "REJECTED",
  /**
   * After feedback, a new "SYSTEM_DRAFT" is created as a new draft from the one that was rejected. */
  SYSTEM_DRAFT = "SYSTEM_DRAFT",
  /**
   * A draft has been approved but is not published yet. */
  APPROVED = "APPROVED",
  /**
   * A draft has been published. */
  PUBLISHED = "PUBLISHED",
}

export const DEFAULT_CLIENT_REVIEW_STATUSES = {
  status: TouchpointStatuses.DRAFT,
  clientReviewStatusName: "",
  versionStatusName: "",
};

export enum CreativeContext {
  ACADEMICS = "ACADEMICS",
  APPLY = "APPLY",
  CAMPUS_LIFE = "CAMPUS_LIFE",
  EDUCATION_OUTCOMES = "EDUCATION_OUTCOMES",
  FINANCIAL_AID = "FINANCIAL_AID",
  VISIT = "VISIT",
  DEPOSIT = "DEPOSIT",
  NA = "N/A",
}

export type ZodFormErrors = { [k: string]: string[] };

export interface BeeContent {
  htmlOutput?: string;
  jsonOutput?: string;
}

export interface Image {
  id?: string;
  name?: string;
  type?: string;
  url?: string;
  lastModifiedDate?: string;
  byteSize?: number;
}

export interface TypekitFont {
  id?: string;
  name?: string;
  defaultFontWeight?: string;
  boldFontWeight?: string;
  fallbackFont?: string;
  slug?: string;
  css_names?: string[];
  css_stack?: string;
  variations?: string[];
}

//generated from a sample typekit response -> https://www.adobe.io/fonts/
// These are the old docs: https://fonts.adobe.com/docs/api/requests
export interface TypekitResponse {
  kit: {
    id: string;
    families: TypekitFamily[];
  };
}

export interface TypekitFamily {
  id?: string;
  name?: string;
  slug?: string;
  css_names?: string[];
  css_stack?: string;
  variations?: string[];
}

export type BuilderContent = Email | LandingPage | Theme;

export type TouchpointBuilderContent = Email | LandingPage;
