import { Avatar, Flex, FlexProps, Icon, Stack } from "@chakra-ui/react";
import { MdOutlineReply } from "react-icons/md";

import { CommentableHeader } from "containers/admin/clients/touchpoint/components/comments/commentable-header/commentable-header";

export interface EmailHeaders {
  senderName: string;
  senderEmail: string;
  replyToEmail: string;
  subjectLine: string;
  preheader: string;
  id?: string;
}

export const initialEmailHeaders: EmailHeaders = {
  senderName: "Not defined",
  senderEmail: "Not defined",
  replyToEmail: "Not defined",
  subjectLine: "[Email subject line]",
  preheader: "[Email preheader text...]",
};

interface EmailHeadersPreviewProps extends FlexProps {
  emailHeaders: EmailHeaders;
  isCommentMode: boolean;
  isMobileView?: boolean;
}
export const EmailHeaderPreview = ({
  emailHeaders,
  isCommentMode,
  isMobileView,
  ...rest
}: EmailHeadersPreviewProps) => {
  const headerDetails: EmailHeaders = {
    senderName: emailHeaders.senderName,
    replyToEmail: emailHeaders.replyToEmail,
    subjectLine: emailHeaders.subjectLine,
    preheader: emailHeaders.preheader,
    senderEmail: emailHeaders.senderEmail,
  };

  const avatarInitial = headerDetails.senderName.charAt(0);
  const avatarFill =
    avatarInitial === headerDetails.senderName.charAt(0) ? undefined : avatarInitial;

  const getHeaderSpacing = () => {
    const headerSpacing = isMobileView ? 1 : 2;
    return isCommentMode ? headerSpacing * 2 : headerSpacing;
  };

  return (
    <Flex data-testid="email-builder__preview-header" paddingX={4} paddingY={3} {...rest}>
      <Avatar name={avatarFill} marginRight={isCommentMode ? 4 : 0} />
      <Stack
        paddingLeft={3}
        flex={1}
        className={`text-neutral-2 text-sm ${isCommentMode ? "space-y-2" : "space-y-1.5"}`}>
        <Stack
          direction={isMobileView ? "column" : "row"}
          spacing={getHeaderSpacing()}
          sx={{ "& > div:last-child": { ml: isMobileView ? 0 : "auto" } }}>
          <CommentableHeader
            moduleId="senderName"
            isCommentMode={isCommentMode}
            marginRight={isCommentMode ? 4 : 0}>
            <span className="font-bold">{headerDetails.senderName}</span>
          </CommentableHeader>

          <CommentableHeader
            moduleId="senderEmail"
            isCommentMode={isCommentMode}
            marginLeft={isCommentMode ? 6 : 0}>
            <span>&lt;{headerDetails.senderEmail}&gt;</span>
          </CommentableHeader>

          <CommentableHeader moduleId="replyToEmail" isCommentMode={isCommentMode}>
            <div className="flex">
              <Icon as={MdOutlineReply} w="18px" h="20px" mr="3px" />
              <span>&lt;{headerDetails.replyToEmail}&gt;</span>
            </div>
          </CommentableHeader>
        </Stack>

        <CommentableHeader moduleId="subjectLine" isCommentMode={isCommentMode}>
          <span className="font-medium">{headerDetails.subjectLine}</span>
        </CommentableHeader>

        <CommentableHeader moduleId="preheader" isCommentMode={isCommentMode}>
          <span className="text-xs text-neutral-3">{headerDetails.preheader}</span>
        </CommentableHeader>
      </Stack>
    </Flex>
  );
};
