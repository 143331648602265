import React, { createContext, useState } from "react";

interface InboxTableContextData {
  tableHasInvalidTouchpoints: boolean;
  setTableHasInvalidTouchpoints: (value: boolean) => void;
}

const INITIAL_CONTEXT_STATE: InboxTableContextData = {
  tableHasInvalidTouchpoints: false,
  setTableHasInvalidTouchpoints: () => {},
};

export const InboxTableContext = createContext<InboxTableContextData>(INITIAL_CONTEXT_STATE);

interface InboxTableProviderProps {
  children: React.ReactNode;
}

export const InboxTableProvider = ({ children }: InboxTableProviderProps) => {
  const [tableHasInvalidTouchpoints, setTableHasInvalidTouchpoints] = useState<boolean>(false);

  const value = {
    tableHasInvalidTouchpoints,
    setTableHasInvalidTouchpoints,
  };

  return <InboxTableContext.Provider value={value}>{children}</InboxTableContext.Provider>;
};

export const useInboxTableContext = () => {
  const context = React.useContext(InboxTableContext);
  if (context === undefined) {
    throw new Error("useInboxTableContext must be used within an InboxTableProvider");
  }
  return context;
};
