import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import THEME_DEFAULT_DATA from "containers/admin/clients/client/theme-page/preview/mock-data/theme-default-data.json";

import { ReactComponent as InsertImage } from "assets/icons/insert-image.svg";
interface PreviewAreaContentProps {
  clientName: string;
}

const PreviewAreaContent = ({ clientName }: PreviewAreaContentProps) => {
  const { colors, typography, button } = useThemePreviewContext();

  const { primary, secondary, tertiary, intense, bold, soft, subtle, white } = colors!;
  const { heading, subHeading, normalBodyText, smallBodyText, textLink, buttonLabel } = typography!;
  const { paddingX, paddingY, backgroundColor } = button!;

  const defaultTypography = THEME_DEFAULT_DATA.typography;
  const defaultButton = THEME_DEFAULT_DATA.button;

  const colorMap: Record<string, string> = {
    primary,
    secondary,
    tertiary,
    intense,
    bold,
    soft,
    subtle,
    white,
  };

  return (
    <div
      style={{
        fontFamily:
          normalBodyText.fontFamily.value || defaultTypography.normalBodyText.fontFamily.value,
        fontSize: normalBodyText.fontSize.value || defaultTypography.normalBodyText.fontSize.value,
        lineHeight:
          normalBodyText.lineHeight.value || defaultTypography.normalBodyText.lineHeight.value,
        fontWeight:
          normalBodyText.fontWeight.value || defaultTypography.normalBodyText.fontWeight.value,
        color:
          normalBodyText.textColor.value === "custom"
            ? normalBodyText.customColor || defaultTypography.normalBodyText.textColor.value
            : colorMap[normalBodyText.textColor.value],
      }}
      className="flex flex-col gap-5 bg-neutrals-white border-t border-neutral-4 p-5">
      <div className="bg-neutral-4 border-2 border-dashed border-neutral-3 h-[90px] rounded-xl flex items-center justify-center">
        <InsertImage className="text-neutral-3" />
      </div>
      <div
        style={{
          fontFamily: heading.fontFamily.value || defaultTypography.heading.fontFamily.value,
          fontSize: heading.fontSize.value || defaultTypography.heading.fontSize.value,
          lineHeight: heading.lineHeight.value || defaultTypography.heading.lineHeight.value,
          fontWeight: heading.fontWeight.value || defaultTypography.heading.fontWeight.value,
          color:
            heading.textColor.value === "custom"
              ? heading.customColor || defaultTypography.heading.textColor.value
              : colorMap[heading.textColor.value],
        }}>
        <h1>
          Securing Your Teen's Bright Future:
          <br />
          College Planning Tips Inside
        </h1>
        <div
          className="pt-3"
          style={{
            fontFamily:
              subHeading.fontFamily.value || defaultTypography.subHeading.fontFamily.value,
            fontSize: subHeading.fontSize.value || defaultTypography.subHeading.fontSize.value,
            lineHeight:
              subHeading.lineHeight.value || defaultTypography.subHeading.lineHeight.value,
            fontWeight:
              subHeading.fontWeight.value || defaultTypography.subHeading.fontWeight.value,
            color:
              subHeading.textColor.value === "custom"
                ? subHeading.customColor || defaultTypography.subHeading.textColor.value
                : colorMap[subHeading.textColor.value],
          }}>
          <h2>Strategies and Expert Tips for College Sucess</h2>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <p>Dear [Parents Name],</p>
          <span>
            Navigating the path to college success with your teen just got easier! Explore expert
            tips and essential strategies in our guide,&nbsp;
          </span>
          <span
            style={{
              fontFamily: textLink.fontFamily.value || defaultTypography.textLink.fontFamily.value,
              fontSize: textLink.fontSize.value || defaultTypography.textLink.fontSize.value,
              lineHeight: textLink.lineHeight.value || defaultTypography.textLink.lineHeight.value,
              fontWeight: textLink.fontWeight.value || defaultTypography.textLink.fontWeight.value,
              color:
                textLink.textColor.value === "custom"
                  ? textLink.customColor || defaultTypography.textLink.textColor.value
                  : colorMap[textLink.textColor.value],
            }}
            className="underline">
            "Securing Your Teen's Bright Future: College Planning Tips Inside."
          </span>
        </div>
        <div>
          <p className="font-bold">In this comprehensive guide, you'll discover:</p>
          <ul className="list-disc list-outside pl-7">
            <li>Proven methods for effective college planning.</li>
            <li>Insider tips to streamline the application process.</li>
            <li>Guidance on selecting the right-fit college for your teen.</li>
            <li>Strategies to ensure a successful college transition.</li>
          </ul>
        </div>
        <div>
          <p>Your teen's bright future starts here. Don't miss out on these valuable insights.</p>
          <p>Empower your parenting journey with actionable advice. Read the guide now!</p>
        </div>
      </div>
      <button
        type="button"
        className="w-fit font-bold rounded px-4 py-2 cursor-default"
        style={{
          fontFamily:
            buttonLabel.fontFamily.value || defaultTypography.buttonLabel.fontFamily.value,
          fontSize: buttonLabel.fontSize.value || defaultTypography.buttonLabel.fontSize.value,
          lineHeight:
            buttonLabel.lineHeight.value || defaultTypography.buttonLabel.lineHeight.value,
          fontWeight:
            buttonLabel.fontWeight.value || defaultTypography.buttonLabel.fontWeight.value,
          color:
            buttonLabel.textColor.value === "custom"
              ? buttonLabel.customColor || defaultTypography.buttonLabel.textColor.value
              : colorMap[buttonLabel.textColor.value],
          padding: `${paddingY?.value} ${paddingX?.value} ${paddingY?.value} ${paddingX?.value}`,
          background:
            backgroundColor?.name?.value === "custom"
              ? backgroundColor?.custom || defaultButton.backgroundColor.name.value
              : colorMap[backgroundColor?.name.value],
        }}>
        Get the Guide
      </button>
      <div>
        <p>Best Regards,</p>
        <p>{clientName}</p>
        <p className="pt-3">
          P.S. Securing your teen's future is just a click away. Read the guide for a smoother
          college planning experience.
        </p>
      </div>
      <p
        style={{
          fontFamily:
            smallBodyText.fontFamily.value || defaultTypography.smallBodyText.fontFamily.value,
          fontSize: smallBodyText.fontSize.value || defaultTypography.smallBodyText.fontSize.value,
          lineHeight:
            smallBodyText.lineHeight.value || defaultTypography.smallBodyText.lineHeight.value,
          fontWeight:
            smallBodyText.fontWeight.value || defaultTypography.smallBodyText.fontWeight.value,
          color:
            smallBodyText.textColor.value === "custom"
              ? smallBodyText.customColor || defaultTypography.smallBodyText.textColor.value
              : colorMap[smallBodyText.textColor.value],
        }}
        className="text-sm">
        We are contacting you because we received your information from your student or a prior
        contact with {clientName}.
      </p>
    </div>
  );
};

export default PreviewAreaContent;
