import { Flex } from "@chakra-ui/react";

import { H2 } from "components/partials/typography/typography";
import Search from "components/new/custom-search/custom-search";
import InboxAllFilters from "containers/admin/inbox/components/inbox-all-filters/inbox-all-filters";
import { PaginationState } from "components/partials/paginated-table/paginated-table";

interface InboxHeaderProps {
  setCurrentSearchTerm: (searchTerm: string) => void;
  isLoading: boolean;
  paginationState: PaginationState;
}

const InboxHeader = ({ setCurrentSearchTerm, isLoading, paginationState }: InboxHeaderProps) => {
  return (
    <Flex flexDirection="column" width="100%" px={8} pt={5}>
      <Search
        {...{
          setSearch: setCurrentSearchTerm,
          placeholder: "Search by touchpoint name, subject line, or page title",
          customClass: "mb-2",
        }}
      />
      <InboxAllFilters paginationState={paginationState} isLoading={isLoading} />
    </Flex>
  );
};

export default InboxHeader;
