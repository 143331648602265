import {
  PreviewSidebarItemType,
  ButtonTabInputType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";
import ButtonSectionContent from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button-section-content";

export const BUTTON_INPUTS: ButtonTabInputType[] = [
  { isRequired: true, name: "paddingX", label: "Padding horizontal" },
  { isRequired: true, name: "paddingY", label: "Padding vertical" },
  { isRequired: true, name: "backgroundColor", label: "Background color" },
];

export const BUTTON_TAB_SIDEBAR: PreviewSidebarItemType[] = [
  { titleText: "PARAMETERS", children: <ButtonSectionContent content={BUTTON_INPUTS} /> },
];
