import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const emailFormatErrMesage = "This is not a valid email format";

const getEmailTypesDetailsValidation = (name: string) => {
  const message = `${name} is required`;

  return z.string({ required_error: message }).nonempty({ message: message });
};

export const EMAIL_TYPES_DETAILS_VALIDATION_SCHEMA = zodResolver(
  z.object({
    senders: z.array(
      z.object({
        senderName: getEmailTypesDetailsValidation("Sender Name"),
        senderEmail: getEmailTypesDetailsValidation("Sender Email").email(emailFormatErrMesage),
        replyToEmail: getEmailTypesDetailsValidation("Reply-to email").email(emailFormatErrMesage),
      }),
    ),
  }),
);
