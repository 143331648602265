import {
  TypographyTabFormData,
  ButtonTabFormData,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

// filtering isAlertShown out of typography data
export const filterAlertTypography = (typographyData: TypographyTabFormData) => {
  Object.keys(typographyData).forEach((section) => {
    delete typographyData[section].textColor.isAlertShown;
  });

  return typographyData;
};

// filtering isAlertShown out of button data
export const filterAlertButton = (buttonData: ButtonTabFormData) => {
  delete buttonData.backgroundColor.name.isAlertShown;

  return buttonData;
};
