import { Route, Routes } from "react-router-dom";

import EmailBuilder from "containers/admin/clients/touchpoint/components/email-builder/email-builder";
import LPBuilder from "containers/admin/clients/touchpoint/components/lp-builder/lp-builder";
import BuilderProvider from "containers/admin/clients/touchpoint/components/builder/builder-provider/builder-provider";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { Email, LandingPage } from "models";
import TouchpointVersion from "models/touchpoint-version";

export const TouchpointVersionRoutes = () => {
  const currentCampaign = useCurrentCampaign();
  const currentClient = useCurrentClient();
  const currentTouchpoint = useCurrentTouchpoint();

  const findTouchpointVersion = (id: string) => {
    return TouchpointVersion.find({
      id,
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
    }) as Promise<Email | LandingPage>;
  };

  return (
    <BuilderProvider findBuilderValue={findTouchpointVersion}>
      <Routes>
        <Route path="email-builder/*" element={<EmailBuilder previewMode={false} />} />
        <Route path="email-preview/*" element={<EmailBuilder previewMode={true} />} />
        <Route path="landing-page-builder/*" element={<LPBuilder previewMode={false} />} />
        <Route path="landing-page-preview/*" element={<LPBuilder previewMode={true} />} />
      </Routes>
    </BuilderProvider>
  );
};
