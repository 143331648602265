import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ThemeRoutes from "containers/admin/clients/client/theme-page/theme-page-routes";
import CustomSelect from "components/new/custom-select/custom-select";

import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import { mapToggleOptions } from "components/partials/toggle-button-group/helpers/map-toggle-options/map-toggle-options";

import { setCurrentTheme } from "state/ducks/themes";
import {
  ThemePreviewProvider,
  useThemePreviewContext,
} from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import { TabItem, useTab } from "hooks/use-tab";

import { getThemeTabRoute } from "containers/admin/clients/client/theme-page/theme-page.helper";
import { Route as AppRoute } from "utilities/app-routes";

import { THEME_MOCK_DATA, MOCKED_VERSIONS } from "./theme-page-mock-data";

import Icons from "assets";

const ThemePage = () => {
  return (
    <ThemePreviewProvider>
      <ThemePageContent />
    </ThemePreviewProvider>
  );
};

const THEME_PAGE_TABS: TabItem[] = [
  { name: "Preview", path: "preview" },
  { name: "Version History", path: "versions" },
] as const;

const ThemePageContent = () => {
  const { clientId } = useParams();
  const { isEditMode, themeError } = useThemePreviewContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentTab = useTab(THEME_PAGE_TABS, "Preview");

  const changeTab = (tabName: string) => {
    const basePath = `${AppRoute.clients}/${clientId}/${AppRoute.theme}/`;

    const tab = getThemeTabRoute(tabName, basePath);
    navigate(tab, { replace: true });
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentTheme(THEME_MOCK_DATA));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex flex-col bg-neutral-4 px-8 pt-4">
        <div className="flex justify-between items-center mb-2">
          <H2 className="!text-2xl !leading-[30px]">Theme Configuration</H2>
          <div className="w-56">
            <CustomSelect
              ariaLabel="Select a theme version"
              selectName="theme-versions-select"
              size="md"
              defaultValue={MOCKED_VERSIONS[0]}
              options={MOCKED_VERSIONS}
              onChange={() => changeTab("Preview")}
            />
          </div>
        </div>
        <ToggleButtonGroup
          variant="primary"
          groupName="theme-tabs-toggle"
          options={mapToggleOptions({
            tabs: THEME_PAGE_TABS,
            shouldShowIcon: (tab) => tab === "preview" && !isEditMode,
            Icon: <Icons.LockClosed className="size-4" />,
          })}
          onChange={(tab) => changeTab(tab)}
          value={currentTab}
        />
      </div>
      {!themeError ? <ThemeRoutes /> : <span className="p-8">{themeError}</span>}
    </>
  );
};

export default ThemePage;
