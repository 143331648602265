import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Flex,
  AlertTitle,
  AlertDescription,
  CloseButton,
  BoxProps,
} from "@chakra-ui/react";

import { AlertMessage } from "components/partials/flash/flash";
import { H4 } from "components/partials/typography/typography";
import RouterLink from "components/partials/router-link/router-link";
import Button from "components/forms/button/button";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

interface AlertBoxProps extends BoxProps {
  alert: AlertMessage;
  handleClose?: () => void;
  showCloseButton?: boolean;
}

// Similar to Flash component but doesn't use location state
const AlertBox = ({
  alert,
  handleClose = () => {},
  showCloseButton = true,
  ...rest
}: AlertBoxProps) => {
  const navigate = useNavigate();

  const currentUser = useCurrentUser();

  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const handleRedirect = (path: string) => {
    navigate(path);
  };

  const { button, link, message, title, status } = alert;

  const accentColorByStatus = `${status}.100`;

  return (
    <Alert
      status={alert.status}
      variant="left-accent"
      data-testid="flash-key"
      background="white"
      borderRadius="md"
      borderLeftWidth={8}
      borderLeftColor={accentColorByStatus}
      boxShadow="lg"
      alignItems="center"
      justifyContent="space-between"
      py={6}
      {...rest}>
      <Flex direction="column">
        <Flex>
          <AlertIcon mt="-1" color={accentColorByStatus} />
          <AlertTitle as={H4}>{title}</AlertTitle>
        </Flex>
        {message && (
          <AlertDescription mt={2} ml={8}>
            {message}
          </AlertDescription>
        )}
        {link && (
          <AlertDescription mt={2} as={Link} to={link.route}>
            <RouterLink to={link.route} fontWeight="bold" textDecoration="none">
              {link.label}
            </RouterLink>
          </AlertDescription>
        )}
      </Flex>
      {button && !isClientUser && (
        <Button
          variant={button.variant}
          size="sm"
          onClick={() => handleRedirect(button.path)}
          mr={showCloseButton ? 8 : 2}>
          {button.label}
        </Button>
      )}
      {showCloseButton && (
        <CloseButton onClick={handleClose} position="absolute" right={2} top={2} />
      )}
    </Alert>
  );
};

export default AlertBox;
