import { useTheme } from "@chakra-ui/react";

import RouterLink, { RouterLinkProps } from "components/partials/router-link/router-link";

export interface NavLinkProps extends RouterLinkProps {
  activeColor?: string;
  spacingY?: number;
  isStacked?: boolean;
  height?: string | number;
}

const NavLink = ({
  color = "text.link",
  activeColor = "primary",
  spacingY = 3.5,
  borderWidth = 3,
  isStacked = false,
  height,
  ...rest
}: NavLinkProps) => {
  const theme = useTheme();

  const borderStyles = `${borderWidth}px solid transparent`;
  const borderColor = "secondary.100";

  const focusStyles = { textDecoration: "none", opacity: "0.85" };

  const Link = ({ _activeLink, _hover, _focus, ...rest }: NavLinkProps) => (
    <RouterLink
      data-testid="nav-link"
      color={color}
      fontWeight="bold"
      textDecoration="none"
      display="flex"
      alignItems="center"
      height={height}
      _hover={{ _hover, ...focusStyles }}
      _focus={{ _focus, ...focusStyles }}
      _activeLink={{ color: activeColor, ..._activeLink }}
      {...rest}
    />
  );

  const StackedNavLink = () => (
    <Link
      height={height || 10}
      width="100%"
      paddingLeft={5}
      borderLeft={borderStyles}
      _activeLink={{ borderLeftColor: borderColor }}
      _hover={{ borderLeftColor: borderColor }}
      {...rest}
    />
  );

  const InlineNavLink = () => (
    <Link
      width="max-content"
      marginBottom={{ base: 3, sm: 0 }}
      paddingY={theme.sizes[spacingY]}
      borderY={borderStyles}
      _activeLink={{ borderBottomColor: borderColor }}
      _hover={{ borderBottomColor: borderColor }}
      {...rest}
    />
  );

  return isStacked ? <StackedNavLink /> : <InlineNavLink />;
};

export default NavLink;
