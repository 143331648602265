import { EditIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import { H3, P } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import VersionEditModal, {
  FormType,
  onSubmitFormProps,
} from "components/modals/version-edit-modal/version-edit-modal";
import { hasPermission } from "utilities/user";
import { Permission } from "types/auth";
import { useCurrentCampaign, useCurrentTouchpoint, useCurrentUser } from "state/ducks";
import { TouchpointType } from "types/touchpoint";
import { getDisplayTouchpointType } from "utilities";

interface VersionNotFoundProps {
  touchpointType: TouchpointType;
  onCreateDraft: (props: onSubmitFormProps) => void;
}

export const NoVersionFound = ({ touchpointType, onCreateDraft }: VersionNotFoundProps) => {
  const createFirstDraftModal = useDisclosure();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const displayedTouchpointType = getDisplayTouchpointType(touchpointType).toLowerCase();

  const mayCreateDraft = !isClientUser;

  return (
    <div className="flex flex-col gap-2 border-t border-neutral-3 px-10 py-14 items-center">
      <H3>There's nothing here</H3>
      <P>You haven't built any versions of this {displayedTouchpointType} yet.</P>
      {mayCreateDraft && (
        <Button leftIcon={<EditIcon />} onClick={createFirstDraftModal.onOpen} className="mt-6">
          Start new draft
        </Button>
      )}

      <VersionEditModal
        {...createFirstDraftModal}
        parentName={currentCampaign.name}
        name={currentTouchpoint.name}
        formType={FormType.NEW}
        onSubmit={onCreateDraft}
        version="1.0"
        placeholderNotes="Initial draft"
      />
    </div>
  );
};
