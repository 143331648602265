const ColorSwatch = ({ color }: { color?: string }) => {
  return (
    <div
      className="border border-neutral-4 w-5 h-5 rounded-xs"
      style={{ backgroundColor: color }}
    />
  );
};

export default ColorSwatch;
