import {
  Box,
  ButtonGroup,
  ButtonGroupProps,
  useRadio,
  useRadioGroup,
  useTheme,
} from "@chakra-ui/react";

import twTheme from "styles/theme";

/* Usage example:
<ToggleButtonGroup
  groupName="theme-info"
  options={["Details", "Usage", "Version History"]}
  onChange={(option) => setShowContentFor(option)}
  mb={8}
/> */

export interface ToggleButtonsProps extends ButtonGroupProps {
  groupName: string;
  options: string[] | { label: React.ReactNode; value: string }[];
  defaultValue?: string;
  onChange: (e?: any) => void;
  value?: string;
  size?: "sm" | "md";
}

const ToggleButtonGroup = ({
  variant = "secondary",
  value,
  groupName,
  options,
  defaultValue,
  onChange,
  size = "md",
  ...rest
}: ToggleButtonsProps) => {
  const theme = useTheme();

  const normalizedOptions = options.map((opt) =>
    typeof opt === "string" ? { label: opt, value: opt } : opt,
  );

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: groupName,
    defaultValue: defaultValue || normalizedOptions[0]?.value,
    onChange: onChange,
    value: value,
  });
  const group = getRootProps();
  const radius = theme.radii.lg;
  const isPrimaryVariant = variant === "primary";

  const getBorderRadius = ({ isFirst, isLast }: { isFirst: boolean; isLast: boolean }) => {
    switch (true) {
      case isPrimaryVariant:
        return `${radius} ${radius} 0 0`; // rounded top-left and top-right
      case isFirst:
        return `${radius} 0 0 ${radius}`; // rounded top-left and bottom-left
      case isLast:
        return `0 ${radius} ${radius} 0`; // rounded top-right and bottom-right
      default:
        return "0";
    }
  };

  return (
    <ButtonGroup data-testid="toggle-button-group" boxShadow="none" isAttached {...group} {...rest}>
      {normalizedOptions.map(({ label, value }, index) => {
        const radio = getRadioProps({ value });
        const isFirst = index === 0;
        const isLast = index === options.length - 1;

        return (
          <ToggleButton
            key={value}
            isPrimary={isPrimaryVariant}
            borderRadius={getBorderRadius({ isFirst, isLast })}
            size={size}
            {...radio}>
            {label}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;

const ToggleButton = (props: any) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const theme = useTheme();
  const input = getInputProps();
  const checkbox = getRadioProps();
  const isSmall = props.size === "sm";

  return (
    <Box as="label" _notLast={{ borderRight: `1px solid ${theme.colors.gray[200]}` }}>
      <input {...input} />
      <Box
        {...checkbox}
        fontFamily="body"
        fontSize={isSmall ? "14px" : "md"}
        fontWeight="bold"
        lineHeight={isSmall ? "20px" : "tall"}
        cursor="pointer"
        borderRadius={props.borderRadius}
        bg={props.isPrimary ? "transparent" : "white"}
        color="text.link"
        sx={{
          "[role='alert']": {
            color: twTheme.colors.primary.scarlett.dark,
          },
        }}
        _checked={{
          bg: props.isPrimary ? "gray.100" : "primary",
          color: props.isPrimary ? "primary" : "white",
          borderColor: "transparent",
          "[role='alert']": {
            color: "currentColor",
          },
        }}
        px={isSmall ? "12px" : 4}
        py={isSmall ? "6px" : 2}>
        {props.children}
      </Box>
    </Box>
  );
};
