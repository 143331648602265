import TypographySectionContent from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography-section-content";

import {
  PreviewSidebarItemType,
  TypographyTabInputType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

export const TYPOGRAPHY_INPUTS: TypographyTabInputType[] = [
  { isRequired: false, name: "fontFamily", label: "Font family" },
  { isRequired: false, name: "fallbackFontFamily", label: "Fallback font family" },
  { isRequired: false, name: "fontSize", label: "Font size" },
  { isRequired: false, name: "lineHeight", label: "Line height" },
  { isRequired: false, name: "fontWeight", label: "Font weight" },
  { isRequired: false, name: "textColor", label: "Color" },
];

export const TYPOGRAPHY_TAB_SIDEBAR: PreviewSidebarItemType[] = [
  {
    titleText: "HEADING (H1)",
    children: (
      <TypographySectionContent
        sectionName="heading"
        sectionTitle="H1"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },
  {
    titleText: "SUBHEADING (H2)",
    children: (
      <TypographySectionContent
        sectionName="subHeading"
        sectionTitle="H2"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },
  {
    titleText: "NORMAL BODY TEXT",
    children: (
      <TypographySectionContent
        sectionName="normalBodyText"
        sectionTitle="Normal Body"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },
  {
    titleText: "SMALL BODY TEXT",
    children: (
      <TypographySectionContent
        sectionName="smallBodyText"
        sectionTitle="Small Body"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },

  {
    titleText: "TEXT LINK",
    children: (
      <TypographySectionContent
        sectionName="textLink"
        sectionTitle="Text Link"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },

  {
    titleText: "BUTTON LABEL",
    children: (
      <TypographySectionContent
        sectionName="buttonLabel"
        sectionTitle="Button Label"
        content={TYPOGRAPHY_INPUTS}
      />
    ),
  },
];
