interface PreviewAreaHeaderProps {
    clientName: string;
    isMobileView: boolean; 
  }

const PreviewAreaHeader = ({clientName, isMobileView}: PreviewAreaHeaderProps) => {

  return (
    <div className="flex bg-neutral-5 text-sm text-neutrals-2 px-4 py-3">
      {clientName && (
        <span className="inline-flex size-10 aspect-square bg-neutral-1 capitalize font-medium items-center justify-center rounded-full text-white">
          {clientName[0]}
        </span>
      )}
      <div className="flex flex-col gap-1 pl-3"> 
        <div
         className={isMobileView ? "flex flex-col break-words whitespace-normal" : "flex flex-row truncate"}
         style={isMobileView ? { wordBreak: 'break-word', overflowWrap: 'break-word'}: {}}
        >
          <h3 className="font-bold">{clientName}</h3>
          <p className={isMobileView ? "" : "pl-1"}>{`<admission@mail.${clientName.replace(/\s+/g, "")}.org>`}</p>
        </div>
        <p className="font-medium">
          Securing Your Teen's Bright Future: College Planning Tips Inside
        </p>
        <p className="text-xs text-neutral-3">
          Empower your parenting journey
        </p>
      </div>
    </div>
  );
};

export default PreviewAreaHeader;
