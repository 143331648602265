import { FC } from "react";

import { WarningIcon } from "@chakra-ui/icons";
import NavLink from "components/partials/nav-link/nav-link";
import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import LockTabHeader from "containers/admin/clients/touchpoint/components/lock-tab-header/lock-tab-header";

const builderTabs = [
  { id: "design", label: "Design" },
  { id: "content-settings", label: "Content Settings" },
  { id: "preview", label: "Preview" },
] as const;

export type BuilderTabId = (typeof builderTabs)[number]["id"];
export type BuilderTabName = (typeof builderTabs)[number]["label"];

interface BuilderNavTabProps {
  isPreviewMode: boolean;
  url: string;
  invalidTabs: BuilderTabName[];
  setShowConfirmation?: () => void;
  currentTab?: string;
  setNavigateTo?: (value: string) => void;
}

export const BuilderTabNav: FC<BuilderNavTabProps> = ({
  isPreviewMode,
  url,
  invalidTabs,
  setShowConfirmation,
  currentTab,
  setNavigateTo,
  ...rest
}) => {
  const handleNavClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: BuilderTabId,
  ) => {
    if (currentTab === "content-settings" && invalidTabs.includes("Content Settings")) {
      event.preventDefault();

      if (setShowConfirmation) {
        setShowConfirmation();
      }

      if (setNavigateTo) {
        const nextTab = `${url}/${id}`;

        setNavigateTo(nextTab);
      }
    }
  };

  return (
    <SecondaryNav data-testid="builder-tab-nav" py={0} {...rest}>
      {builderTabs.map((tab) =>
        isPreviewMode && tab.id === "design" ? (
          <LockTabHeader key="locked-design" label={tab.label} />
        ) : (
          <NavLink
            key={tab.id}
            to={`${url.replace(/\/[^/]*$/, `/${tab.id}`)}`}
            onClick={(e) => handleNavClick(e, tab.id)}
            mr={{ sm: 8, md: 12 }}>
            {tab.label}
            {invalidTabs.includes(tab.label) && (
              <span className="text-wrong">
                <WarningIcon boxSize={5} className="ml-1 p-0.5" />
              </span>
            )}
          </NavLink>
        ),
      )}
    </SecondaryNav>
  );
};
