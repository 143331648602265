import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import {
  TableEditButton,
  TableMoreButton,
  TableViewButton,
} from "components/table/table-icon-button/table-icon-button";
import VersionEditModal, {
  onSubmitFormProps,
  FormType,
} from "components/modals/version-edit-modal/version-edit-modal";
import ConfirmArchiveModal from "components/modals/confirm-archive-modal/confirm-archive-modal";
import { StatusColumn } from "components/table/table-status-column/table-status-column";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";
import { ClientReviewStatusColumn } from "components/table/table-columns/table-columns";
import { TableCell } from "components/table/table-cell/table-cell";
import { TableRow } from "components/table/table";

import {
  useCurrentClient,
  useCurrentCampaign,
  useCurrentTouchpoint,
  useCurrentUser,
} from "state/ducks";
import { setTouchpointVersions, touchpointVersionsSelector } from "state/ducks/touchpoint";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";
import Touchpoint from "models/touchpoint";

import { getDisplayTouchpointType, getErrorMessage, getTouchpointType } from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { TouchpointStatuses } from "types";
import { TouchpointType } from "types/touchpoint";
import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { CloneTouchpointModal } from "components/modals/clone-touchpoint-modal/clone-touchpoint-modal";

import {
  ActionsMenu,
  ActionsMenuItem,
} from "containers/admin/clients/touchpoint/components/actions-menu/actions-menu";

import { useBuilderAccess } from "containers/admin/clients/touchpoint/components/builder/use-builder-access";

interface TouchpointVersionTableProps {
  touchpointType: TouchpointType;
  creativeName: string;
}

function TouchpointVersionTable({
  touchpointType,
  creativeName,
}: Readonly<TouchpointVersionTableProps>) {
  const { accessBuilder } = useBuilderAccess();
  const currentClient = useCurrentClient();
  const currentUser = useCurrentUser();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const newDraftModal = useDisclosure();
  const editVersionModal = useDisclosure();
  const confirmArchiveModal = useDisclosure();
  const cloneTouchpointModal = useDisclosure();
  const confirmPublishModal = useDisclosure();
  const dispatch = useDispatch();

  // TODO: currentActionItem should be just the touchpoint version ID
  // Then we can pull the touchpoint version from Redux using that ID
  const [currentActionItem, setCurrentActionItem] = useState<TouchpointVersion>();
  const [nextMinorMajorVersions, setNextMinorMajorVersions] = useState<string[]>();
  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(false);
  const refreshTable = () => setShouldRefreshTable(true);

  const [selectedVersion, setSelectedVersion] = useState<TouchpointVersionAttributes>();
  const touchpointVersions = useSelector(touchpointVersionsSelector);

  const loadVersionHistory = (
    options: PaginatedRequestOptions,
  ): Promise<PaginatedResponse<TouchpointVersion>> => {
    return TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
      options: { ...options, sort: "desc" },
    }).then((results) => {
      const resultsAttributes = results.items.map((v) => v.attributes);
      dispatch(setTouchpointVersions({ ...results, items: resultsAttributes }));
      return results as PaginatedResponse<TouchpointVersion>;
    });
  };

  const isEmail = touchpointType === TouchpointType.EMAIL;

  const openTouchpointPreview = (id: string) => {
    const version = touchpointVersions.items.find((v) => v.id === id);
    if (version) {
      accessBuilder(version, "preview");
    }
  };

  const openTouchpointEdit = (touchpoint: TouchpointVersion) => {
    accessBuilder(touchpoint.attributes);
  };

  const handleArchiveVersion = (touchpoint?: TouchpointVersion) => {
    if (touchpoint) {
      const touchpointType = getTouchpointType(touchpoint);
      const formattedTouchpointType = getDisplayTouchpointType(touchpointType);

      TouchpointVersion.patchTouchpointStatus({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: touchpoint.id,
        status: TouchpointStatuses.ARCHIVED_DRAFT,
      })
        .then(() => {
          refreshTable();
          toast.success({
            data: {
              title: `${formattedTouchpointType} version ${touchpoint.version} archived.`,
            },
          });
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to archive Touchpoint",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    confirmArchiveModal.onClose();
  };

  const handleCreateDraftFromVersion = () => {
    Touchpoint.nextVersions({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      id: currentTouchpoint.id,
    })
      .then((response) => {
        let { nextMinorVersion, nextMajorVersion } = response.data;
        setNextMinorMajorVersions([nextMinorVersion, nextMajorVersion]);
      })
      .then(() => newDraftModal.onOpen())
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create draft",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const handleSubmitEditVersion = ({
    id,
    version,
    versionNotes,
    visibleToClient,
  }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.edit({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        attributes: {
          version: version,
          versionNotes: versionNotes,
          visibleToClient: visibleToClient,
        },
      })
        .then(() => {
          toast.success({
            data: {
              title: "Version details updated",
            },
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to edit version details",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    editVersionModal.onClose();
  };

  const handleSubmitNewDraft = ({
    id,
    version,
    versionNotes,
    visibleToClient,
  }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.createDraft({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        version: version,
        versionNotes: versionNotes,
        visibleToClient: visibleToClient,
      })
        .then(() => {
          toast.success({
            data: {
              title: "New draft created",
            },
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to create draft",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    newDraftModal.onClose();
  };

  const handlePublishVersion = (id?: string) => {
    if (id) {
      TouchpointVersion.patchTouchpointStatus({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: id,
        status: TouchpointStatuses.PUBLISHED,
      })
        .then(() => {
          toast.success({
            data: {
              title: "Published Touchpoint",
            },
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to publish Touchpoint",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    confirmPublishModal.onClose();
  };

  const renderRow = (version: TouchpointVersion) => {
    const isDraft = version.status === TouchpointStatuses.DRAFT;
    const isSystemDraft = version.status === TouchpointStatuses.SYSTEM_DRAFT;
    const isEditable = isDraft || isSystemDraft;
    const versionIsPublished = version.status === TouchpointStatuses.PUBLISHED;

    return (
      <TableRow>
        {/* Version */}
        <StatusColumn
          id={version.id}
          status={version.status}
          version={version.version}
          lastModifiedDate={
            version.status === TouchpointStatuses.PUBLISHED
              ? (version.publishedAt ?? version.lastModifiedDate)
              : version.lastModifiedDate
          }
        />

        {/* Description */}
        <TableCell data-testid={`${version.id}-table-version-notes`}>
          {version.versionNotes}
        </TableCell>

        {/* Status */}
        <ClientReviewStatusColumn status={version.status as TouchpointStatuses} />

        {/* Actions */}
        <TableCell>
          <div className="flex justify-end align-middle">
            {isEditable && (
              <TableEditButton
                title={`Edit ${getDisplayTouchpointType(touchpointType)} Version`}
                aria-label={`Edit ${getDisplayTouchpointType(touchpointType)} Version`}
                onClick={() => openTouchpointEdit(version)}
              />
            )}
            <TableViewButton
              title={`Preview ${getDisplayTouchpointType(touchpointType)} Version`}
              aria-label={`Preview ${getDisplayTouchpointType(touchpointType)} Version`}
              onClick={() => openTouchpointPreview(version.id)}
            />
            <ActionsMenu trigger={<TableMoreButton aria-label="More actions" />}>
              {versionIsPublished ? (
                <>
                  <ActionsMenuItem.CreateDraftFromVersion
                    onClick={() => {
                      setCurrentActionItem(version);
                      handleCreateDraftFromVersion();
                    }}
                  />

                  <ActionsMenuItem.CloneTouchpoint
                    onClick={() => {
                      setSelectedVersion(version);
                      cloneTouchpointModal.onOpen();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionsMenuItem.PreviewTouchpoint
                    onClick={() => openTouchpointPreview(version.id)}
                    isEmail={isEmail}
                  />

                  {isEditable && (
                    <>
                      <ActionsMenuItem.EditVersionDetails
                        onClick={() => {
                          setCurrentActionItem(version);
                          editVersionModal.onOpen();
                        }}
                      />
                      <ActionsMenuItem.EditTouchpoint
                        onClick={() => openTouchpointEdit(version)}
                        isEmail={isEmail}
                      />
                    </>
                  )}

                  <ActionsMenuItem.CreateDraftFromVersion
                    onClick={() => {
                      setCurrentActionItem(version);
                      handleCreateDraftFromVersion();
                    }}
                  />

                  <ActionsMenuItem.CloneTouchpoint
                    onClick={() => {
                      setSelectedVersion(version);
                      cloneTouchpointModal.onOpen();
                    }}
                  />

                  {hasPermission(currentUser, Permission.PERM_TOUCHPOINT_PUBLISH) && (
                    <ActionsMenuItem.Publish
                      onClick={() => {
                        setCurrentActionItem(version);
                        confirmPublishModal.onOpen();
                      }}
                      isEmail={isEmail}
                    />
                  )}

                  {isEditable && (
                    <ActionsMenuItem.ArchiveDraft
                      onClick={() => {
                        setCurrentActionItem(version);
                        confirmArchiveModal.onOpen();
                      }}
                    />
                  )}
                </>
              )}
            </ActionsMenu>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <PaginatedTable
        headers={["Version", "Description", "Status", "Actions"]}
        fetchPage={loadVersionHistory}
        shouldRefresh={shouldRefreshTable}
        setShouldRefresh={setShouldRefreshTable}
        renderRow={renderRow}
      />

      <VersionEditModal
        {...editVersionModal}
        id={currentActionItem?.id}
        placeholderNotes={currentActionItem?.versionNotes}
        parentName={currentCampaign.name}
        formType={FormType.EDIT}
        version={currentActionItem?.version || "1.0"}
        name={creativeName}
        onSubmit={handleSubmitEditVersion}
        helperText="Edit the version description"
        saveText="Save"
        visibleToClient={currentActionItem?.visibleToClient}
        nextMinorMajorVersions={nextMinorMajorVersions}
      />

      <VersionEditModal
        {...newDraftModal}
        id={currentActionItem?.id}
        parentName={currentCampaign.name}
        formType={FormType.NEW_VERSION}
        version={currentActionItem?.version || "1.0"}
        name={creativeName}
        onSubmit={handleSubmitNewDraft}
        nextMinorMajorVersions={nextMinorMajorVersions}
      />

      <ConfirmArchiveModal
        {...confirmArchiveModal}
        currentTouchpoint={currentActionItem}
        onSubmit={() => handleArchiveVersion(currentActionItem)}
      />

      <CloneTouchpointModal {...cloneTouchpointModal} selectedVersion={selectedVersion} />

      <ConfirmationModal
        {...confirmPublishModal}
        onConfirm={() => {
          handlePublishVersion(currentActionItem?.id);
        }}
        headline={
          touchpointType === "EMAIL"
            ? "Are you sure you want to publish this email to iterable"
            : "Are you sure you want to publish this landing page"
        }
        message={"This will replace the current published version. This action cannot be undone"}
        confirmButtonText={touchpointType === "EMAIL" ? "Yes, publish to iterable" : "Yes, publish"}
      />
    </>
  );
}

export default TouchpointVersionTable;
