import { useMemo } from "react";

import { useFormContext } from "react-hook-form";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";
import ColorSwatch from "containers/admin/clients/client/theme-page/preview/components/color-swatch/color-swatch";

import { ButtonOptions } from "containers/admin/clients/client/theme-page/preview/preview.types";

import Icons from "assets";

export const useButtonOptions = (): ButtonOptions => {
  const { colors } = useThemePreviewContext();

  const { primary, secondary, tertiary, intense, bold, soft, subtle, white } = colors!;

  //form
  const {
    formState: { errors },
    getValues,
  } = useFormContext();

  const getIsAlertIconShown = ({ color, colorName }: { color: string; colorName: string }) =>
    !(color !== "" && color !== "#" && !(errors?.colors && colorName in errors.colors));

  return useMemo(
    () => ({
      paddingX: [
        { label: "4px", value: "4px" },
        { label: "6px", value: "6px" },
        { label: "8px", value: "8px" },
        { label: "10px", value: "10px" },
        { label: "12px", value: "12px" },
        { label: "14px", value: "14px" },
        { label: "16px", value: "16px" },
        { label: "20px", value: "20px" },
      ],
      paddingY: [
        { label: "4px", value: "4px" },
        { label: "6px", value: "6px" },
        { label: "8px", value: "8px" },
        { label: "10px", value: "10px" },
        { label: "12px", value: "12px" },
        { label: "14px", value: "14px" },
        { label: "16px", value: "16px" },
        { label: "20px", value: "20px" },
      ],
      backgroundColor: [
        ...(getValues("colors.primary")
          ? [
              {
                label: "Brand/Primary",
                value: "primary",
                icon: <ColorSwatch color={primary} />,
                isAlertShown: getIsAlertIconShown({ color: primary, colorName: "primary" }),
              },
            ]
          : []),
        ...(getValues("colors.secondary")
          ? [
              {
                label: "Brand/Secondary",
                value: "secondary",
                icon: <ColorSwatch color={secondary} />,
                isAlertShown: getIsAlertIconShown({ color: secondary, colorName: "secondary" }),
              },
            ]
          : []),
        ...(getValues("colors.tertiary")
          ? [
              {
                label: "Brand/Tertiary",
                value: "tertiary",
                icon: <ColorSwatch color={tertiary} />,
                isAlertShown: getIsAlertIconShown({ color: tertiary, colorName: "tertiary" }),
              },
            ]
          : []),
        {
          label: "Neutrals/Intense",
          value: "intense",
          icon: <ColorSwatch color={intense} />,
          isAlertShown: getIsAlertIconShown({ color: intense, colorName: "intense" }),
        },
        {
          label: "Neutrals/Bold",
          value: "bold",
          icon: <ColorSwatch color={bold} />,
          isAlertShown: getIsAlertIconShown({ color: bold, colorName: "bold" }),
        },
        {
          label: "Neutrals/Soft",
          value: "soft",
          icon: <ColorSwatch color={soft} />,
          isAlertShown: getIsAlertIconShown({ color: soft, colorName: "soft" }),
        },
        {
          label: "Neutrals/Subtle",
          value: "subtle",
          icon: <ColorSwatch color={subtle} />,
          isAlertShown: getIsAlertIconShown({ color: subtle, colorName: "subtle" }),
        },
        {
          label: "Neutrals/White",
          value: "white",
          icon: <ColorSwatch color={white} />,
          isAlertShown: getIsAlertIconShown({ color: white, colorName: "white" }),
        },
        {
          label: "None",
          value: "none",
          icon: <Icons.ChooseColor className="w-5 h-5 text-neutral-4 fill-white" />,
        },
        {
          label: "Custom...",
          value: "custom",
          icon: <Icons.ChooseColor className="w-5 h-5 text-neutral-4 fill-white" />,
        },
      ],
    }),
    // eslint-disable-next-line
    [primary, secondary, tertiary, intense, bold, soft, subtle, white, errors],
  );
};
