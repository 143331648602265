import { Route as AppRoute } from "utilities/app-routes";

export const getThemeTabRoute = (tabName: string, url: string) => {
  switch (tabName) {
    case "Version History":
      return url + AppRoute.versions;
    default:
      return url + AppRoute.preview;
  }
};
