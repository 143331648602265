import ContentTypeMismatchIcon from "components/partials/icon/content-type-mismatch-icon";

export const getTabViews = (hasWarning: boolean) => {
  return [
    { label: "Preview", value: "Preview" },
    {
      label: (
        <div className=" flex items-center gap-1">
          Details
          {hasWarning && <ContentTypeMismatchIcon customClass="size-5" />}
        </div>
      ),
      value: "Details",
    },
    { label: "Version history", value: "Version history" },
  ];
};
