import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";

import BasicInfo from "containers/admin/clients/client/components/basic-info/basic-info";
import EmailTypes from "containers/admin/clients/client/components/email-types/email-types";
import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks";
import { setCurrentClient } from "state/ducks/clients";
import { WithMaybePersisted } from "models/model";
import Client, { ClientAttributes } from "models/client";

import { getErrorMessage } from "utilities";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import PageContent from "components/partials/page-content/page-content";

export const ProfileEditPage = () => {
  const client = useCurrentClient();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const clientProfileRoute = `/clients/${client.id}/profile`;

  usePagePermission(Permission.PERM_CLIENT_WRITE);

  const updateClient = (attributes: WithMaybePersisted<ClientAttributes, "primaryContact">) => {
    Client.replace(attributes)
      .then(async (_response) => {
        const clientAfterUpdating = await Client.find(attributes.id);
        dispatch(setCurrentClient(clientAfterUpdating.attributes));
        toast.success({
          data: {
            title: "Client updated",
          },
        });
        navigate(clientProfileRoute);
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to save client",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const cancelEditClient = () => {
    navigate(clientProfileRoute);
  };

  const changeTab = (option: string) => {
    const basePath = `${clientProfileRoute}/edit`;
    navigate(option === "Basic information" ? `${basePath}/basic` : `${basePath}/email-types`, {
      replace: true,
    });
  };

  const currentPage = () => {
    return location.pathname.endsWith("basic") ? "Basic information" : "Email types";
  };

  return (
    <PageContent>
      <H2 className="mb-8 pt-5">Update Client Profile</H2>
      <Box>
        <ToggleButtonGroup
          groupName="edit-type-toggle"
          options={["Basic information", "Email types"]}
          value={currentPage()}
          onChange={changeTab}
          size="sm"
          mb={8}
          sx={{
            boxShadow: "xs",
            borderRadius: "lg",
            overflow: "hidden",
          }}
        />
      </Box>
      <Routes>
        <Route path="/" element={<Navigate to="basic" replace />} />
        <Route
          path="basic"
          element={
            <BasicInfo client={client} onSubmit={updateClient} onCancel={cancelEditClient} />
          }
        />
        <Route path="email-types" element={<EmailTypes client={client} />} />
      </Routes>
    </PageContent>
  );
};
