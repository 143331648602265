import { useEffect, useState } from "react";
import { RiLayout5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { MdContentCopy, MdEmail } from "react-icons/md";

import Button from "components/forms/button/button";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import { ParamsType } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";

import { useGetTouchpointVersionStatusesQuery } from "state/api/dictionary";
import {
  useCurrentCampaign,
  useCurrentClient,
  useCurrentTouchpoint,
  useCurrentUser,
} from "state/ducks";
import { touchpointVersionsSelector } from "state/ducks/touchpoint";

import User from "models/user";
import Client from "models/client";
import Campaign from "models/campaign";
import { replaceEndingToUrl } from "utilities";
import { hasPermission } from "utilities/user";
import { getTabViews } from "containers/admin/clients/touchpoint/components/touchpoint-page-header/touchpoint-page-header.helper";

import { TabItem, useTab } from "hooks/use-tab";

import { Permission } from "types/auth";
import { TouchpointType } from "types/touchpoint";
import { TouchpointStatuses } from "types";
import { getCampaignPaths, getClientPaths } from "utilities/app-routes";

interface BreadcrumbsType {
  client: Client;
  campaign: Campaign;
  params?: ParamsType;
  currentUser: User;
}

const getBreadcrumbsForCurrentPage = ({
  client,
  campaign,
  params,
  currentUser,
}: BreadcrumbsType) => {
  const { clientCampaignsPath } = getClientPaths({ clientId: params?.clientId });
  const { campaignCreativePath } = getCampaignPaths({
    clientId: params?.clientId,
    campaignId: params?.campaignId,
  });

  return !hasPermission(currentUser, Permission.PERM_CLIENT_USER)
    ? [
        { label: client?.name, href: clientCampaignsPath },
        {
          label: campaign?.name,
          href: campaignCreativePath,
        },
      ]
    : [
        { label: "Reviews", href: "/reviews" },
        { label: client?.name, isActive: true },
      ];
};

const TAB_VIEWS: readonly TabItem[] = [
  { name: "Preview", path: "preview" },
  { name: "Details", path: "details" },
  { name: "Version history", path: "versions" },
] as const;

export const TouchpointPageHeader = () => {
  const currentTouchpoint = useCurrentTouchpoint();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const versions = useSelector(touchpointVersionsSelector);
  const [draftCount, setDraftCount] = useState<number>(0);
  const params = useParams();
  const { data: touchpointVersionStatuses = [] } = useGetTouchpointVersionStatusesQuery();
  const options = getTabViews(!currentTouchpoint.isValidContentType);

  const currentTab = useTab(TAB_VIEWS, "preview");

  const handleTabChange = (tabName: string) => {
    const tab = TAB_VIEWS.find((t) => t.name === tabName);
    if (tab) {
      navigate(`../${tab.path}`, { replace: true });
    }
  };
  useEffect(() => {
    const draftStatusNames = ["Draft", "System draft"];

    if (versions.items) {
      const draftStatuses = new Set(
        touchpointVersionStatuses
          .filter(({ versionStatusName }) => draftStatusNames.includes(versionStatusName))
          .map(({ status }) => status),
      );

      const numDrafts = versions.items.reduce((draftCount, item) => {
        if (item.status && draftStatuses.has(item.status)) return draftCount + 1;
        return draftCount;
      }, 0);

      setDraftCount(numDrafts);
    }
  }, [touchpointVersionStatuses, versions.items]);

  return (
    <Box className="bg-gray-200" pt={1} mx={-8} px={8} mb={5}>
      <Breadcrumbs
        customClass="mt-0 !mb-1.5"
        breadcrumbs={getBreadcrumbsForCurrentPage({
          client: currentClient,
          campaign: currentCampaign,
          params,
          currentUser,
        })}
      />
      <Flex paddingBottom="2" justifyContent="space-between" alignItems="center">
        <Flex alignItems={"center"}>
          <Icon
            as={currentTouchpoint.type === TouchpointType.EMAIL ? MdEmail : RiLayout5Fill}
            fontSize="xl"
            color="gray.2"
            mr={2}
            data-testid={currentTouchpoint.type + "-type"}
          />
          <span className="text-navy font-bold text-xl" data-testid="touchpoint-name">
            {currentTouchpoint.name}
          </span>
        </Flex>

        <CopyURLButton />
      </Flex>
      <Flex justify="between" paddingBottom="2" fontSize="sm">
        {versions.items.find((v) => v.status === TouchpointStatuses.PUBLISHED)?.version ? (
          <span>{`v${
            versions.items.find((v) => v.status === TouchpointStatuses.PUBLISHED)?.version
          }`}</span>
        ) : (
          <span>No published version</span>
        )}
        {/* Drafts exist and user is not a CLIENT_USER */}
        {draftCount > 0 && !hasPermission(currentUser, Permission.PERM_CLIENT_USER) && (
          <>
            <span className="mx-3">|</span>
            <span>{draftCount} drafts</span>
          </>
        )}
      </Flex>
      {/* Hide toggle from CLIENT_USER */}
      {!hasPermission(currentUser, Permission.PERM_CLIENT_USER) && (
        <Box mt="3">
          <ToggleButtonGroup
            variant="primary"
            groupName="touchpoint-tab-toggle"
            options={options}
            onChange={handleTabChange}
            value={currentTab}
            size="sm"
            mb={0}
            sx={{
              "& label": {
                _notLast: {
                  borderRight: "1px solid transparent !important",
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const CopyURLButton = () => {
  const copyUrlToClipboard = () => {
    const currentTouchpointUrl = window.location.href;
    const urlToCopy = replaceEndingToUrl(currentTouchpointUrl, "preview");
    navigator.clipboard.writeText(urlToCopy);
  };

  return (
    <Button
      size="sm"
      leftIcon={<Icon as={MdContentCopy} fontSize="lg" mb={1} mr={-1} />}
      variant="link"
      onClick={copyUrlToClipboard}
      padding="0">
      Copy record URL
    </Button>
  );
};
