import { type TouchpointStatusDictionaryItem } from "state/api/dictionary";
import User from "models/user";

import { hasPermission } from "utilities/user";

import { TouchpointStatuses } from "types";
import { Permission } from "types/auth";

//admin functionality
export const canApproveSendFeedbackOnTouchpoint = (user: User, statusName: string) => {
  return (
    hasPermission(user, Permission.PERM_CLIENT_APPROVAL) &&
    statusName === TouchpointStatuses.CLIENT_REVIEW
  );
};

export const getUserCanPublish = (user: User) => {
  return hasPermission(user, Permission.PERM_TOUCHPOINT_PUBLISH);
};

export const getIsButtonGroupShown = ({
  status,
  versionStatusName,
}: TouchpointStatusDictionaryItem) => {
  return (
    status &&
    status !== TouchpointStatuses.CLIENT_REVIEW &&
    (versionStatusName === "Draft" ||
      versionStatusName === "Rejected draft" ||
      versionStatusName === "System draft")
  );
};

export const getIsApproved = (clientReviewStatusName: string) => {
  return clientReviewStatusName === TouchpointStatuses.APPROVED;
};

export const getSuccessMessage = (status: string) => {
  switch (status) {
    case TouchpointStatuses.CLIENT_REVIEW:
      return "Touchpoint version sent to client review";
    case TouchpointStatuses.PUBLISHED:
      return "Touchpoint version successfully published";
    case TouchpointStatuses.APPROVED:
      return "Approval successfully sent";
    case TouchpointStatuses.REJECTED:
      return "Feedback successfully sent";
    default:
      return "Action successfully applied";
  }
};

export const getFailMessage = (status: string) => {
  switch (status) {
    case TouchpointStatuses.CLIENT_REVIEW:
      return "Failed to send touchpoint to client review";
    case TouchpointStatuses.PUBLISHED:
      return "Failed to publish touchpoint version";
    case TouchpointStatuses.APPROVED:
      return "Failed to approve, please contact your Account Manager";
    case TouchpointStatuses.REJECTED:
      return "Failed to send feedback, please contact your Account Manager";
    default:
      return "Something went wrong";
  }
};

function removeFromString(inputString: string, ...valuesToRemove: string[]): string {
  // Escape special regex characters in the values to remove
  const escapedValues = valuesToRemove.map((value) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));

  // Join the values into a single regex pattern
  const regexPattern = `(${escapedValues.join("|")})`;

  // Create regex with global flag to replace all occurrences
  const regex = new RegExp(regexPattern, "g");

  // Remove the occurrences of the values
  return inputString.replace(regex, "");
}

export function removeUserIdFromString(inputString: string): string {
  return removeFromString(inputString, "&amp;uid={{userId}}", "&uid={{userId}}");
}
