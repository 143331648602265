import { useEffect, useState } from "react";
import clsx from "clsx";

import { IAccordionProps, IAccordionPropsVariants } from "components/new/accordion/accordion.types";

import Icons from "assets";

const coreStyles: IAccordionPropsVariants = {
  default: {
    disclosureButtonStyles: "w-full items-center justify-between",
    titleStyles: "font-semibold tracking-widest text-neutral-3",
  },
  primary: {
    disclosureButtonStyles: "",
    titleStyles: "font-semibold text-default",
  },
};

const CustomAccordion = ({
  variant = "default",
  title,
  isDisabled = false,
  isOpen = false,
  children,
}: IAccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

  const { disclosureButtonStyles, titleStyles } = coreStyles[variant];

  useEffect(() => {
    setIsAccordionOpen(isOpen);
  }, [isOpen]);

  return (
    <div className="w-full divide-y divide-white/5 rounded-xl bg-white/5">
      <button
        type="button"
        className={clsx("group flex items-center", disclosureButtonStyles, {
          "opacity-50": isDisabled,
        })}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        aria-expanded={isAccordionOpen}
        disabled={isDisabled}>
        <span className={titleStyles}>{title}</span>
        <Icons.ArrowRight
          className={clsx("size-5 text-aqua-dark transition-transform duration-200", {
            "rotate-90": isAccordionOpen,
            "opacity-50": isDisabled,
          })}
        />
      </button>

      {isAccordionOpen && <div className="mt-2">{children}</div>}
    </div>
  );
};

export default CustomAccordion;
