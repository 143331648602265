const EmailTypesSendersTable = ({ senderName, senderEmail, replyToEmail, email }: any) => {
  const trStyles = "border-b border-neutral-4";
  const cellNameStyles = "w-1/4 border-r border-neutral-4 p-2 text-left";
  const tdValueStyles = "p-2";

  return (
    <div className="flex flex-col gap-3">
      {email.senders.map((sender: any, ind: number) => (
        <div key={sender.id + ind}>
          <div className="flex items-center">
            <span className="font-semibold text-navy">Sender {ind + 1}</span>
            {sender.defaultForEmailType && (
              <div className="py-1 px-2 rounded-md text-white bg-aqua-500 ml-2">Default</div>
            )}
          </div>
          <div
            data-testid="email-types-details-table"
            className="rounded-lg border border-neutral-4 mt-2">
            <table className="w-full">
              <tbody>
                <tr className={trStyles}>
                  <th scope="row" className={cellNameStyles}>
                    Sender name
                  </th>
                  <td className={tdValueStyles}>{sender.senderName}</td>
                </tr>
                <tr className={trStyles}>
                  <th scope="row" className={cellNameStyles}>
                    Sender email
                  </th>
                  <td className={tdValueStyles}>{sender.senderEmail}</td>
                </tr>
                <tr>
                  <th scope="row" className={cellNameStyles}>
                    Reply-to email
                  </th>
                  <td className={tdValueStyles}>{sender.replyToEmail}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmailTypesSendersTable;
