import { Box } from "@chakra-ui/react";

import { TouchpointStatuses } from "types";

interface StatusIndicatorProps {
  status?: string | null;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const getBackgroundColor = () => {
    switch (status) {
      case TouchpointStatuses.CREATED:
      case TouchpointStatuses.DRAFT:
      case TouchpointStatuses.CLIENT_REVIEW:
        return "warning.100";
      case TouchpointStatuses.PUBLISHED:
      case TouchpointStatuses.APPROVED:
        return "success.100";
      default:
        return "gray.300";
    }
  };

  return <Box borderRadius="full" bgColor={getBackgroundColor()} width={4} height={4} mr={1} />;
};

export default StatusIndicator;
