import React from "react";
import { Controller, useFormContext, FieldError, Merge, FieldErrorsImpl } from "react-hook-form";

import CustomSelect from "components/new/custom-select/custom-select";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";
import { useButtonOptions } from "containers/admin/clients/client/theme-page/preview/hooks/use-button-option";
import HexInput from "containers/admin/clients/client/theme-page/preview/components/hex-input/hex-input";
import ColorSwatch from "containers/admin/clients/client/theme-page/preview/components/color-swatch/color-swatch";

import {
  ButtonKeys,
  ButtonTabInputType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";
import {
  ButtonTabFormData,
  ThemeOption,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

import Icons from "assets";

const ButtonSectionContent = ({ content }: { content: ButtonTabInputType[] }) => {
  // getting context
  const { isEditMode, setButton, colors, typography, button } = useThemePreviewContext();

  //form
  const {
    control,
    formState: { errors },
    resetField,
    watch,
    clearErrors,
  } = useFormContext();

  // get button input options
  const BUTTON_OPTIONS = useButtonOptions();

  // errors
  const buttonErrors = errors?.button as Record<
    string,
    FieldError | Merge<FieldError, FieldErrorsImpl<ButtonTabFormData>>
  >;

  const buttonErrorsSection = buttonErrors?.backgroundColor as Record<
    string,
    FieldError | Merge<FieldError, FieldErrorsImpl<ButtonTabFormData>>
  >;

  const isThemeSet = typography && colors && button;

  const getSelectValue = (name: keyof ButtonTabFormData, field: any) => {
    return (
      isThemeSet && {
        value: field?.value,
        label: (
          <div className="flex gap-1 relative w-full">
            {name === "backgroundColor" && <ColorSwatch color={colors[field?.value]!} />}
            {field?.label}
            {name === "backgroundColor" && button?.backgroundColor?.name?.isAlertShown && (
              <Icons.ExclamationCircle className="overflow-visible absolute text-wrong-text -bottom-0.5 size-6 left-[180px]" />
            )}
          </div>
        ),
      }
    );
  };

  const getPathName = (name: string) => {
    switch (name) {
      case "paddingY":
      case "paddingX":
        return `button.${name}`;
      default:
        return `button.${name}.name`;
    }
  };

  // handle change for custom string values
  const handleCustomChange = (
    value: string,
    field: ButtonKeys,
    fieldOnChange: (value: string) => void,
  ) => {
    const hasError = buttonErrorsSection?.custom ? true : false;

    if (value !== "#" && !hasError) {
      setButton((prev: ButtonTabFormData | null) => {
        if (!prev) return prev;
        return {
          ...prev,
          [field]: {
            ...prev[field],
            custom: value,
          },
        };
      });
    }

    fieldOnChange(value);
  };

  const handleChange = (
    field: ButtonKeys,
    input: ThemeOption,
    fieldOnChange: (value: ThemeOption) => void,
  ) => {
    // getting which values to save
    const { label, value, isAlertShown } = input;
    const inputValue =
      field === "backgroundColor" && isAlertShown
        ? { label, value, isAlertShown }
        : { label, value };

    setButton((prev: ButtonTabFormData | null) => {
      if (!prev) return prev;

      // set context based on structure of input
      if (field === "paddingX" || field === "paddingY") {
        return {
          ...prev,
          [field]: inputValue,
        };
      } else {
        return {
          ...prev,
          [field]: {
            ...prev[field],
            name: inputValue,
          },
        };
      }
    });

    // reset custom bg color once you click away
    if (field === "backgroundColor" && value !== "custom") {
      resetField(`button.${field}.custom`, { defaultValue: "" });
      setButton((prev: ButtonTabFormData | null) => {
        if (!prev) return prev;
        return {
          ...prev,
          [field]: {
            ...prev[field],
            custom: "",
          },
        };
      });
    }

    fieldOnChange({ label, value });
  };

  return (
    isThemeSet && (
      <div className="flex flex-col gap-5">
        {content.map(({ name, label, isRequired }: ButtonTabInputType, ind: number) => (
          <React.Fragment key={ind}>
            <Controller
              key={ind}
              name={getPathName(name)}
              control={control}
              render={({ field }) => (
                <div>
                  <label className="font-semibold text-sm">{label}</label>
                  <div className="mt-1.5">
                    <CustomSelect
                      ariaLabel={label}
                      selectName={`button-select-${name}`}
                      size="md"
                      options={BUTTON_OPTIONS[name]}
                      isRequired={isRequired}
                      isDisabled={!isEditMode}
                      {...field}
                      value={getSelectValue(name, field.value)}
                      onChange={(el) => {
                        handleChange(name, el as ThemeOption, field.onChange);
                      }}
                    />
                  </div>
                </div>
              )}
            />
            {name === "backgroundColor" &&
              watch(`button.backgroundColor`)?.name?.value === "custom" && (
                <Controller
                  name={`button.backgroundColor.custom`}
                  control={control}
                  render={({ field }) => (
                    <div className="mt-1.5">
                      <HexInput
                        label="Button background custom color"
                        isRequired={true}
                        isDisabled={!isEditMode}
                        handleCustomChange={(value, isColorPickerInvoked) => {
                          if (isColorPickerInvoked) clearErrors(`button.backgroundColor.custom`);
                          handleCustomChange(value, "backgroundColor", field.onChange);
                        }}
                        errMessage={(buttonErrorsSection?.custom as FieldError)?.message}
                        inputNameKey="custom"
                        {...field}
                      />
                    </div>
                  )}
                />
              )}
          </React.Fragment>
        ))}
      </div>
    )
  );
};

export default ButtonSectionContent;
