import { useEffect, useState } from "react";

import { CustomFormField } from "utilities/forms";

import { getSenderOnEmailTypeChange } from "containers/admin/clients/touchpoint/components/email-builder/email-builder-content-settings-tab/email-builder-content-settings-tab.helper";

import { IEmailType } from "containers/admin/clients/client/components/email-types/email-types.types";
import Email from "models/email";
import { ISenderDetails } from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.types";

interface EmailSettingsFieldsType {
  emailTypeOptions: IEmailType[];
  currentTouchpointVersion: Email;
  setCurrentSender: React.Dispatch<React.SetStateAction<ISenderDetails>>;
}

const useEmailSettingsFields = ({
  emailTypeOptions,
  currentTouchpointVersion,
  setCurrentSender,
}: EmailSettingsFieldsType) => {
  const [contentSettingsFields, setContentSettingsFields] = useState<CustomFormField[]>([]);

  const getChosenEmailType = (cb: (option: IEmailType) => boolean) => {
    return emailTypeOptions.find(cb)!;
  };

  const chosenEmailType = currentTouchpointVersion.iterableEmailTypeId
    ? getChosenEmailType(
        (option: IEmailType) => option.id === currentTouchpointVersion.iterableEmailTypeId,
      )
    : getChosenEmailType((option: IEmailType) => option.defaultForClient);

  const sender = getSenderOnEmailTypeChange(chosenEmailType, currentTouchpointVersion);

  useEffect(() => {
    setCurrentSender(sender);

    setContentSettingsFields([
      {
        name: "iterableEmailTypeId",
        component: "select",
        label: "Email Type",
        defaultValue: chosenEmailType?.id,
        placeholder: !emailTypeOptions.length ? "There are no Email Types" : undefined,
        options: emailTypeOptions.map(({ externalName, id }) => ({
          name: externalName,
          value: id,
        })),
      },
      {
        name: "senderName",
        component: "select",
        label: "Sender name",
        defaultValue: sender?.id,
        options: [],
      },
      {
        name: "senderEmail",
        component: "input",
        label: "Sender email",
        defaultValue: sender?.senderEmail,
        disabled: true,
      },
      {
        name: "replyToEmail",
        component: "input",
        label: "Reply-to email",
        defaultValue: sender?.replyToEmail,
        disabled: true,
      },
      {
        name: "subjectLine",
        component: "input",
        label: "Subject line",
        defaultValue: "[Email subject line]",
        validations: { isRequired: true, maxLength: 120 },
      },
      {
        name: "preheader",
        component: "textarea",
        label: "Preheader",
        defaultValue: "[Email preheader text...]",
        helperText:
          "Enter a line of summary text that will be visible after the subject line in a contact's inbox. The ideal length is 40 to 100 characters.",
        validations: { isRequired: true },
      },
      {
        name: "advancedHeadSnippet",
        component: "textarea",
        label: "Head Snippet",
        helperText: "Scripts are applied directly before the closing </head> tag",
      },
      {
        name: "advancedBodySnippet",
        component: "textarea",
        label: "Body Snippet",
        helperText: "Scripts are applied directly before the closing </body> tag",
      },
    ]);
    // eslint-disable-next-line
  }, [emailTypeOptions, chosenEmailType]);

  return contentSettingsFields;
};

export default useEmailSettingsFields;
