import { useDispatch } from "react-redux";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { useTouchpointBuilderContent, setCurrentBuilderContent } from "state/ducks/builder-content";
import TouchpointVersion, { TouchpointIds } from "models/touchpoint-version";
import { EmailAttributes } from "models/email";
import { LandingPageAttributes } from "models/landing-page";
import { normalizeValues } from "utilities/forms";

export const useTouchpointIds = (): TouchpointIds => {
  const client = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const touchpointContent = useTouchpointBuilderContent();

  return {
    campaignId: currentCampaign.id,
    clientId: client.id,
    id: touchpointContent.id,
    touchpointId: currentTouchpoint.id,
  };
};

export const useSaveTouchpoint = () => {
  const touchpointContent = useTouchpointBuilderContent();
  const touchpointIds = useTouchpointIds();
  const dispatch = useDispatch();

  const saveTouchpoint = async (updatedValue: Record<string, any>) => {
    const getIterableEmailSenderId = () => {
      //for cases when senderName is not defined yet OR when senders are empty in EmailType
      if (updatedValue.senderName === "mock_id_for_no_senders_defined") {
        //for Design tab when iterableEmailTypeId from Content settings tab is not defined yet
        if (!updatedValue.iterableEmailTypeId) {
          return (touchpointContent.attributes as EmailAttributes).iterableEmailSenderId;
        } else {
          //for Content settings tab
          return null;
        }
      } else {
        return (
          //for Content settings tab
          updatedValue.senderName ||
          //for Design tab when iterableEmailTypeId from Content settings tab is not defined yet
          (touchpointContent.attributes as EmailAttributes).iterableEmailSenderId
        );
      }
    };

    // setting iterableEmailSenderId
    updatedValue.iterableEmailSenderId = getIterableEmailSenderId();

    // setting iterableEmailTypeId
    updatedValue.iterableEmailTypeId =
      updatedValue.iterableEmailTypeId ||
      (touchpointContent.attributes as EmailAttributes).iterableEmailTypeId;

    //removing redundant fields not to send those to BE
    delete updatedValue.senderName;
    delete updatedValue.replyToEmail;
    delete updatedValue.senderEmail;

    const updatedAttributes = {
      attributes: {
        ...normalizeValues(touchpointContent.attributes),
        ...normalizeValues(updatedValue),
      } as EmailAttributes | LandingPageAttributes,
    };
    const touchpoint = await TouchpointVersion.find(touchpointIds);
    if (touchpoint.attributes.lockedBy === null) {
      try {
        await TouchpointVersion.claimLock(touchpointIds);
        const updatedTouchpoint = await TouchpointVersion.find(touchpointIds);
        dispatch(setCurrentBuilderContent(updatedTouchpoint.attributes));
      } catch (claimError) {
        console.error("Failed to claim lock:", claimError);
      }
    }

    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const updatedTouchpoint = await TouchpointVersion.replace({
          ...touchpointIds,
          ...updatedAttributes,
        });
        dispatch(setCurrentBuilderContent(updatedTouchpoint.attributes));
        return;
      } catch (error: any) {
        switch (error.response.status) {
          case 409: {
            const newTouchpoint = await TouchpointVersion.find(touchpointIds);
            dispatch(setCurrentBuilderContent(newTouchpoint.attributes));
            console.error("Conflict error while saving touchpoint. Retrying...", error);
            retries++;
            // eslint-disable-next-line no-loop-func
            await new Promise((resolve) => setTimeout(resolve, 1000 * retries));
            break;
          }
          case 412: {
            const newTouchpoint = await TouchpointVersion.find(touchpointIds);
            dispatch(setCurrentBuilderContent(newTouchpoint.attributes));
            retries += 3;
            console.error(
              "precondition failed, you no longer have the lock for this touchpoint",
              error,
            );
            break;
          }
          default: {
            console.error("Failed to save touchpoint", error);
            throw error;
          }
        }

        throw new Error("Failed to save touchpoint after maximum retries");
      }
    }
  };
  return saveTouchpoint;
};
