import Icons from "assets";

interface IFieldErrorMessage {
  name: string;
  errMessage: string;
}

const FieldErrorMessage = ({ name, errMessage }: IFieldErrorMessage) => {
  return (
    <p className="flex items-center pt-1" id={`${name}-error`} role="alert">
      <Icons.InfoIndicator className="text-error size-4 flex items-center justify-center" />
      <span className="text-error ml-1 text-xs">{errMessage}</span>
    </p>
  );
};

export default FieldErrorMessage;
