import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const VALID_FORMAT_MESSAGE = "Please provide 6 characters for a valid hex code";

const getRequiredHexInput = (inputName: string) =>
  z
    .string()
    .nonempty(`${inputName} color is required`)
    .regex(/^#[0-9A-Fa-f]{6}$/, VALID_FORMAT_MESSAGE);

const getOptionalHexInput = () =>
  z
    .string()
    .optional()
    .refine(
      (value) =>
        value === undefined ||
        value === "#" ||
        value.length === 0 ||
        /^#[0-9a-fA-F]{6}$/.test(value),
      {
        message: VALID_FORMAT_MESSAGE,
      },
    );

export const ADOBE_FONTS = {
  name: "fonts.adobeFontsProjectId" as const,
  key: "adobeFontsProjectId" as const,
} as const;

export const GOOGLE_FONTS = {
  name: "fonts.googleFonts" as const,
  key: "googleFonts" as const,
} as const;

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  icon: z.unknown().optional(),
  isAlertShown: z.boolean().optional(),
});

// super refine to allow custom color to be conditionally required
const typographyFieldsValidation = z
  .object({
    fontFamily: optionSchema.optional(),
    fallbackFontFamily: optionSchema.optional(),
    fontSize: optionSchema.optional(),
    lineHeight: optionSchema.optional(),
    fontWeight: optionSchema.optional(),
    textColor: optionSchema.optional(),
    customColor: getOptionalHexInput(),
  })
  .superRefine(
    (
      arg,
      ctx,
    ): arg is {
      fontFamily?: { label: string; value: string };
      fallbackFontFamily?: { label: string; value: string };
      fontSize?: { label: string; value: string };
      lineHeight?: { label: string; value: string };
      fontWeight?: { label: string; value: string };
      textColor?: { label: string; value: string; icon?: unknown; isAlertShown?: boolean };
      customColor?: string;
    } => {
      if (arg.textColor?.value === "custom") {
        if (!arg.customColor || !/^#[0-9a-fA-F]{6}$/.test(arg.customColor)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide 6 characters for a valid hex code",
            path: ["customColor"],
          });
        }
      }
      return false;
    },
  );

export const PREVIEW_FORM_DATA_VALIDATION_SCHEMA = zodResolver(
  z.object({
    colors: z.object({
      primary: getOptionalHexInput(),
      secondary: getOptionalHexInput(),
      tertiary: getOptionalHexInput(),
      intense: getRequiredHexInput("Intense"),
      bold: getRequiredHexInput("Bold"),
      soft: getRequiredHexInput("Soft"),
      subtle: getRequiredHexInput("Subtle"),
      white: getRequiredHexInput("White"),
    }),
    fonts: z.object({
      [GOOGLE_FONTS.key]: z.array(z.string().optional()),
      [ADOBE_FONTS.key]: z
        .string()
        .optional()
        // Transform null/undefined to an empty string
        .transform((val) => val || ""),
    }),
    typography: z.object({
      heading: typographyFieldsValidation,
      subHeading: typographyFieldsValidation,
      normalBodyText: typographyFieldsValidation,
      smallBodyText: typographyFieldsValidation,
      textLink: typographyFieldsValidation,
      buttonLabel: typographyFieldsValidation,
    }),
    button: z
      .object({
        paddingX: optionSchema,
        paddingY: optionSchema,
        backgroundColor: z.object({
          name: optionSchema,
          custom: getOptionalHexInput(),
        }),
      })
      .superRefine(
        (
          arg,
          ctx,
        ): arg is {
          paddingX: { label: string; value: string };
          paddingY: { label: string; value: string };
          backgroundColor: {
            name: { label: string; value: string };
            custom?: string;
          };
        } => {
          if (arg.backgroundColor.name.value === "custom") {
            if (
              !arg.backgroundColor.custom ||
              !/^#[0-9a-fA-F]{6}$/.test(arg.backgroundColor.custom)
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Please provide 6 characters for a valid hex code",
                path: ["backgroundColor", "custom"],
              });
            }
          }
          return false;
        },
      ),
  }),
);
