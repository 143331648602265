import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import {
  currentTouchpointSelector,
  setCurrentTouchpoint,
  setTouchpointVersions,
} from "state/ducks/touchpoint";
import Touchpoint from "models/touchpoint";

import { Route } from "utilities/app-routes";

import { emptyPaginatedResponse } from "types/pagination";

const TouchpointProvider = ({ children }: React.PropsWithChildren) => {
  const client = useCurrentClient();
  const { touchpointId } = useParams<{ touchpointId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useCurrentCampaign();
  const touchpoint = useSelector(currentTouchpointSelector);

  useEffect(
    function mountTouchpoint() {
      if (touchpointId && touchpoint?.id !== touchpointId) {
        Touchpoint.find({ clientId: client.id, campaignId: campaign.id, id: touchpointId })
          .then((newTouchpoint) => {
            dispatch(setCurrentTouchpoint(newTouchpoint.attributes));
          })
          .catch(() => navigate(Route.notFound));
      }
    },
    [client, campaign, touchpointId, dispatch, touchpoint, navigate],
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentTouchpoint(null));
      dispatch(setTouchpointVersions(emptyPaginatedResponse));
    };
    // eslint-disable-next-line
  }, []);

  return <>{touchpoint && touchpoint.id === touchpointId ? children : null}</>;
};

export default TouchpointProvider;
