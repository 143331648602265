import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "state/ducks/users";

import { hasPermission } from "utilities/user";
import { Route } from "utilities/app-routes";

import { Permission } from "types/auth";

export function usePermissionBlacklist(permissions: Permission[]) {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    permissions.forEach((permission) => {
      if (hasPermission(currentUser, permission)) {
        navigate(Route.notFound);
      }
    });

    // eslint-disable-next-line
  }, [currentUser, navigate]);
}
