import { useState } from "react";
import clsx from "clsx";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import ContentTypeMismatchIcon from "components/partials/icon/content-type-mismatch-icon";
import { Span } from "components/partials/typography/typography";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import Touchpoint from "models/touchpoint";

import { sortAlphabetically } from "utilities";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { TouchpointType, TouchpointTypeLabel } from "types/touchpoint";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

const LandingPageTable = () => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const [hasInvalidTouchpoints, setHasInvalidTouchpoints] = useState(false);

  const loadAllLandingPages = (
    options: PaginatedRequestOptions,
  ): Promise<PaginatedResponse<Touchpoint>> => {
    return Touchpoint.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      type: TouchpointType.LANDING_PAGE,
      options: { ...options, sort: "name" },
    }).then((results) => {
      results.items = sortAlphabetically(results.items);
      const anyInvalid = results.items.some((touchpoint) => !touchpoint.isValidContentType);
      setHasInvalidTouchpoints(anyInvalid);
      return results;
    });
  };

  const renderRow = (touchpoint: Touchpoint) => {
    const hasWarningIcon = hasInvalidTouchpoints && !touchpoint.isValidContentType;
    const hasWarningIconSpacer = hasInvalidTouchpoints && touchpoint.isValidContentType;

    return (
      <TableRow>
        <TableCell>
          <div
            className={clsx("flex items-center", {
              "pl-8": hasWarningIconSpacer, // total width of ContentTypeMismatchIcon classNames
            })}>
            {hasWarningIcon && <ContentTypeMismatchIcon customClass="size-4 ml-1 mr-3" />}
            <RouterLink to={`../${touchpoint.id}/preview`}>{touchpoint.name}</RouterLink>
          </div>
        </TableCell>
        <TableCell>
          <Span className="whitespace-nowrap">{touchpoint.url}</Span>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <PaginatedTable
      headers={[`${TouchpointTypeLabel.LP_TOUCHPOINT} name`, "URL"]}
      fetchPage={loadAllLandingPages}
      renderRow={renderRow}
    />
  );
};

export default LandingPageTable;
