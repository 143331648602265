import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { FONTS_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/fonts/fonts.constants";

const Fonts = () => {
  return (
    <>
      <PreviewSidebar sidebarItems={FONTS_TAB_SIDEBAR} />
      <div className="flex-1 overflow-auto custom-scroll ">
        <PreviewArea />
      </div>
    </>
  );
};

export default Fonts;
