import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

import CampaignNewPage from "containers/admin/clients/campaign/campaign-new-page/campaign-new-page";
import { CampaignsPage } from "./campaigns-page";
import CampaignProvider from "containers/admin/clients/campaign/components/campaign-provider/campaign-provider";
import EmailNewPage from "containers/admin/clients/touchpoint/email/email-new-page/email-new-page";
import LPNewPage from "containers/admin/clients/touchpoint/lp/lp-new-page/lp-new-page";
import TouchpointPage from "containers/admin/clients/touchpoint/touchpoint-page";
import CampaignCreativePage from "containers/admin/clients/campaign/campaign-creative-page/campaign-creative-page";
import CreativeProvider from "containers/admin/clients/touchpoint/components/touchpoint-provider/touchpoint-provider";
import CampaignSettingsPage from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import CampaignSettingsEditTab from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-edit-tab/campaign-settings-edit-tab";

import PageContent from "components/partials/page-content/page-content";

import { useCurrentCampaign } from "state/ducks/campaigns";
import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";
import { CampaignSecondaryNav } from "containers/admin/clients/client/components/campaign-secondary-nav/campaign-secondary-nav";

const CampaignLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { pathname } = location;
  const lastSegment = pathname.split("/").pop() || "";
  const hideNav = ["details", "versions", "preview"].includes(lastSegment);

  const campaign = useCurrentCampaign();
  const campaignPath = pathname.split("/campaigns/")[0] + `/campaigns/${campaign.id}`;

  const navItems = React.useMemo(
    () => [
      {
        heading: "",
        links: [
          { to: `${campaignPath}/creative`, text: "Creative" },
          { to: `${campaignPath}/settings`, text: "Settings" },
        ],
      },
    ],
    [campaignPath],
  );

  return (
    <>
      {!hideNav && <CampaignSecondaryNav />}
      <PageContent hideSidebar={hideNav} tertiaryNavGroups={navItems}>
        {children}
      </PageContent>
    </>
  );
};

export const CampaignsRoutes = () => {
  usePagePermission(Permission.PERM_CAMPAIGN_READ);
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);
  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  return (
    <Routes>
      <Route index element={<CampaignsPage />} />
      <Route path="new" element={<CampaignNewPage />} />
      <Route
        path=":campaignId"
        element={
          <CampaignProvider>
            <CampaignLayout>
              <Outlet />
            </CampaignLayout>
          </CampaignProvider>
        }>
        <Route index element={<Navigate to="settings" />} />
        <Route path="settings" element={<CampaignSettingsPage />} />
        <Route path="settings/edit" element={<CampaignSettingsEditTab />} />
        <Route path="creative">
          <Route index element={<Navigate to="emails" />} />
          <Route path="emails" element={<CampaignCreativePage />} />
          <Route path="emails/new" element={<EmailNewPage />} />
          <Route path="landing-pages" element={<CampaignCreativePage />} />
          <Route path="landing-pages/new" element={<LPNewPage />} />
          <Route
            path=":touchpointId/*"
            element={
              <CreativeProvider>
                <TouchpointPage />
              </CreativeProvider>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="settings" />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
