import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import PageContentHeader from "components/partials/page-content-header/page-content-header";
import { Content } from "components/partials/layout/layout";
import { AddButton } from "components/partials/add-button/add-button";
import PaginatedTable, {
  usePaginationState,
} from "components/partials/paginated-table/paginated-table";
import { H2 } from "components/partials/typography/typography";
import Search from "components/new/custom-search/custom-search";
import RadioGroup from "components/partials/TW/radio-group/radio-group";
import CampaignRow from "./campaign-row";

import { useGetProgramTypesQuery } from "state/api/dictionary";
import { useCurrentClient } from "state/ducks/clients";
import Campaign from "models/campaign";

import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { Permission } from "types/auth";
import { ITableColumn } from "components/table/table-header/table-header";

const defaultSortingParams = "lastModifiedDate,desc";

export type campaignTableColumn = "name" | "programType" | "lastModifiedDate" | "actions";

const headers: ITableColumn<campaignTableColumn>[] = [
  { id: "name", label: "Campaign name", isSortable: true },
  { id: "programType", label: "Program Type", isSortable: true },
  { id: "lastModifiedDate", label: "Last updated", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false },
];

export const CampaignsPage = () => {
  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [tableSortedBy, setTableSortedBy] = useState<campaignTableColumn>("lastModifiedDate");
  const [tableSortedAsc, setTableSortedAsc] = useState<boolean>(false);
  const [currentSortOrder, setCurrentSortOrder] = useState(defaultSortingParams);
  const paginationState = usePaginationState();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  const navigate = useNavigate();
  const currentClient = useCurrentClient();
  const [campaignDisplayType, setCampaignDisplayType] = useState<string>();
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  const campaignTypeOptions = useMemo(
    () => [
      { label: "All", value: "All" },
      ...programTypes.map((type) => ({
        label: type.description,
        value: type.name,
      })),
    ],
    [programTypes],
  );

  const handleTableSort = useCallback(
    (columnId: campaignTableColumn) => {
      columnId !== tableSortedBy
        ? setTableSortedBy(columnId)
        : setTableSortedAsc((sortAsc) => !sortAsc);
    },
    [tableSortedBy],
  );

  const refreshColumnSort = useCallback(() => {
    return `${tableSortedBy},${tableSortedAsc ? "asc" : "desc"}`;
  }, [tableSortedAsc, tableSortedBy]);

  const handleProgramTypeToggle = useCallback((option: string) => {
    setCampaignDisplayType(option === "All" ? undefined : option);
  }, []);

  const fetchCampaigns = useCallback(
    (options: PaginatedRequestOptions): Promise<PaginatedResponse<Campaign>> => {
      return Campaign.all({
        options: { ...options, search, sort: currentSortOrder },
        clientId: currentClient.id,
        programType: campaignDisplayType,
      });
    },
    [currentClient.id, campaignDisplayType, search, currentSortOrder],
  );

  useEffect(() => {
    setCurrentSortOrder(refreshColumnSort());
  }, [tableSortedBy, tableSortedAsc, refreshColumnSort]);

  useEffect(() => {
    if (paginationState.page !== 0) {
      paginationState.setPage(0);
    } else {
      setShouldRefreshTable(true);
    }
  }, [search, campaignDisplayType, currentSortOrder, paginationState]);

  const renderRow = useCallback((campaign: Campaign) => {
    return <CampaignRow campaign={campaign} />;
  }, []);

  return (
    <Content data-testid="campaigns-tab" showBreadcrumbs={false}>
      <PageContentHeader direction="column">
        <div className="flex justify-between w-full mb-4">
          <H2>Campaigns</H2>
          <AddButton aria-label="Add New Campaign" onClick={() => navigate("new")} />
        </div>

        <Search
          {...{
            search,
            setSearch,
            customClass: "mb-4 w-full hidden", // Remove "hidden" when BE will be ready
          }}
        />
      </PageContentHeader>

      <RadioGroup
        options={campaignTypeOptions}
        groupName="campaignType"
        groupLabel="Filter by touchpoint type"
        onChange={handleProgramTypeToggle}
        defaultSelectedValue={campaignTypeOptions[0].value}
      />

      <PaginatedTable<Campaign, campaignTableColumn>
        headers={headers}
        fetchPage={fetchCampaigns}
        shouldRefresh={shouldRefreshTable}
        setShouldRefresh={setShouldRefreshTable}
        currentSortOrder={currentSortOrder}
        handleTableSort={handleTableSort}
        renderRow={renderRow}
      />
    </Content>
  );
};
