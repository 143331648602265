import { TouchpointVersionAttributes } from "models/touchpoint-version";

import { GeneralCommentsFAB } from "components/partials/general-comments-fab/general-comments-fab";
import { TouchpointPreviewTabContent } from "containers/admin/clients/touchpoint/tab-components/preview-tab/components/preview-tab-content/preview-tab-content";
import { TouchpointPreviewTabHeader } from "containers/admin/clients/touchpoint/tab-components/preview-tab/components/preview-tab-header/preview-tab-header";

interface PreviewTabProps {
  isSummaryLoading?: boolean;
  selectedVersion: TouchpointVersionAttributes | undefined;
  setSelectedVersion: (version: TouchpointVersionAttributes) => void;
}

const PreviewTab = ({ isSummaryLoading, selectedVersion, setSelectedVersion }: PreviewTabProps) => {
  return (
    <>
      {selectedVersion?.id && (
        <TouchpointPreviewTabHeader
          isSummaryLoading={isSummaryLoading}
          setSelectedVersion={setSelectedVersion}
          selectedVersion={selectedVersion}
        />
      )}
      <TouchpointPreviewTabContent selectedVersion={selectedVersion} />

      <GeneralCommentsFAB />
    </>
  );
};

export default PreviewTab;
