import { IAlert } from "containers/admin/clients/client/components/email-types/email-types.types";
import { ISenderDetails } from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.types";

export const ALERT_DATA: { [key: string]: IAlert } = {
  default: { status: "info", title: "", message: "" },
  success: {
    status: "success",
    title: "Sync is initiated",
    message: "Please refresh the page in a bit",
  },
  error: { status: "error", title: "Something went wrong", message: "Please, try again" },
};

//for displaying empty data in a form when no senders defined
export const NEW_SENDER_DATA: ISenderDetails = {
  id: "",
  senderName: "",
  senderEmail: "",
  replyToEmail: "",
  defaultForEmailType: true,
};

//for displaying empty data on a Content Settings tab (Touchpoint) when no senders defined yet
export const DEFAULT_SENDER_DATA: ISenderDetails = {
  id: "mock_id_for_no_senders_defined",
  replyToEmail: "Not defined",
  senderName: "Not defined",
  senderEmail: "Not defined",
  defaultForEmailType: true,
};
