import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import FieldLabel from "components/partials/field/field-label/field-label";
import FieldErrorMessage from "components/partials/field/field-error-message/field-error-message";

interface TextInputType {
  name: string;
  label: string;
  value?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  errMessage?: string;
}

const GeneralField = forwardRef<HTMLInputElement, TextInputType>(
  ({ name, label, value, isRequired, isDisabled = false, errMessage, ...rest }, ref) => {
    //styles
    const textInputStyles = twMerge(
      "w-full flex rounded-md bg-white border border-zinc-200 px-3 py-1.5 mt-1 text-base font-normal leading-6 outline-none",
      !!errMessage && "border-error",
      !errMessage && "focus:shadow-input focus:border-aqua-dark",
      isDisabled && "border-border-disabled bg-zinc-200",
    );

    return (
      <div className="w-full">
        <FieldLabel {...{ name, label, isRequired, isDisabled }} />
        <input
          ref={ref}
          type="text"
          data-testid={`${name}-text-input`}
          aria-describedby={errMessage ? `${name}-error` : undefined}
          className={textInputStyles}
          {...rest}
          value={value}
        />
        {!!errMessage && <FieldErrorMessage name={name} errMessage={errMessage} />}
      </div>
    );
  },
);

export default GeneralField;
