import { Navigate, Route, Routes, useMatch } from "react-router-dom";

import PageContent from "components/partials/page-content/page-content";
import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import UIKitButtons from "containers/ui-kit-page/components/ui-kit-buttons/ui-kit-buttons";
import UIKitColorPalette from "containers/ui-kit-page/components/ui-kit-color-palette/ui-kit-color-palette";
import UIKitTypography from "containers/ui-kit-page/components/ui-kit-typography/ui-kit-typography";
import UIKitForms from "containers/ui-kit-page/components/ui-kit-forms/ui-kit-forms";

const UIKitPage = () => {
  const match = useMatch("/ui-kit/*");
  const url = match?.pathnameBase ?? "";

  return (
    <>
      <SecondaryNav
        heading={"UI Kit"}
        links={[
          { to: `${url}/color-palette`, text: "Color Palette" },
          { to: `${url}/typography`, text: "Typography" },
          { to: `${url}/buttons`, text: "Buttons" },
          { to: `${url}/forms`, text: "Forms" },
          { to: `${url}/navigation`, text: "Navigation" },
          { to: `${url}/messaging`, text: "Messaging" },
          { to: `${url}/lists-data`, text: "Lists & Data" },
        ]}
      />
      <PageContent data-testid="ui-kit-page">
        <Routes>
          <Route index element={<Navigate to="color-palette" replace />} />

          <Route path="color-palette" element={<UIKitColorPalette />} />
          <Route path="typography" element={<UIKitTypography />} />
          <Route path="buttons" element={<UIKitButtons />} />
          <Route path="forms" element={<UIKitForms />} />
          <Route path="navigation" element={<PageContent>Navigation Page</PageContent>} />
          <Route path="messaging" element={<PageContent>Messaging Page</PageContent>} />
          <Route path="lists-data" element={<PageContent>Lists and Data Page</PageContent>} />
        </Routes>
      </PageContent>
    </>
  );
};

export default UIKitPage;
