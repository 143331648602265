import Model, { Unpersisted } from "models/model";

import { PreviewFormData } from "containers/admin/clients/client/theme-page/preview/preview.types";

class ThemeConfig extends Model<PreviewFormData> implements PreviewFormData {
  static getAll({ clientId }: { clientId: string }): Promise<ThemeConfig> {
    return this.connection.get(`clients/${clientId}/theme`).then((response) => {
      return new ThemeConfig(response.data);
    });
  }

  static create({
    clientId,
    attributes,
  }: {
    clientId: string;
    attributes: Unpersisted<PreviewFormData>;
  }): Promise<ThemeConfig> {
    return this.connection.post(`clients/${clientId}/theme`, attributes).then((response) => {
      return new ThemeConfig(response.data);
    });
  }

  get attributes(): PreviewFormData {
    return {
      colors: this.colors,
      fonts: this.fonts,
      typography: this.typography,
      button: this.button,
    };
  }

  get colors() {
    return this._attributes["colors"];
  }

  get fonts() {
    return this._attributes["fonts"];
  }

  get typography() {
    return this._attributes["typography"];
  }
  get button() {
    return this._attributes["button"];
  }
}

export { ThemeConfig as default };
