import { useState } from "react";

import {
  ModalProps,
  Modal,
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { H3, Span } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import { determineIconAndColorForFlashType } from "components/partials/flash/flash";
import ErrorAlert from "components/partials/error-alert/error-alert";

interface ConfirmationModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onConfirm: (() => void) | (() => Promise<void>);
  headline?: string;
  message?: string | React.ReactElement;
  error?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  cancelButtonVariant?: "tertiary" | "primary";
  showConfirmButton?: boolean;
  confirmButtonText?: string;
  modalType?: "general" | "success" | "info" | "warning" | "error" | "danger";
  children?: React.ReactNode;
  disabled?: boolean;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  headline = "Confirm Action",
  message = "Are you sure you want to continue?",
  error,
  showCancelButton = true,
  cancelButtonText = "Cancel",
  cancelButtonVariant = "tertiary",
  showConfirmButton = true,
  confirmButtonText = "Confirm",
  modalType = "general",
  children,
  disabled = false,
}: ConfirmationModalProps) => {
  const [Icon, color] = determineIconAndColorForFlashType(modalType);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      await onConfirm();
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const buttonStyles = {
    height: "auto",
    lineHeight: "1.5",
    px: "calc(1.25rem - 2px)",
    py: "calc(0.75rem - 2px)",
    margin: "unset",
    className: "!shadow",
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="xl"
      motionPreset="scale">
      <ModalOverlay />
      <ModalContent
        borderStyle="solid"
        borderColor={modalType === "general" ? "transparent" : `${modalType}.100`}
        borderLeftWidth="8px"
        paddingRight="unset">
        <Flex justify="left" pt="6" pb="8" pl="3">
          {Icon && <Icon color={color} w={5} h={5} />}
          <Flex direction="column" flex="1" gap="4" pl="6" pr="2">
            <ModalHeader padding="unset">
              <H3 lineHeight="1.33">{headline}</H3>
            </ModalHeader>

            {(error || message || children) && (
              <ModalBody display="flex" flexDirection="column" gap="4" p="0" mb="4">
                {error && <ErrorAlert error={error} />}
                {message && <Span lineHeight="1.5">{message}</Span>}
                {children && <Box>{children}</Box>}
              </ModalBody>
            )}

            {(showCancelButton || showConfirmButton) && (
              <ModalFooter justifyContent="left" gap="3" padding="unset">
                {showCancelButton && (
                  <Button
                    variant={cancelButtonVariant}
                    onClick={onClose}
                    size="sm"
                    data-testid="modal-cancel-button"
                    {...buttonStyles}>
                    {cancelButtonText}
                  </Button>
                )}
                {showConfirmButton && (
                  <Button
                    isLoading={isLoading}
                    onClick={handleConfirm}
                    size="sm"
                    variant={["danger", "error"].includes(modalType) ? "danger" : "primary"}
                    data-testid="modal-confirm-button"
                    {...buttonStyles}>
                    {confirmButtonText}
                  </Button>
                )}
              </ModalFooter>
            )}
          </Flex>
          <Flex flex="0 0 3rem" justifyContent="center">
            <ModalCloseButton data-testid="modal-close-button" top="2" right="2" />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
