import { FormLabel, Switch } from "@chakra-ui/react";

type ToggleProps = {
  toggleName?: string;
  customClass?: string;
  uncheckedTextColor?: string;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
};

const Toggle = ({
  toggleName = "Comment Mode",
  uncheckedTextColor = "gray.500",
  customClass,
  isChecked,
  setIsChecked,
}: ToggleProps) => {
  return (
    <FormLabel
      className={customClass}
      margin={0}
      fontWeight="semi-bold"
      color={uncheckedTextColor}
      fontSize="sm">
      <Switch
        colorScheme="navy"
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        mr={2}
      />
      {toggleName}
    </FormLabel>
  );
};

export default Toggle;
