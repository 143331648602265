import { useCallback, useMemo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import Select from "components/forms/select/select";
import Input from "components/forms/input/input";
import toast from "components/partials/toast/toast";

import {
  useGetCampaignObjectiveTypesQuery,
  useGetTouchpointContentTypesQuery,
  getDictionaryItemDescription,
} from "state/api/dictionary";
import { useCurrentClient, useCurrentCampaign, useCurrentTouchpoint } from "state/ducks";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { useClientCampaignsInModal } from "hooks/use-client-campaigns";
import { getErrorMessage } from "utilities";
import { Route as AppRoute, buildPath, getCampaignPaths } from "utilities/app-routes";

import { TouchpointType } from "types/touchpoint";
import { CampaignAttributes } from "models/campaign";

interface CloneTouchpointModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedVersion?: TouchpointVersionAttributes;
}

interface CloneFormData {
  campaignId: string;
  touchpointName: string;
  campaignObjective: string;
  contentType: string;
}

export const CloneTouchpointModal = ({
  isOpen,
  onClose,
  selectedVersion,
}: CloneTouchpointModalProps) => {
  const navigate = useNavigate();
  const currentClient = useCurrentClient();
  const defaultCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();

  const [selectedCampaign, setSelectedCampaign] = useState<CampaignAttributes>(defaultCampaign);

  const requestOptions = useMemo(
    () => ({
      size: 10000,
      sort: "name",
    }),
    [],
  );
  const clientCampaigns = useClientCampaignsInModal(isOpen, requestOptions);

  const { data: contentTypes } = useGetTouchpointContentTypesQuery(
    selectedCampaign.campaignObjective,
  );

  const defaultTouchpointName = `Copy of ${selectedVersion?.name ?? currentTouchpoint.name}`;

  const defaultValues = useMemo(
    () => ({
      campaignId: defaultCampaign.id,
      touchpointName: defaultTouchpointName,
      campaignObjective: defaultCampaign.campaignObjective,
      contentType: currentTouchpoint.contentType,
    }),
    [
      defaultCampaign.id,
      defaultTouchpointName,
      defaultCampaign.campaignObjective,
      currentTouchpoint.contentType,
    ],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CloneFormData>({
    mode: "onChange",
    defaultValues,
  });

  const abortControllerRef = useRef<AbortController | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [
    isOpen,
    defaultCampaign.id,
    selectedVersion,
    currentTouchpoint.name,
    defaultTouchpointName,
    reset,
    defaultValues,
  ]);

  const handleSubmitCloneTouchpoint = useCallback(
    (data: CloneFormData) => {
      if (!selectedVersion) return;

      const getTypeUrlSegment = (type: TouchpointType) => {
        switch (type) {
          case TouchpointType.EMAIL:
            return AppRoute.emails;
          case TouchpointType.LANDING_PAGE:
            return AppRoute.landingPages;
          case TouchpointType.THEME:
            return AppRoute.theme;
        }
      };

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      TouchpointVersion.clone({
        campaignId: data.campaignId,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        id: selectedVersion?.id,
        name: data.touchpointName,
        contentType: data.contentType,
      })
        .then(() => {
          if (signal.aborted) return;

          const selectedCampaign = clientCampaigns.find(
            (campaign) => campaign.id === data.campaignId,
          );

          toast.success({
            data: {
              title: `The touchpoint is cloned to the ${selectedCampaign?.name} campaign.`,
            },
          });

          const touchpointType = getTypeUrlSegment(currentTouchpoint.type);

          const { campaignCreativePath } = getCampaignPaths({
            clientId: currentClient.id,
            campaignId: data.campaignId,
          });

          navigate(buildPath(campaignCreativePath, touchpointType));
        })
        .catch((err) => {
          if (signal.aborted) return;

          toast.error({
            data: {
              title: "Failed to clone Touchpoint",
              message: getErrorMessage(err?.response?.data),
            },
          });
        })
        .finally(() => {
          if (!signal.aborted) {
            onClose();
          }
          abortControllerRef.current = null;
        });
    },
    [
      clientCampaigns,
      currentClient.id,
      currentTouchpoint.id,
      currentTouchpoint.type,
      navigate,
      onClose,
      selectedVersion,
    ],
  );

  const { data: campaignObjectiveTypes } = useGetCampaignObjectiveTypesQuery(
    selectedCampaign.programType,
  );

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      modalType="warning"
      headline="Clone Touchpoint"
      message="Please choose the campaign where you want to clone this touchpoint."
      confirmButtonText="Clone Touchpoint"
      onConfirm={handleSubmit(handleSubmitCloneTouchpoint)}
      disabled={!isValid}>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(handleSubmitCloneTouchpoint)}>
        <Controller
          name="campaignId"
          control={control}
          rules={{ required: "Campaign name is required" }}
          render={({ field }) => (
            <Select
              label="Campaign name"
              isRequired
              {...field}
              onChange={(e) => {
                const selectedCampaign =
                  clientCampaigns.find((campaign) => campaign.id === e.target.value) ??
                  defaultCampaign;

                setSelectedCampaign(selectedCampaign);

                field.onChange(selectedCampaign.id);
              }}>
              {clientCampaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </Select>
          )}
        />
        <Controller
          name="touchpointName"
          control={control}
          rules={{ required: "Touchpoint name is required" }}
          render={({ field: { ref, ...restField } }) => (
            <Input
              label="Touchpoint name"
              isRequired
              errorMessage={errors.touchpointName?.message}
              {...restField}
              ref={inputRef}
            />
          )}
        />
        <Select
          label="Campaign objective"
          value={selectedCampaign.campaignObjective}
          isDisabled
          isRequired>
          <option value={selectedCampaign.campaignObjective}>
            {getDictionaryItemDescription(
              campaignObjectiveTypes,
              selectedCampaign.campaignObjective,
            )}
          </option>
        </Select>

        <Controller
          name="contentType"
          control={control}
          rules={{ required: "Content type is required" }}
          render={({ field }) =>
            contentTypes?.length === 1 ? (
              <Select label="Content type" isRequired isDisabled {...field}>
                <option value={contentTypes[0].name}>{contentTypes[0].description}</option>
              </Select>
            ) : (
              <Select
                label="Content type"
                placeholder="Select content type"
                isRequired
                errorMessage={errors.contentType?.message}
                {...field}>
                {contentTypes?.map((type) => (
                  <option key={type.name} value={type.name}>
                    {type.description}
                  </option>
                ))}
              </Select>
            )
          }
        />
      </form>
    </ConfirmationModal>
  );
};
