const base = 16;
const unit = "em";

const viewports = {
  base: 0,
  maxMobile: 460,
  maxTablet: 800,
  maxBeeSmall: 1170,
  maxLaptop: 1280,
  maxDesktop: 1600,
};

// Use max-width sparingly, but, if needed:
// @media screen and (max-width: maxMobile) { *styles* }
export const maxMobile = viewports.maxMobile / base + unit;
export const maxTablet = viewports.maxTablet / base + unit;
export const maxBeeSmall = viewports.maxBeeSmall / base + unit;
export const maxLaptop = viewports.maxLaptop / base + unit;
export const maxDesktop = viewports.maxDesktop / base + unit;

// Use array syntax for min-width media queries
export const minTablet = (viewports.maxMobile + 1) / base + unit;
export const minLaptop = (viewports.maxTablet + 1) / base + unit;
export const minBeeLarge = (viewports.maxBeeSmall + 1) / base + unit;
export const minDesktop = (viewports.maxLaptop + 1) / base + unit;
export const minWidescreen = (viewports.maxDesktop + 1) / base + unit;

/* Transforms to:
const breakpoints = [
  {base: 0}, {sm: minTablet}, {md: minLaptop}, {beeLarge: minBeeLarge}, {lg: minDesktop}, {xl: minWidescreen}
]*/
const breakpoints = {
  base: "0",
  sm: minTablet,
  md: minLaptop,
  beeLarge: minBeeLarge,
  lg: minDesktop,
  xl: minWidescreen,
};

export default breakpoints;
