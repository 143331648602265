import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { TYPOGRAPHY_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography.constants";

const Typography = () => {
  return (
    <>
      <PreviewSidebar sidebarItems={TYPOGRAPHY_TAB_SIDEBAR} isAccordionApplied={true} />
      <div className="flex-1 overflow-auto custom-scroll">
        <PreviewArea />
      </div>
    </>
  );
};

export default Typography;
