import React from "react";
import { Box, Flex, FlexProps } from "@chakra-ui/react";

import { H4 } from "components/partials/typography/typography";
import NavLink from "components/partials/nav-link/nav-link";
import BackButton from "components/partials/back-button/back-button";
import { StatusDisplay } from "components/partials/status-display/status-display";

import { dashCase } from "utilities";

import { Link } from "types";

export interface SecondaryNavProps extends FlexProps {
  heading?: string | React.ReactNode;
  links?: Link[];
  backButton?: {
    show?: boolean;
    text?: string;
    path?: string;
    onClick?: () => void;
  };
  status?: string;
}

const SecondaryNav = ({
  heading,
  links,
  backButton,
  status,
  children,
  ...rest
}: SecondaryNavProps) => {
  const navLinkSpacing = { sm: 8, md: 12 };
  const SecondaryNavBackButton = ({ withSeparator = false }: { withSeparator?: boolean }) => (
    <BackButton
      data-testid="secondary-nav-back-button"
      text={backButton?.text || undefined}
      onClick={backButton?.onClick || undefined}
      path={backButton?.path || undefined}
      withSeparator={withSeparator}
    />
  );
  return (
    <Flex
      data-testid="secondary-nav"
      alignItems="center"
      justify="space-between"
      px={6}
      className="bg-gray-100"
      borderBottom="1px"
      borderColor="gray.200"
      zIndex="docked"
      marginTop="0!important"
      {...rest}>
      {heading ? (
        <H4
          data-testid="secondary-nav-heading"
          display="flex"
          alignItems="center"
          lineHeight="none">
          {backButton?.show && <SecondaryNavBackButton withSeparator />}
          {heading}
        </H4>
      ) : (
        backButton?.show && <SecondaryNavBackButton />
      )}

      <Box>
        <Flex as="nav">
          {links?.map((link) => (
            <NavLink
              data-testid={`secondary-nav-link-${dashCase(link.text)}`}
              to={link.to}
              key={link.text}
              marginLeft={heading ? navLinkSpacing : 0}
              marginRight={heading ? 0 : navLinkSpacing}
              py={2}>
              {link.text}
            </NavLink>
          ))}
          {children}

          {status && (
            <Flex>
              <StatusDisplay status={status} />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default SecondaryNav;
