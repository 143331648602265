import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { BUTTON_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button.constants";

const Button = () => {
  return (
    <>
      <PreviewSidebar sidebarItems={BUTTON_TAB_SIDEBAR} />
      <div className="flex-1 overflow-auto custom-scroll ">
        <PreviewArea />
      </div>
    </>
  );
};

export default Button;
