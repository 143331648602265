import { IEmailType } from "containers/admin/clients/client/components/email-types/email-types.types";
import Email from "models/email";

import { DEFAULT_SENDER_DATA } from "containers/admin/clients/client/components/email-types/email-types.constants";

export const getSenderOnEmailTypeChange = (
  emailType: IEmailType,
  currentTouchpointVersion: Email,
) => {
  //check if we have senders at all
  if (emailType?.senders?.length) {
    let sender;

    if (emailType.id !== currentTouchpointVersion.iterableEmailTypeId) {
      //is there is no iterableEmailSenderId - we set default one
      sender = emailType?.senders.find((sndr) => sndr.defaultForEmailType)!;
    } else {
      if (currentTouchpointVersion.iterableEmailSenderId) {
        //we set the sender that was chosen before nevertheless it  could be not default one
        sender = emailType?.senders.find(
          (sender) => sender.id === currentTouchpointVersion.iterableEmailSenderId,
        )!;
      } else {
        sender = DEFAULT_SENDER_DATA;
      }
    }

    return sender;
  } else {
    //if we don't have senders - we set a default sender
    return DEFAULT_SENDER_DATA;
  }
};
