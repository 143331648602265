import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useCurrentClient } from "state/ducks";

import Color from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/color";
import Fonts from "containers/admin/clients/client/theme-page/preview/sub-tabs/fonts/fonts";
import Typography from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography";
import Button from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button";
import { PreviewToolbar } from "containers/admin/clients/client/theme-page/preview/preview-toolbar/preview-toolbar";

import ThemeConfig from "models/theme-config";
import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import { PreviewFormData } from "containers/admin/clients/client/theme-page/preview/preview.types";

import {
  filterAlertTypography,
  filterAlertButton,
} from "containers/admin/clients/client/theme-page/preview/helpers/data-formatters";

import { PREVIEW_FORM_DATA_VALIDATION_SCHEMA } from "containers/admin/clients/client/theme-page/preview/preview.constants";

const Preview = ({ colors, typography, fonts, button }: PreviewFormData) => {
  const [currentTab, setCurrentTab] = useState("Colors");

  const { setIsEditMode } = useThemePreviewContext();

  const client = useCurrentClient();

  const tabHasError = (tabPath: string) => {
    return tabPath.toLowerCase() in methods.formState.errors;
  };

  //form
  const methods = useForm<PreviewFormData>({
    mode: "onChange",
    defaultValues: { colors, fonts, typography, button },
    resolver: PREVIEW_FORM_DATA_VALIDATION_SCHEMA,
    shouldFocusError: true,
  });

  //form
  const { reset, formState } = methods;

  // submit
  const onSubmit = async (values: PreviewFormData) => {
    try {
      setIsEditMode(false);

      const filteredValues = {
        ...values,
        typography: filterAlertTypography(values.typography),
        button: filterAlertButton(values.button),
      };

      const results = await ThemeConfig.create({
        clientId: client.id,
        attributes: filteredValues,
      });

      return results;
    } catch (error) {
      console.error("Failed to submit theme:", error);
    }
  };

  // reset the default values of the form on submit
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ colors, fonts, typography, button });
    }
  }, [formState.isSubmitSuccessful]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex flex-1 flex-col pb-14 overflow-hidden h-full">
        <PreviewToolbar
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabHasError={tabHasError}
        />
        <div className="border-t border-zinc-200 flex flex-1 overflow-auto">
          {currentTab === "Colors" && <Color />}
          {currentTab === "Fonts" && <Fonts />}
          {currentTab === "Typography" && <Typography />}
          {currentTab === "Button" && <Button />}
        </div>
      </form>
    </FormProvider>
  );
};

export default Preview;
