import React, { useEffect, useReducer, useState } from "react";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import * as zod from "zod";

import {
  useGetProgramTypesQuery,
  useGetCampaignObjectiveTypesQuery,
  useGetCampaignClassTypesQuery,
  useGetAudienceTypesQuery,
  useGetPopulationTypesQuery,
  DEFAULT_PROGRAM_TYPE,
  DEFAULT_CAMPAIGN_OBJECTIVE,
  mapDictionaryItemsToOptions,
  mapDictionaryItemsToMultiSelectOptions,
} from "state/api/dictionary";

import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";
import { H2 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import MultiSelect from "components/forms/multi-select-dropdown/multi-select-dropdown";
import Select from "components/forms/select/select";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import Form from "components/forms/form/form";
import FormErrors from "components/partials/form-errors/form-errors";
import toast from "components/partials/toast/toast";

import { Unpersisted, WithMaybePersisted } from "models/model";
import Campaign, { CampaignAttributes } from "models/campaign";

import {
  deeplyTransformEmptyStringToUndefined,
  getClientReviewStatus,
  toUpperCaseSpacesToUnderScores,
  toLowerCaseNoSpaces,
} from "utilities";

import { ZodFormErrors } from "types";
import { Formify } from "types/utility";

interface CreateCampaignFormProps {
  campaign?: Campaign;
  onSubmit: (attributes: Unpersisted<CampaignAttributes>) => void;
  onCancel: () => void;
}

interface UpdateCampaignFormProps {
  campaign: Campaign;
  onSubmit: (attributes: CampaignAttributes) => void;
  onCancel: () => void;
}

type CampaignFormProps = CreateCampaignFormProps | UpdateCampaignFormProps;

function isUpdatingCampaign(props: CampaignFormProps): props is UpdateCampaignFormProps {
  return (props as UpdateCampaignFormProps).campaign !== undefined;
}

export const CampaignForm = (props: CampaignFormProps) => {
  const form = useCampaignFormState(props.campaign);
  const confirmCancelModal = useDisclosure();

  const { data: audienceDictionaryItems = [] } = useGetAudienceTypesQuery();
  const { data: populationTypes = [] } = useGetPopulationTypesQuery();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  const { data: campaignObjectiveTypes = [] } = useGetCampaignObjectiveTypesQuery(
    form.values.programType,
  );

  const { data: classTypes = [] } = useGetCampaignClassTypesQuery(form.values.campaignObjective);

  function handleClassMultiSelectChange(selectedOptions: any) {
    const selectedValues = selectedOptions.map((val: any) => {
      return val.value;
    });
    form.handleComplexChange("classes", selectedValues);
  }

  function handleAudienceMultiSelectChange(selectedOptions: any) {
    const selectedValues = selectedOptions.map((val: any) => {
      return val.label;
    });
    form.handleComplexChange("audiences", selectedValues);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const campaignAttributes = schema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
        }),
      );

      form.setErrors({});
      const formattedCampaign = {
        ...campaignAttributes,
        campaignObjective:
          campaignAttributes.campaignObjective &&
          (toUpperCaseSpacesToUnderScores(campaignAttributes.campaignObjective) as string),
        classes: form.values.classes
          .map((tempClass) => {
            return toUpperCaseSpacesToUnderScores(tempClass);
          })
          .filter((str) => str !== null) as string[],
        audiences: form.values.audiences
          .map((audienceChoice) => {
            return toUpperCaseSpacesToUnderScores(audienceChoice);
          })
          .filter((str) => str !== null) as string[],
        status: campaignAttributes.status
          ? getClientReviewStatus(campaignAttributes.status)
          : undefined,
        programType: campaignAttributes.programType,
        lastModifiedDate: "",
        cdnSubdirectoryName: campaignAttributes.cdnSubdirectoryName
          ? toLowerCaseNoSpaces(campaignAttributes.cdnSubdirectoryName)
          : "",
        populationType: campaignAttributes.populationType,
      };

      if (isUpdatingCampaign(props)) {
        props.onSubmit(
          (function addBackData(
            attributes: any,
          ): WithMaybePersisted<CampaignAttributes, "clientId"> {
            attributes.id = props.campaign.id;
            attributes.lastModifiedDate = props.campaign.lastModifiedDate;
            return attributes;
          })(formattedCampaign),
        );
      } else {
        props.onSubmit(formattedCampaign);
      }
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
    }
  }

  /** Changing Program type must clear the Campaign objective. */
  const handleProgramTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    form.handleChange("programType")(event);
    const syntheticEvent = {
      target: Object.assign(document.createElement("select"), {
        value: "",
      }),
    } as React.ChangeEvent<HTMLSelectElement>;
    form.handleChange("campaignObjective")(syntheticEvent);
  };

  const handleCampaignObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    form.handleChange("campaignObjective")(e);
    toast.warning({
      data: {
        title: "Campaign objective changed",
        message:
          "Changing the campaign objective may mismatch the current creative content types. Please review and update them.",
      },
      autoClose: false,
    });
  };

  useEffect(
    /**
     * Some campaigns are only offered to one class type.
     * - e.g. `"Application Generation"` campaigns only target `"Senior"` students.
     *
     * In this case, save it as the `form.classes` value and disable the input.
     */
    function setSingleClassTypeAsDefault() {
      if (classTypes.length === 1) {
        form.handleComplexChange("classes", [classTypes[0].name]);
      }
    },
    // ! Ignore 'form' dependency to prevent infinite loop
    // eslint-disable-next-line
    [classTypes],
  );

  const pageTitle = props.campaign ? "Edit Settings" : "Add new campaign";

  const isFormComplete =
    form.values.name && form.values.programType && form.values.campaignObjective;

  return (
    <Box>
      {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}

      <H2 className="mb-8 mt-5">{pageTitle}</H2>

      <Form onSubmit={handleSubmit}>
        <FormGroup legend="Basic details">
          <Select
            label="Program type"
            helperText="This can't be edited once a campaign is created"
            value={form.values.programType}
            onChange={handleProgramTypeChange}
            isDisabled={!!props.campaign}
            isRequired>
            {mapDictionaryItemsToOptions(programTypes)}
          </Select>
          <Input
            isRequired
            id="campaign-name"
            label="Campaign name"
            value={form.values.name}
            onChange={form.handleChange("name")}
          />
          <Input
            id="campaign-cdnSubdirectoryName"
            label="Campaign CDN Profile"
            placeholder="campaigndirectoryname"
            helperText="Enter a profile name with no spaces. This name will be used to organize this campaign's digital assets and landing pages at its destination URL."
            value={form.values.cdnSubdirectoryName}
            onChange={form.handleChange("cdnSubdirectoryName")}
          />
        </FormGroup>

        <FormGroup legend="Audience & strategy">
          <Select
            label="Campaign objective"
            placeholder="Select campaign objective"
            value={form.values.campaignObjective}
            onChange={(e) => {
              handleCampaignObjectiveChange(e);
            }}
            isRequired>
            {mapDictionaryItemsToOptions(campaignObjectiveTypes)}
          </Select>
          <MultiSelect
            label="Audience"
            options={mapDictionaryItemsToMultiSelectOptions(audienceDictionaryItems)}
            value={form.values.audiences.map((audienceChoice) => ({
              value: audienceChoice.toLowerCase(),
              label: audienceChoice,
            }))}
            onChange={handleAudienceMultiSelectChange}
          />
          <Select
            label="Population type"
            placeholder="Select campaign population type"
            value={form.values.populationType}
            onChange={form.handleChange("populationType")}>
            {mapDictionaryItemsToOptions(populationTypes)}
          </Select>
          {classTypes.length === 1 ? (
            <Select id="campaign-class" label="Class" value={classTypes[0].name} isDisabled>
              <option value={classTypes[0].name}>{classTypes[0].description}</option>
            </Select>
          ) : (
            <MultiSelect
              id="campaign-class-dropdown"
              label="Class"
              placeholder={null}
              menuPlacement="top"
              options={mapDictionaryItemsToMultiSelectOptions(classTypes)}
              value={form.values.classes.map((type) => ({
                value: type,
                label: type,
              }))}
              onChange={handleClassMultiSelectChange}
            />
          )}
        </FormGroup>

        <HStack spacing={2}>
          <Button type="submit" size="sm" isDisabled={!isFormComplete}>
            Submit
          </Button>
          <Button variant="link" size="sm" onClick={confirmCancelModal.onOpen}>
            Cancel
          </Button>
        </HStack>
        <ConfirmationModal
          {...confirmCancelModal}
          message="Are you sure you want to exit? All unsaved changes will be lost"
          cancelButtonText="No"
          confirmButtonText="Yes"
          onConfirm={props.onCancel}
          modalType="warning"
        />
      </Form>
    </Box>
  );
};

const schema = zod.object({
  audiences: zod.string().array().optional(),
  campaignObjective: zod.string().default(DEFAULT_CAMPAIGN_OBJECTIVE),
  populationType: zod.string().optional(),
  classes: zod.string().array().optional(),
  defaultThemeId: zod.string().optional(),
  domainUrl: zod.string().url().optional(),
  keyObjectives: zod.string().optional(),
  name: zod.string(),
  primayMetric: zod.string().optional(),
  status: zod.string().optional(),
  programType: zod.string().default(DEFAULT_PROGRAM_TYPE),
  cdnSubdirectoryName: zod.string().optional(),
});
type CampaignAttributesSchema = zod.infer<typeof schema>;
type CampaignAttributesFormState = Formify<CampaignAttributesSchema>;
type CampaignAttributesFormErrors = Partial<{ [k in keyof CampaignAttributesSchema]: string }>;

function initialCampaignFormValues(campaign?: Campaign): CampaignAttributesFormState {
  return {
    audiences: campaign?.audiences || [],
    campaignObjective: campaign?.campaignObjective || DEFAULT_CAMPAIGN_OBJECTIVE,
    populationType: campaign?.populationType || "",
    classes: campaign?.classes || [],
    defaultThemeId: campaign?.defaultThemeId || "",
    domainUrl: campaign?.domainUrl || "",
    keyObjectives: campaign?.keyObjectives || "",
    name: campaign?.name || "",
    primayMetric: campaign?.primayMetric || "",
    status: campaign?.status || "",
    programType: campaign?.programType || DEFAULT_PROGRAM_TYPE,
    cdnSubdirectoryName: campaign?.cdnSubdirectoryName || "",
  };
}

function useCampaignFormState(campaign?: Campaign) {
  const [values, dispatch] = useReducer(
    function reducer(
      state: CampaignAttributesFormState,
      action: { field: string; value: string | string[] | undefined },
    ) {
      return {
        ...state,
        [action.field]: action.value || (Array.isArray(action.value) ? [] : ""),
      };
    },
    campaign,
    initialCampaignFormValues,
  );

  function handleChange(field: string) {
    return function (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
      dispatch({ field, value: event.target.value });
    };
  }

  function handleComplexChange(field: string, value: any) {
    dispatch({ field, value });
  }

  const [errors, setErrors] = useState<CampaignAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    const invalidFields = Object.keys(errors) as Array<keyof CampaignAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as CampaignAttributesFormErrors),
    );
  }

  return { values, handleChange, handleComplexChange, errors, setErrors: handleErrors };
}
