import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const TouchpointPageRedirect = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(pathname + "/preview");
    // eslint-disable-next-line
  }, []);

  return null;
};

export default TouchpointPageRedirect;
