import { useEffect, useState } from "react";

import { useCurrentClient } from "state/ducks";

import Touchpoint from "models/touchpoint";

import { IEmailType } from "containers/admin/clients/client/components/email-types/email-types.types";

const useEmailTypes = () => {
  const currentClient = useCurrentClient();

  const [emailTypeOptions, setEmailTypeOptions] = useState<IEmailType[]>([]);

  useEffect(() => {
    const fetchEmailTypes = async () => {
      try {
        const emailTypes = (await Touchpoint.getEmailTypes({
          clientId: currentClient.id,
        })) as IEmailType[];

        setEmailTypeOptions(emailTypes);
      } catch (e) {
        console.error("Error while fetching email types", e);
      }
    };

    fetchEmailTypes();
  }, [currentClient.id]);

  return emailTypeOptions;
};

export default useEmailTypes;
