import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useDisclosure } from "@chakra-ui/react";

import { BuilderHeader } from "containers/admin/clients/touchpoint/components/builder/builder-header/builder-header";
import LockingModal from "components/modals/locking-modal/locking-modal";
import toast from "components/partials/toast/toast";
import {
  FullPageOverlay,
  FullPageOverlayProps,
} from "containers/admin/clients/touchpoint/components/full-page-overlay/full-page-overlay";

import { useCurrentUser } from "state/ducks";
import { setCurrentBuilderContent } from "state/ducks/builder-content";

import TouchpointVersion from "models/touchpoint-version";

import { isEditableStatus, isLocked } from "utilities";

import { Permission } from "types/auth";

import { TouchpointBuilderContent } from "types";
import { Theme } from "models";
import { useTouchpointIds } from "containers/admin/clients/touchpoint/components/builder/use-save-touchpoint";
import { TouchpointType } from "types/touchpoint";
import { useLocation } from "react-router-dom";

interface BuilderProps extends FullPageOverlayProps {
  apiValue: TouchpointBuilderContent | Theme;
  contentName?: string;
  previewMode: boolean;
  permission: Permission;
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
  isSaving: boolean;
}

const Builder = ({
  apiValue,
  contentName,
  previewMode,
  permission,
  isSaved,
  setIsSaved,
  isSaving,
  children,
  ...rest
}: BuilderProps) => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const [claimingLock, setClaimingLock] = useState(false);

  const lockingModal = useDisclosure();
  const isLockedByAnother = isLocked(apiValue, currentUser);
  const isReadonly = previewMode || isLockedByAnother || !isEditableStatus(apiValue?.status);
  const touchpointIds = useTouchpointIds();
  const isNotTheme = "touchpointId" in touchpointIds;

  const releaseLock = useCallback(() => {
    if (isNotTheme) {
      return TouchpointVersion.releaseLock(touchpointIds);
    } else {
      return Theme.releaseLock(touchpointIds);
    }
  }, [isNotTheme, touchpointIds]);

  const handleClaimSuccess = useCallback(
    (touchpoint: TouchpointVersion | Theme) => {
      const { attributes } = touchpoint;
      dispatch(setCurrentBuilderContent(attributes));
    },
    [dispatch],
  );
  const claimBuilderLockInModal = useCallback(async () => {
    try {
      const response = await (isNotTheme
        ? TouchpointVersion.find(touchpointIds)
        : Theme.find(touchpointIds));

      // If user already owns the lock, just update dispatch
      if (response.attributes.lockedBy?.id === currentUser.id) {
        handleClaimSuccess(response);
        return;
      }

      // Otherwise proceed with claiming the lock
      if (isNotTheme) {
        const claimedTouchpoint = await TouchpointVersion.claimLock(touchpointIds);
        handleClaimSuccess(claimedTouchpoint);
      } else {
        const claimedTheme = await Theme.claimLock(touchpointIds);
        handleClaimSuccess(claimedTheme);
      }
    } catch (error) {
      console.error("Error during lock management:", error);
    }
    window.location.reload();
  }, [isNotTheme, touchpointIds, currentUser.id, handleClaimSuccess]);

  const claimBuilderLock = useCallback(async () => {
    try {
      // First check if we already own this lock
      const response = await (isNotTheme
        ? TouchpointVersion.find(touchpointIds)
        : Theme.find(touchpointIds));

      // If we already own the lock, just update the content state
      if (response.attributes.lockedBy?.id === currentUser.id) {
        handleClaimSuccess(response);
        return;
      }

      // Otherwise proceed with claiming the lock
      if (isNotTheme) {
        const claimedTouchpoint = await TouchpointVersion.claimLock(touchpointIds);
        handleClaimSuccess(claimedTouchpoint);
      } else {
        const claimedTheme = await Theme.claimLock(touchpointIds);
        handleClaimSuccess(claimedTheme);
      }
    } catch (error) {
      console.error("Error during lock management:", error);
    }
  }, [isNotTheme, touchpointIds, currentUser.id, handleClaimSuccess]);

  useEffect(() => {
    let mounted = true;
    if (apiValue && !isLockedByAnother && !previewMode && !apiValue?.lockedBy && !claimingLock) {
      setClaimingLock(true);
      claimBuilderLock()
        .then(() => {
          if (mounted) {
            toast.success({
              data: {
                title: "Content claimed",
                message: "You can edit the content until someone else claims it.",
              },
            });
          }
        })
        .catch((error) => {
          if (mounted) {
            // If we get a 409, someone else claimed the lock first
            if (error?.response?.status === 409) {
              // Refresh the content to get the latest lock status
              if (isNotTheme) {
                TouchpointVersion.find(touchpointIds)
                  .then((touchpoint) => {
                    dispatch(setCurrentBuilderContent(touchpoint.attributes));
                  })
                  .catch(console.error);
              }
            }
          }
        })
        .finally(() => {
          if (mounted) {
            setClaimingLock(false);
          }
        });
    } else if (apiValue && isLockedByAnother && !previewMode) {
      lockingModal.onOpen();
    } else if (lockingModal.isOpen) {
      lockingModal.onClose();
    }

    return () => {
      mounted = false;
    };
  }, [
    isLockedByAnother,
    previewMode,
    apiValue,
    claimingLock,
    claimBuilderLock,
    isNotTheme,
    touchpointIds,
    dispatch,
    lockingModal,
  ]);

  const latestValuesRef = useRef({ apiValue, currentUser, releaseLock });

  useEffect(() => {
    latestValuesRef.current = { apiValue, currentUser, releaseLock };
  }, [apiValue, currentUser, releaseLock]);

  useEffect(() => {
    return () => {
      const cleanup = async () => {
        try {
          const { currentUser, releaseLock } = latestValuesRef.current;

          // Double check we still own the lock
          const currentLock = await (isNotTheme
            ? TouchpointVersion.find(touchpointIds)
            : Theme.find(touchpointIds));

          if (currentLock.attributes.lockedBy?.id === currentUser.id) {
            await releaseLock();
          }
        } catch (error) {
          console.warn("Error during lock cleanup:", error);
        }
      };
      cleanup().catch((error) => {
        console.warn("Unhandled cleanup error:", error);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseUrl = location.pathname.split("/").slice(0, -1).join("/");

  const previewUrl = isNotTheme
    ? `${baseUrl}/${
        (apiValue as TouchpointBuilderContent).type === TouchpointType.EMAIL
          ? "email-preview"
          : "landing-page-preview"
      }`
    : `${baseUrl}/theme-preview/${apiValue.id}`;

  return (
    <FullPageOverlay {...rest}>
      <LockingModal
        content={apiValue}
        redirectUrl={baseUrl}
        previewUrl={previewUrl}
        claimLock={claimBuilderLockInModal}
        {...lockingModal}
      />
      <BuilderHeader
        apiValue={apiValue}
        contentName={contentName}
        readonly={isReadonly}
        permission={permission}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
        isSaving={isSaving}
      />
      {children}
    </FullPageOverlay>
  );
};

export default Builder;
