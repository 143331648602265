import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { useCurrentClient } from "state/ducks";

import ThemeConfig from "models/theme-config";

import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import { mapToggleOptions } from "components/partials/toggle-button-group/helpers/map-toggle-options/map-toggle-options";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";
import THEME_DEFAULT_DATA from "containers/admin/clients/client/theme-page/preview/mock-data/theme-default-data.json";

interface PreviewToolbarProps {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  tabHasError: (tab: string) => boolean;
}

export const PreviewToolbar = ({ currentTab, setCurrentTab, tabHasError }: PreviewToolbarProps) => {
  //form
  const {
    formState: { isDirty, errors },
    reset,
  } = useFormContext();

  const { isEditMode, setIsEditMode, setColors, setFonts, setTypography, setButton } =
    useThemePreviewContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  // Get client
  const client = useCurrentClient();

  // reset theme context to last saved values
  const updateThemeContext = async () => {
    try {
      const results = await ThemeConfig.getAll({
        clientId: client.id,
      });

      const updatedColors = results?.colors ?? THEME_DEFAULT_DATA.colors;
      const updatedFonts = results?.fonts ?? THEME_DEFAULT_DATA.fonts;
      const updatedTypography = results?.typography ?? THEME_DEFAULT_DATA.typography;
      const updatedButton = results?.button ?? THEME_DEFAULT_DATA.button;

      setColors(updatedColors);
      setFonts(updatedFonts);
      setTypography(updatedTypography);
      setButton(updatedButton);

      return {
        colors: updatedColors,
        fonts: updatedFonts,
        typography: updatedTypography,
        button: updatedButton,
      };
    } catch (error) {
      console.error("Error fetch theme config", error);
      return {
        colors: THEME_DEFAULT_DATA.colors,
        fonts: THEME_DEFAULT_DATA.fonts,
        typography: THEME_DEFAULT_DATA.typography,
        button: THEME_DEFAULT_DATA.button,
      };
    }
  };

  const handleConfirm = async () => {
    setShowModal(false);
    setIsEditMode(false);

    // reset theme context and form state on cancel
    const newTheme = await updateThemeContext();
    reset(newTheme);
  };

  const handleCancel = () => {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditMode(false);
    }
  };

  const anyErrOccured = ["colors", "fonts", "typography", "button"].some(
    (tabName) => tabName.toLowerCase() in errors,
  );

  const options = mapToggleOptions({
    tabs: [
      { name: "Colors", path: "colors" },
      { name: "Fonts", path: "fonts" },
      { name: "Typography", path: "typography" },
      { name: "Button", path: "button" },
    ],
    shouldShowIcon: (tabPath) => tabHasError(tabPath),
  });

  return (
    <div className="flex px-8 py-3 justify-between items-center">
      <ToggleButtonGroup
        groupName="theme-preview-tabs-toggle"
        options={options}
        onChange={(option) => setCurrentTab(option)}
        value={currentTab}
      />

      {isEditMode ? (
        <div className="flex gap-3">
          <Button onClick={handleCancel}>Cancel</Button>
          <ConfirmationModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            headline="Are you sure you want to cancel your changes?"
            message="All unsaved fields won't be saved. The form will be reset with the last saved values."
            cancelButtonText="Stay in Edit Mode"
            confirmButtonText="Leave Edit Mode"
            onConfirm={handleConfirm}
            modalType="warning"
          />
          <Button type="submit" isDisabled={anyErrOccured}>
            Save Draft
          </Button>
          <Button isDisabled>Publish</Button>
        </div>
      ) : (
        <Button
          onClick={() => {
            setIsEditMode(true);
          }}>
          Edit Theme
        </Button>
      )}
    </div>
  );
};
