import { useCallback, useEffect, useState } from "react";
import { RepeatIcon } from "@chakra-ui/icons";
import { Flex, Box, Text } from "@chakra-ui/react";

import Card, { CardHeader, CardBody } from "components/partials/card/card";
import { H3 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import AlertBox from "components/partials/alert-box/alert-box";
import Toggle from "components/partials/toggle/toggle";
import EmailTypesSendersTable from "containers/admin/clients/client/components/email-types/components/email-types-senders-table/email-types-senders-table";
import EmailTypesSendersForm from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form";
import LegacyButton from "components/forms/button/button";
import { LoadingSpinner } from "components/partials/loading-screen/loading-screen";
import CustomAccordion from "components/new/accordion/accordion";

import Touchpoint from "models/touchpoint";
import Client from "models/client";

import { ISenderDetails } from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.types";

import {
  IAlert,
  IEmailType,
  IEmailTypeDetails,
} from "containers/admin/clients/client/components/email-types/email-types.types";

import {
  ALERT_DATA,
  NEW_SENDER_DATA,
} from "containers/admin/clients/client/components/email-types/email-types.constants";

import Icons from "assets";

const EmailTypesTab = ({ client }: { client: Client }) => {
  const [emailTypes, setEmailTypes] = useState<IEmailType[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [sendersTableLoading, setSendersTableLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<IAlert>(ALERT_DATA["default"]);
  const [defaultEmailType, setDefaultEmailType] = useState("");

  const [emailTypeDetailsState, setEmailTypeDetailsState] = useState<IEmailTypeDetails[]>([]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 8000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showAlert]);

  const fetchEmailTypes = useCallback(async () => {
    setLoading(true);
    try {
      const emailTypes = await Touchpoint.getEmailTypes({
        clientId: client.id,
      });

      setEmailTypes(emailTypes);

      setEmailTypeDetailsState(
        emailTypes.map((el: IEmailType) => {
          return { id: el.id, isExpanded: false, isEditModeOn: false };
        }),
      );
      const curentDefaultEmail = emailTypes.filter((el: any) => el.defaultForClient);

      setDefaultEmailType(curentDefaultEmail?.id);
    } catch (e) {
      console.error("Error during fetching email types.EmailTypes", e);
    } finally {
      setLoading(false);
    }
  }, [client.id]);

  useEffect(() => {
    fetchEmailTypes();
  }, [fetchEmailTypes]);

  const handleEmailTypesSync = async () => {
    setSyncLoading(true);

    try {
      await Touchpoint.syncEmailTypes();
      setAlertData(ALERT_DATA["success"]);
      setShowAlert(true);
      if (!showRefreshButton) {
        setShowRefreshButton(true);
      }
    } catch (e) {
      console.error("Error during syncing email types.handleEmailTypesSync", e);
      setAlertData(ALERT_DATA["error"]);
      setShowAlert(true);
    } finally {
      setSyncLoading(false);
    }
  };

  const handleDefaultEmailTypeChange = async (emailTypeId: string) => {
    try {
      const newDefaultEmailType = await Touchpoint.setDefaultEmailType({
        clientId: client.id,
        emailTypeId: emailTypeId,
      });
      setDefaultEmailType(newDefaultEmailType?.id);
    } catch (e) {
      console.error("Error during setting new email type", e);
      setAlertData(ALERT_DATA["error"]);
      setShowAlert(true);
    }
  };

  const handleEmailTypeSendersSave = async (senders: ISenderDetails[], emailTypeId: string) => {
    setSendersTableLoading(true);

    try {
      const emailDetails = await Touchpoint.setDetailsEmailType({
        clientId: client.id,
        emailTypeId,
        data: senders,
      });

      const newEmailTypes = emailTypes.map((el) => (el.id === emailDetails.id ? emailDetails : el));
      setEmailTypes(newEmailTypes);
    } catch (e) {
      console.error("Error during setting Email type details", e);
      setAlertData(ALERT_DATA["error"]);
      setShowAlert(true);
    } finally {
      setSendersTableLoading(false);
    }
  };

  const onEditModeClosed = (currState: IEmailTypeDetails) => {
    currState.isEditModeOn = false;

    const newEmailTypeSendersState = emailTypeDetailsState.map((i) => {
      return i.id === currState.id ? currState : i;
    });

    setEmailTypeDetailsState(newEmailTypeSendersState);
  };

  const getSendersTable = (email: IEmailType) => {
    const defaultSender = email.senders.find(
      (sender: ISenderDetails) => sender.defaultForEmailType,
    );

    return !sendersTableLoading ? (
      <EmailTypesSendersTable {...defaultSender} email={email} />
    ) : (
      <div className="flex justify-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  };

  return (
    <Box position="relative">
      <Flex
        gap={{
          base: "0",
          lg: "6",
        }}
        flexWrap="wrap"
        mt="-1"
        alignItems="flex-start">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pb="10"
          sx={{ maxWidth: "630px", width: "630px" }}>
          <Flex alignItems="center" gap="3">
            <H3>Email types</H3>
            {showRefreshButton && (
              <Button
                variant="secondary"
                padding="0"
                height="30px"
                isLoading={loading}
                onClick={fetchEmailTypes}>
                <RepeatIcon sx={{ width: "24px", height: "20px", color: "#0D736A" }} />
              </Button>
            )}
          </Flex>

          <Button
            variant="secondary"
            size="sm"
            onClick={handleEmailTypesSync}
            loadingText="Synchronizing"
            isLoading={syncLoading}>
            Sync email types
          </Button>
        </Flex>
        {showAlert && (
          <Box sx={{ maxWidth: "630px" }} width={{ base: "630px", lg: "340px" }}>
            <AlertBox
              maxWidth="630px"
              pt="3"
              pb="2"
              mb="4"
              alert={{
                status: alertData.status,
                title: alertData.title,
                message: alertData.message,
              }}
              showCloseButton={true}
              handleClose={() => setShowAlert(false)}
            />
          </Box>
        )}
      </Flex>
      <Box sx={{ maxWidth: "630px" }}>
        {emailTypes.length > 0
          ? emailTypes.map((email: IEmailType) => {
              const currState = { ...emailTypeDetailsState.find((el) => el.id === email.id)! };

              email.senders = email?.senders?.length ? email.senders : [NEW_SENDER_DATA];

              return (
                <Card key={email.id} mb="4">
                  <Flex align="center" justify="space-between" width="100%" paddingRight="4">
                    <Box>
                      <CardHeader heading={email.externalName} paddingBottom="1.5" />
                      <CardBody
                        paddingTop="0"
                        color="gray.300">{`ID: ${email.externalId}`}</CardBody>
                    </Box>
                    <Toggle
                      toggleName="Default email type"
                      uncheckedTextColor="gray.300"
                      isChecked={
                        defaultEmailType ? defaultEmailType === email.id : email.defaultForClient
                      }
                      setIsChecked={() => {
                        handleDefaultEmailTypeChange(email.id);
                      }}
                    />
                  </Flex>
                  <div className="flex relative items-center pb-4">
                    <div className="flex px-4 w-full">
                      <CustomAccordion
                        variant="primary"
                        title="Details"
                        isDisabled={currState?.isEditModeOn}
                        isOpen={currState?.isExpanded}>
                        {currState?.isEditModeOn ? (
                          <EmailTypesSendersForm
                            onCancel={() => {
                              onEditModeClosed(currState);
                            }}
                            saveSenders={(senders: ISenderDetails[]) => {
                              handleEmailTypeSendersSave(senders, email.id);
                              onEditModeClosed(currState);
                            }}
                            email={email}
                          />
                        ) : (
                          getSendersTable(email)
                        )}
                        {!currState?.isEditModeOn && (
                          <LegacyButton
                            variant="tertiary"
                            data-testid="edit-details-button"
                            leftIcon={<Icons.Edit />}
                            size="sm"
                            mt={4}
                            onClick={() => {
                              currState.isEditModeOn = true;

                              const newEmailTypeSendersState = emailTypeDetailsState.map((i) => {
                                return i.id === currState.id
                                  ? currState
                                  : { ...i, isEditModeOn: false };
                              });

                              setEmailTypeDetailsState(newEmailTypeSendersState);
                            }}>
                            Edit senders
                          </LegacyButton>
                        )}
                      </CustomAccordion>
                    </div>
                  </div>
                </Card>
              );
            })
          : !loading && (
              <Card padding="7">
                <Text textAlign="center" fontSize="20px" fontWeight="600">
                  There are no Email Types created yet.
                </Text>
              </Card>
            )}
      </Box>
    </Box>
  );
};

export default EmailTypesTab;
