import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "state/ducks/users";

import { hasPermission } from "utilities/user";
import { Route } from "utilities/app-routes";

import { Permission } from "types/auth";

export function usePagePermission(permission: Permission, forbidden?: boolean) {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (forbidden || !hasPermission(currentUser, permission)) {
      navigate(Route.notFound);
    }
    // eslint-disable-next-line
  }, [currentUser, navigate]);
}
