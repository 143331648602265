import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import BuilderForm from "containers/admin/clients/touchpoint/components/builder/builder-form/builder-form";
import {
  CustomInput,
  CustomSelect,
  CustomTextarea,
} from "components/new/forms/custom-form-inputs/custom-form-inputs";
import { BuilderTabName } from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";

import Email, { EmailAttributes } from "models/email";
import { useTouchpointBuilderContent } from "state/ducks/builder-content";

import { getSenderOnEmailTypeChange } from "containers/admin/clients/touchpoint/components/email-builder/email-builder-content-settings-tab/email-builder-content-settings-tab.helper";
import { createZodSchemaFromFormFields, CustomFormField, CustomSelectProps } from "utilities/forms";

import { IEmailType } from "containers/admin/clients/client/components/email-types/email-types.types";
import { ISenderDetails } from "containers/admin/clients/client/components/email-types/components/email-types-senders-form/email-types-senders-form.types";

import { DEFAULT_SENDER_DATA } from "containers/admin/clients/client/components/email-types/email-types.constants";

interface EmailBuilderContentSettingsTabProps {
  previewMode: boolean;
  formFields: CustomFormField[];
  emailTypeOptions: IEmailType[];
  currentTouchpointVersion: Email;
  onSave: (field: Record<string, any>) => Promise<void>;
  setIsSaved: (isSaved: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setInvalidTabs: React.Dispatch<React.SetStateAction<BuilderTabName[]>>;
  setCurrentSender: React.Dispatch<React.SetStateAction<ISenderDetails>>;
}

const EmailBuilderContentSettingsTab = ({
  previewMode = false,
  formFields,
  emailTypeOptions,
  currentTouchpointVersion,
  onSave,
  setIsSaved,
  setIsSaving,
  setInvalidTabs,
  setCurrentSender,
}: EmailBuilderContentSettingsTabProps) => {
  const { attributes: initialAttributes } = useTouchpointBuilderContent() as Email;

  const zodSchema = createZodSchemaFromFormFields(formFields);

  type FormValues = z.infer<typeof zodSchema>;

  const [hasChanges, setHasChanges] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });

  useEffect(
    function setInitialValues() {
      formFields.forEach((field) => {
        const fieldName = field.name as keyof EmailAttributes;
        const initialValue = initialAttributes[fieldName] || field.defaultValue;
        setValue(fieldName, initialValue || "");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formFields],
  );

  useEffect(
    () => {
      //we find chosen emailType
      const emailType = emailTypeOptions.find(
        (option) => option.id === watch("iterableEmailTypeId"),
      )!;

      //we find chosen sender
      const sender = getSenderOnEmailTypeChange(emailType, currentTouchpointVersion);

      //set senderName to the form and trigger useEffect for senderName watch
      setValue("senderName", sender?.id);
    }, // eslint-disable-next-line
    [watch("iterableEmailTypeId")],
  );

  useEffect(
    () => {
      //we find chosen emailType
      const emailType = emailTypeOptions.find(
        (option) => option.id === watch("iterableEmailTypeId"),
      )!;

      //we find chosen sender among senders in chosen emailType
      const sender = emailType?.senders.length //check if we have senders
        ? emailType?.senders?.find((option: any) => option.id === watch("senderName"))!
        : DEFAULT_SENDER_DATA; //set a mock sender in case we dodn't have any senders on Email Type

      //set sender to the form
      setValue("senderEmail", sender?.senderEmail);
      setValue("replyToEmail", sender?.replyToEmail);

      //set sender outside for apply changes to Preview tab
      setCurrentSender(sender);
    }, // eslint-disable-next-line
    [watch("senderName")],
  );

  const hasErrors = !!Object.keys(errors).length;

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await handleSubmit(onSave)();

      setIsSaved(true);
    } catch (error) {
      console.error("Failed to save", error);
      setIsSaved(false);
    } finally {
      setIsSaving(false);
      reset(getValues());
    }
  };

  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    /** Autosave after 2 second debounce if a change has been made and the form is valid */
    function autosave() {
      if (hasChanges && !Object.keys(errors).length) {
        saveTimeoutRef.current = setTimeout(() => {
          handleSave();
        }, 2000);
      }

      return () => {
        if (saveTimeoutRef.current) {
          clearTimeout(saveTimeoutRef.current);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasChanges, Object.keys(errors).length],
  );

  useEffect(() => {
    setHasChanges(!isSubmitting && isDirty);
  }, [isDirty, isSubmitting, setHasChanges]);

  useEffect(() => {
    const thisTab = "Content Settings";
    setInvalidTabs((prev) =>
      hasErrors ? [...prev, thisTab] : prev.filter((tab) => tab !== thisTab),
    );
  }, [hasErrors, setInvalidTabs]);

  const getDinamicOptions = (id: string) => {
    const chosenEmailType = emailTypeOptions.find((option) => option.id === id)!;

    return !chosenEmailType?.senders.length
      ? [
          {
            name: DEFAULT_SENDER_DATA.senderName,
            value: DEFAULT_SENDER_DATA.id,
          },
        ]
      : chosenEmailType?.senders.map(({ senderName, id }) => ({
          name: senderName,
          value: id,
        }));
  };

  return (
    <BuilderForm errors={errors} formFields={formFields} onSubmit={() => handleSave()} noValidate>
      {formFields.map((formField) => {
        if (formField.component === "hidden") {
          return null;
        }

        const isDynamcField = formField.name === "senderName";

        const senderOptions = getDinamicOptions(getValues("iterableEmailTypeId"));

        return (
          <div key={`${formField.name}-${formField.component}`}>
            <Controller
              control={control}
              name={formField.name}
              render={({ field: { onChange, onBlur, value } }) => {
                const commonProps = {
                  error: errors[formField.name],
                  eyebrow: formField.eyebrow,
                  helperText: formField.helperText,
                  label: formField.label,
                  name: formField.name,
                  onBlur,
                  onChange,
                  placeholder: formField.placeholder,
                  required: !!formField.validations?.isRequired,
                  value,
                  disabled: isDynamcField
                    ? senderOptions.length === 1
                    : previewMode || formField.disabled,
                  options: isDynamcField ? senderOptions : (formField as CustomSelectProps).options,
                };

                switch (formField.component) {
                  case "select":
                    return <CustomSelect {...commonProps} />;
                  case "textarea":
                    return <CustomTextarea {...commonProps} />;

                  default:
                    return (
                      <CustomInput
                        type="text"
                        addonStart={formField.addonStart}
                        addonEnd={formField.addonEnd}
                        {...commonProps}
                        value={value || ""}
                      />
                    );
                }
              }}
            />
          </div>
        );
      })}
    </BuilderForm>
  );
};

export default EmailBuilderContentSettingsTab;
